import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-featured-config',
  templateUrl: 'featured-config.component.html'
})
export class FeaturedConfigComponent implements OnInit {
  
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  trailData: any;
  videoData: any;
  eventData: any;

  trailFolder: any;
  videoFolder: any;
  eventFolder: any;

  eventSessions: any;
  eventSessionData: any;

  courseSessionData: any;

  constructor(
    public dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getData();
    this.getVideoFolders();
    this.getEventFolders();

    if (this.item.featureFolderType) {
      this.getFolders(this.item.featureFolderType);
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getData() {
    const lu = sessionStorage.getItem('loggedUser') || (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.dataService.web().subscribe(res => {
      this.trailData = [];
      this.trailData.push(... res.data.trilhas);
      this.trailData.push(... res.data.influenciadores);
      this.trailData.push(... res.data.desenvolvimento);

      this.videoData = res.data.videos;
    });

    this.dataService.event(user.id).subscribe(res => {
      this.eventData = res;
    });

    this.dataService.custom('event-sessao', 8).subscribe((res: any) => {
      this.eventSessionData = res.data['evento-sessao'];
    });

    this.dataService.cursosSessao().subscribe(res => {
      this.courseSessionData = res;
    });
  }

  featureFolderTypeChanged() {
    this.trailFolder = null;
    this.getFolders(this.item.featureFolderType);
  }

  getFolders(type: number) {
    this.dataService.folders(type).subscribe((res) => {
      this.trailFolder = res.data;
    });
  }

  getVideoFolders() {
    this.dataService.videoFolders().subscribe((res: any) => {
      this.videoFolder = res.data;
    });
  }

  getEventFolders() {
    this.dataService.eventFolders().subscribe((res: any) => {
      this.eventFolder = res.data;
    });
  }

}

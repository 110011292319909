import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-polls-card',
  templateUrl: 'polls-card.component.html'
})
export class PollsCardComponent {

  @Input() setting: any;
  @Input() item: any;

}

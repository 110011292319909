import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';


@Component({
  selector: 'app-editor-custom-component-studio',
  templateUrl: 'studio.component.html'
})
export class StudioComponent implements OnInit {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() full: boolean;

  data: any;
  mock: any;

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnInit() {
    this.getCustom();
  }

  getCustom() {
    this.dataService.studio()
      .subscribe((res: any) => {
        this.data = res.data;
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ClientService {

  protected client;

  constructor(public http: HttpClient) {
    this.client = (localStorage.getItem('client') || sessionStorage.getItem('client'));
  }

  getColor(id = null): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/color' + ((id) ? ('/' + id) : ''), this.getHeaders());
  }

  categories(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder?role=6', this.getHeaders());
  }

  getClientData() {
    const id = JSON.parse( localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser') ).data.client_id;
    return this.http.get(environment.baseUrl + '/api/v2/admin/asap-client/' + id, this.getHeaders());
  }

  putColor(config: any, id = null): Observable<any> {
    const body = {
        config
    };
    return this.http.put(environment.baseUrl + '/api/v2/admin/color' + ((id) ? ('/' + id) : ''), body, this.getHeaders());
  }

  getHeaders(): {headers: HttpHeaders} {
    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

  allPages(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/pages-section/content/all?client_id=' + this.client, this.getHeaders());
  }

  staticPages(): Observable<any> {
      return this.http.get(environment.baseUrl + '/api/v2/admin/static-page?client_id=' + this.client, this.getHeaders());
  }

  layoutData() {
      return this.http.get(environment.baseUrl + '/api/v2/client/type/layout?client_id=' + this.client, this.getHeaders());
  }

}

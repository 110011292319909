import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-edital-influenciadores-config',
  templateUrl: 'edital-influenciadores-config.component.html'
})
export class EditalInfluenciadoresConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

}

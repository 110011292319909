import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-tecnico',
  templateUrl: 'tecnico.component.html',
  styleUrls: ['./tecnico.component.scss']
})
export class TecnicoComponent implements OnInit {
    @Input() item: any;

    data: any;

    constructor(public dataService: DataService) {

    }

    ngOnInit() {
      this.getData();
    }

    getData() {
      this.dataService.acervo().subscribe((res: any) => {
        console.log('TecnicoComponent: getData: res', res);
        this.data = res.data;
      });
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventsService } from 'src/app/@core/services/events.service';

import { FeatureService } from '../../shared/featured/feature.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-editor-custom-component-header-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['../header.component.scss', './carousel.component.scss']
})
export class HeaderCarouselComponent implements OnInit {

  @Input() item: any;

  data$: any;

  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 500,
    dots: false,
    items: 1,
    nav: true,
    navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
  };

  constructor(
    public featureService: FeatureService,
    public eventsService: EventsService
  ) {
  }

  ngOnInit() {
    this.setData();

    this.eventsService.on('featured-changed', () => this.setData());
  }

  setData() {
    this.data$ = of(this.item.carousel);
  }

  public processEvent(event) {
    if (['featured-config-event-change','featured-config-course-change','carousel-item-remove','featured-config-free-banner-change'].includes(event.event)) {
      this.data$ = this.featureService.getData(this.item.carousel);
    }
  }

}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ClientService } from 'src/app/@core/services/client.service';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-navbar-mobile-config',
  templateUrl: 'navbar-mobile-config.component.html'
})
export class NavbarMobileConfigComponent implements OnInit {

  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  public pageList: Array<any>;
  public staticPages: Array<any>;
  public layout;
  public companiesURL;
  showNavbar: boolean;

  constructor(
    public service: DataService,
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.getPages();
    if (this.item) {
      console.log('item navbar', this.item.navbarPerfil);
      this.item.navbarMenuItems = this.item.navbarMenuItems.filter(item => item != null);
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  getCompanies() {
    this.companiesURL = [];
    this.service.companies().subscribe((response: any) => {
        let urls = [];
        response.data.forEach((company) => {
            company.urls.forEach((url) => {
                console.log("[getCompanies]", url);
                urls.push({
                    client_id: company.id,
                    url: url
                });
            });
        });
        this.companiesURL = urls;
    });
}

getTitle(menuItem: NavbarMenuItem) {
  if (['external_link', 'internal_link', 'static_page', 'dynamic_page','channel'].includes(menuItem.type)) {
      return menuItem.label;
  }

  if (menuItem.type === 'section') {
      const sections = {
          news: 'Notícias',
          trail: 'Cursos'
      };
      return sections[menuItem.section];
  }

  if (menuItem.type === 'page') {
      const find = this.pageList.find(item => +item.id === +menuItem.page);
      if (find) {
          return find.name;
      }
  }

}

getPages() {
    this.clientService.layoutData().subscribe((res: any) => {
        this.layout = res.data.value;
    });
    this.clientService.allPages().subscribe(res => {
        this.pageList = res.data;
        this.showNavbar = true;
    });
    this.clientService.staticPages().subscribe(res => {
        this.staticPages = res.data;
        this.showNavbar = true;
    });
}

  newPage() {
    if (!this.item.navbarMenuMobile) {
        this.item.navbarMenuMobile = [];
    }
    this.item.navbarMenuMobile.push({
      ... new NavbarMenuItem(),
      position: 'mobile'
    });
}

removePage(index: number): void {
  this.item.navbarMenuMobile.splice(index, 1);
}

  getPhoto(target: any, $event: any): void {
    if (target === 'logoLight') {
      this.item.logoLight = $event.path;
    }
    if (target === 'logoDark') {
      this.item.logoDark = $event.path;
    }
    if (target === 'logoFooter') {
      this.item.logoFooter = $event.path;
    }
    if (target === 'logoEAD') {
      this.item.logoEAD = $event.path;
    }
    if (target === 'logoHelpdesk') {
      this.item.logoHelpdesk = $event.path;
    }
    if (target === 'emblem') {
      this.item.emblem = $event.path;
    }

  }
}
  export class NavbarMenuItem {
    id: string;
    type: string;
    url: string;
    label: string;
    section: string;
    page: string;
    pageStyle: string;

    constructor() {
        this.id = this.uuidv4;
        this.type = 'internal_link';
        this.label = 'INICIAL';
        this.url = '/';
        this.pageStyle = 'dropdown';
    }

    get uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

}


import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Global } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-multicontas',
    templateUrl: 'multicontas.component.html',
    styleUrls: ['./multicontas.component.scss']
})
export class MulticontasComponent implements OnInit {

    @Input() item: any;
    @Input() full: any;
    
    @Output() public removeItemEvent = new EventEmitter<any>();

    @ViewChild('multicontaCarousel',{static: false}) multicontaCarousel;

    data: any;
    mock: any;

    filtered: any;

    get owlOptions() {
        let options =  { items: 4, loop: true, dots: false, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };
        if (this.multicontaCarousel) {
            options.items = this.multicontaCarousel.carouselWindowWidth/250;
        }
        return options;
    };

    constructor(
        public global: Global,
        public dataService: DataService
    ) {

    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.dataService.companies().subscribe((res: any) => {
            this.data = res.data;
            this.filtered = this.data;
        });
    }

}


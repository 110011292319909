import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material';
import { NewsSectionDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-news-section',
  templateUrl: 'news-section.component.html'
})
export class NewsSectionComponent implements OnInit {
  @Input() full: boolean;
  @Input() item: any;
  @Input() settings: any;
  @Input() layout: number;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  data: any;

  mock = {
    name: 'Título da notícia',
  };

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    if (!this.item.section_id) {
      this.openDialog();
    } else {
      this.getData();
    }
  }

  getData() {
    this.dataService.news(this.item.section_id)
      .subscribe((res: any) => {
        this.data = res.data;
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NewsSectionDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.item.title.text = result.name;
        this.item.section_id = result.id;

        this.getData();
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-editor-custom-component-credits-config',
    templateUrl: 'credits-config.component.html'
})
export class CreditsConfigComponent {
    @Input() item: any;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    constructor(
    ) {
    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

}

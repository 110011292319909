import { Injectable } from '@angular/core';
import { AdminService } from 'src/app/@core/services/admin.service';
// import { DataService } from 'src/app/@core/services/data.service';

@Injectable()
export class AdminToolsService extends AdminService {
    
    public createPermission(id: any, item: any) {
        this.basePath = 'api/v2/admin';
        this.entityName = `folder/permission/${id}`;
        return this.createResource(item)
    }

    public deletePermission(id: number, query: string = '') {
        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.deleteResource(null, { router: 'list/' + id, query })
    }


    public loadFields() {
        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.getResources();
    }

    public loadFieldsVoucher() {
        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.getResources({ query: 'context=journey' });
    }
    

    public loadGroups() {
        this.basePath = 'api/v2/admin';
        this.entityName = 'group/cad';
        return this.getResources();
    }

    public loadValues(field: any, related = false, clientID = null) {
        let data = {
            filter: [field]
        };

        let params: any = {};

        if (related) {
            params.query = "related";
        }

        if (clientID) {
            if (params.query) params.query += "&"; else params.query = "";
            params.query += "client_id=" + clientID;
        }

        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.createResource(data, params);
    }

    public loadValuesVoucher(field: any, related = false, clientID = null) {
        let data = {
            filter: [field]
        };

        let params: any = {};

        if (related) {
            params.query = "related";
        }

        if (params.query) params.query += "&"; else params.query = "";
        params.query += "context=voucher&client_id=" + clientID;

        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.createResource(data, params);
    }

    public getFolderGroups(folder_id: number) {
        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/folder-groups/${folder_id}`;
        return this.getResources();
    }

    public getVideoGroups(folder_id: number) {
        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/video-groups/${folder_id}`;
        return this.getResources();
    }

    public getForumGroups(folder_id: number) {
        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/forum-groups/${folder_id}`;
        return this.getResources();
    }

    public getAreaGroups(folder_id: number) {
        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/area-groups/${folder_id}`;
        return this.getResources();
    }

    public getPermissionGroups(group_id: number, type: string, context = false) {
        const query = (context) ? `context=${context}` : '';

        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/folder/${group_id}/${type}`;
        return this.getResources({ query });
    }

    public getPermissionsExtra(id: number, type: string) {
        this.basePath = 'api/v2/admin';
        this.entityName = 'folder/permission';
        return this.getResources({ router: 'list/' + id, query: 'type=' + type });
    }

    public addGroups(id: number, type: string, groups: any) {
        this.basePath = 'api/v2/admin';
        this.entityName = `group/cad/folder/${id}/${type}`;
        let data = {
            groups: groups
        }
        return this.createResource(data);
    }

}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-artigos',
  templateUrl: 'artigos.component.html'
})
export class ArtigosComponent {

    @Input() setting: any;
    @Input() data: any;
    
}

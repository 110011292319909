import { NgModule } from "@angular/core";
import { SelectUserComponent, SelectUserComponentModal } from "./select-user.component";
import { SelectUserSelectModule } from "./select/select.module";
import { FormsModule } from '@angular/forms';
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule, MatIconModule } from "@angular/material";
import { MatInputModule } from '@angular/material/input';
import { SelectUserSelectComponent } from "./select/select.component";

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        SelectUserSelectModule,
        TranslateModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        SelectUserComponent,
        SelectUserComponentModal
    ],
    exports: [
        SelectUserComponent,
        SelectUserComponentModal
    ]
})
export class SelectUserModule { }
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-gallery-config',
  templateUrl: 'gallery-config.component.html'
})
export class GalleryConfigComponent implements OnChanges {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      if (this.item) {
        if (this.item.layout) {

        }
      }
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  uploadSubmited($event: any): void {
    this.item.url = ($event.data) ? $event.data.url : $event.url;
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  setItemAlign(element: string, align: string) {
    this.item[element] = align;
  }

}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SharedSubTitleItemComponent } from 'src/app/@core/models/item-component';

@Component({
    selector: 'app-editor-component-shared-subtitle-config',
    templateUrl: 'subtitle-config.component.html'
})
export class EditorSharedSubTitleConfigComponent implements OnChanges {

    @Input() subtitle: SharedSubTitleItemComponent;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        //if (changes.subtitle) {
            console.log("[EditorSharedSubTitleConfigComponent]",changes);
            // if (!this.subtitle) {
            //     this.subtitle = new SharedSubTitleItemComponent();
            // }
        //}
    }

    setAlign(align: string): void {
        this.subtitle.align = align;
    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

}

import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-jobs',
  templateUrl: 'jobs.component.html'
})
export class JobsComponent implements OnInit, OnChanges {
  @Input() setting: any;
  @Input() full: boolean;

  loading: boolean;

  data: any;
  mock: any;

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService
  ) {
    this.loading = true;
    this.mock = [
      {"id":null,"user_id":null,"client_id":"","role":"Vaga 1","vacancies":null,"salary_start":null,"salary_end":null,"vacancy_type":null,"benefits_type":null,"description":null,"minimal_expertise":null,"schooling":null,"banner":"/assets/img/no-image.jpg","status":false,"updated_at":"2022-04-04 15:10:10","created_at":"2022-03-31 15:11:40","minimum_age":33,"max_age":46,"city":null,"state":"Amazonas","item_id":null,"item":46,"languages":null,"courses":null,"max_points":null,"minimum_points":null,"social_institute":null,"company_description":null,"company_video":null,"active":1,"etalent_info":null,"date_end":null,"date_start":null,"situation":null,"degree":null,"company_name":"ASAP","processDisabled":null,"abilitie":[],"hasCurriculum":true},
      {"id":null,"user_id":null,"client_id":"","role":"Vaga 2","vacancies":null,"salary_start":null,"salary_end":null,"vacancy_type":null,"benefits_type":null,"description":null,"minimal_expertise":null,"schooling":null,"banner":"/assets/img/no-image.jpg","status":false,"updated_at":"2022-04-04 15:10:10","created_at":"2022-03-31 15:11:40","minimum_age":33,"max_age":46,"city":null,"state":"Amazonas","item_id":null,"item":46,"languages":null,"courses":null,"max_points":null,"minimum_points":null,"social_institute":null,"company_description":null,"company_video":null,"active":1,"etalent_info":null,"date_end":null,"date_start":null,"situation":null,"degree":null,"company_name":"ASAP","processDisabled":null,"abilitie":[],"hasCurriculum":true},
      {"id":null,"user_id":null,"client_id":"","role":"Vaga 3","vacancies":null,"salary_start":null,"salary_end":null,"vacancy_type":null,"benefits_type":null,"description":null,"minimal_expertise":null,"schooling":null,"banner":"/assets/img/no-image.jpg","status":false,"updated_at":"2022-04-04 15:10:10","created_at":"2022-03-31 15:11:40","minimum_age":33,"max_age":46,"city":null,"state":"Amazonas","item_id":null,"item":46,"languages":null,"courses":null,"max_points":null,"minimum_points":null,"social_institute":null,"company_description":null,"company_video":null,"active":1,"etalent_info":null,"date_end":null,"date_start":null,"situation":null,"degree":null,"company_name":"ASAP","processDisabled":null,"abilitie":[],"hasCurriculum":true},
      {"id":null,"user_id":null,"client_id":"","role":"Vaga 4","vacancies":null,"salary_start":null,"salary_end":null,"vacancy_type":null,"benefits_type":null,"description":null,"minimal_expertise":null,"schooling":null,"banner":"/assets/img/no-image.jpg","status":false,"updated_at":"2022-04-04 15:10:10","created_at":"2022-03-31 15:11:40","minimum_age":33,"max_age":46,"city":null,"state":"Amazonas","item_id":null,"item":46,"languages":null,"courses":null,"max_points":null,"minimum_points":null,"social_institute":null,"company_description":null,"company_video":null,"active":1,"etalent_info":null,"date_end":null,"date_start":null,"situation":null,"degree":null,"company_name":"ASAP","processDisabled":null,"abilitie":[],"hasCurriculum":true}
    ];
    this.data = this.mock;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.jobs().subscribe(
      (res: any) => {
        this.loading = false;
        if (res.length) {
          this.data = res;
        }
        else {
          this.data = this.mock;
        }
      },
      (error: any) => {
        this.data = this.mock;
      }
    );
  }
}

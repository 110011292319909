import { Injectable } from "@angular/core";
import { from } from "rxjs/internal/observable/from";
import { Subject } from "rxjs/internal/Subject";

@Injectable({providedIn: 'root'})
export class EventsService {
  listeners: { [key: string]: Function[] };
  eventsSubject: Subject<any>;
  events: any;

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = from(this.eventsSubject);

    this.events.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (let listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  on(name: string, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  off(name: string, listener) {
    this.listeners[name] = this.listeners[name].filter((x) => x != listener);
  }

  broadcast(name: string, ...args) {
    console.log('EventsService: broadcast', name, args);
    this.eventsSubject.next({
      name,
      args,
    });
  }
}

import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';


@Component({
  selector: 'app-editor-custom-component-slides',
  templateUrl: 'slides.component.html'
})
export class SlidesComponent implements OnInit, OnChanges {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() data: any;
  @Input() full: boolean;
  @Input() screen: string;

  dataByFolder: any;
  mock: any;

  owlOptions = { items: 1, loop: true, dots: false, navigation: false, autoplay: false, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.getCustom();
  }

  getCustom() {
    const trail = {
      slides: 0,
      influenciadores: 2,
      mini: 3,
      artigos: 4,
      free: 6
    };

    this.dataService.custom('trail', trail[this.setting.type])
      .subscribe((res: any) => {
        this.dataByFolder = res.data.itens;
        console.log('this.dataByFolder', this.dataByFolder);
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AdminService {

    public extraOptions: any = {};

    private _entityName: string = "";

    public idField: string = "id";
    public apiURL: string = environment.apiUrl;
    public basePath: string = "";
    public baseUrl: string;

    public methods: any = {
        create: '',
        read: '',
        update: '',
        delete: ''
    };

    protected token = localStorage.getItem('token');
    getUserData: any;

    constructor(
        public http: HttpClient
    ) {
        this.baseUrl = this.host();
    };

    protected host(): string {
        let url = this.apiURL;

        if (!url) {
            url = "";
        }

        this.baseUrl = url;
        return url;
    }

    protected headers(): HttpHeaders {
        let result: any = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'CompanyName': 'ASAP'
        };

        this.token = localStorage.getItem('token');
        if (!this.token) {
            this.token = sessionStorage.getItem('token');
        }

        if (this.token) {
            result.Authorization = this.token;
        }

        return new HttpHeaders(result);
    };

    protected options(): any {
        const headerOptions = this.headers();
        const options: any = { headers: headerOptions };

        for (let key in this.extraOptions) {
            options[key] = this.extraOptions[key];
        };

        return options;
    }

    get entityName() {
        return this._entityName;
    }

    set entityName(value: string) {
        this._entityName = value;
        this.methods.create = value;
        this.methods.read = value;
        this.methods.update = value;
        this.methods.delete = value;
    }

    getResources(params = {}): Observable<any> {
        return this.http.get<any>(this.resourceUrl(null, params, 'read'), this.options());
    }

    getResource(id: any, params = {}): Observable<any> {
        return this.http.get<any>(this.resourceUrl(id, params, 'read'), this.options());
    }

    createResource(data: any, params = {}): Observable<any> {
        return this.http.post<any>(this.resourceUrl(null, params, 'create'), data, this.options());
    }

    updateResource(data: any, params = {}): Observable<any> {
        return this.http.put<any>(this.resourceUrl(data.id, params, 'update'), data, this.options());
    }

    deleteResource(id: any, params = {}): Observable<any> {
        return this.http.delete<any>(this.resourceUrl(id, params, 'delete'), this.options());
    }

    rawGet(url: any) {
        return this.http.get<any>(url);
    }


    resourceUrl(id = null, params = {}, method: any) {

        let entity = this.methods[method];

        if (!entity) {
            entity = this.entityName;
        }

        const endpoint = [
            this.host(),
            this.basePath,
            this.urlParameters(params),
            entity,
            id
        ].filter((element: any) => element != false).join('/').replace(/\/$/, '');

        let hasQueryString = endpoint.includes('?');
        let url = endpoint + this.queryString(params, hasQueryString);

        return url;
    }

    protected urlParameters(params: any) {
        let urlParameters = []

        if (!params) {
            params = {};
        }

        for (var placeholder in params) {
            if (/.*_id$/.test(placeholder)) {
                urlParameters.push(`${placeholder}/${params[placeholder]}`)
            }
        }

        return urlParameters.join('/')
    }

    protected queryString(params: any, hasQueryString = false) {
        let connector = '?';

        if (hasQueryString) {
            connector = '&';
        }

        if (!params) {
            params = {};
        }

        let result = "";

        if (params.router) {
            result = `/${params.router}`;
        }

        if (params.query) {
            if (result != "") {
                result = result + `${connector}${params.query}`;
            }
            else {
                result = `${connector}${params.query}`;
            }
        }

        console.log("[queryString]", result, params);

        return result;
    }

    public adonisURL() {
        let url = localStorage.getItem('adonisURL');
        if (url) {
            return url;
        }
        else {
            url = sessionStorage.getItem('adonisURL');
            if (url) {
                return url;
            }
            else {
                return null;
            }
        }
    }

    protected adonisHeader(model = 'BaseModel', entityName = '', extraOptions: any = {}) {
        let result: any = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'CompanyName': 'ASAP',
            'EntityData': JSON.stringify({
                "model": model,
                "entityName": entityName
            })
        };

        this.token = localStorage.getItem('token');
        if (!this.token) {
            this.token = sessionStorage.getItem('token');
        }

        if (this.token) {
            result.token = this.token;
        }

        for (const key in extraOptions) {
            result[key] = extraOptions[key];
        }

        const headerOptions = new HttpHeaders(result);

        return { headers: headerOptions };
    }

    public adonis(model = 'BaseModel', entityName = '', extraOptions = {}) {
        return {
            get: (url: string) => {
                return this.http.get<any>(this.adonisURL() + url, this.adonisHeader(model, entityName, extraOptions));
            },
            post: (url: string, data: any) => {
                return this.http.post<any>(this.adonisURL() + url, data, this.adonisHeader(model, entityName, extraOptions));
            },
            put: (url: string, data: any) => {
                return this.http.put<any>(this.adonisURL() + url, data, this.adonisHeader(model, entityName, extraOptions));
            },
            delete: (url: string) => {
                return this.http.delete<any>(this.adonisURL() + url, this.adonisHeader(model, entityName, extraOptions));
            }
        }
    }

    public getEntity(id: any): Observable<any> {
        return this.getResource(id);
    }

    public save(entity: any, params: any = null): Observable<any> {
        if (entity.id) {
            return this.updateResource(entity, params);
        }
        else {
            return this.createResource(entity, params);
        }
    }

    public delete(id: any): Observable<any> {
        return this.deleteResource(id);
    }

    public listAll(params: any, page: any = null): Observable<any> {
        if (page) {
            if (!params) params = {};
            if (!params.query) params.query = `page=${page}`;
            if (params.query) params.query = `${params.query}&page=${page}`;
        }
        return this.getResources(params);
    }

}

import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AsapService, Global } from 'asap-crud';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-editor-custom-component-sidebar-config',
  templateUrl: 'sidebar-config.component.html',
  providers: [ AsapService ]
})
export class SidebarConfigComponent implements OnInit, OnChanges {

  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  colorToAdd: string;

  public monitoring = {
    categories: null
  }

  constructor(
    public global: Global,
    public service: AsapService
  ) {
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item) {
      if (!this.item.sidebarMonitoringHiddenCategories) {
        this.item.sidebarMonitoringHiddenCategories = [];
      }
      if (!this.item.sidebarMonitoringCategoryLabel) {
        this.item.sidebarMonitoringCategoryLabel = {};
      }
    }
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  addColorToPallet() {
    if (this.colorToAdd === '') {
      return;
    }

    if (!this.item.myColors) {
      this.item.myColors = [];
    }
    this.item.myColors.push(this.colorToAdd);
    this.colorToAdd = '';
  }

  removeColor(index: number) {
    this.item.myColors.splice(index, 1);
  }

  loadCategories() {
    const categories = [];
    this.service.basePath = 'api/v2/admin';

    this.service.entityName = `monitoring/category/client/0`;
    categories.push(this.service.getResources());
    this.service.entityName = `monitoring/category/client/1`;
    categories.push(this.service.getResources());

    forkJoin(categories).subscribe((responses) => {
      this.monitoring.categories = [
        ... responses[0].data,
        ... responses[1].data
      ];
    });
  }

  hideMonitoringCategory(cat) {
    const idx = this.item.sidebarMonitoringHiddenCategories.indexOf(cat.id)
    if (idx < 0) {
      this.item.sidebarMonitoringHiddenCategories.push(cat.id);
    }
    else {
      this.item.sidebarMonitoringHiddenCategories.splice(idx,1);
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-menu',
  templateUrl: 'menu.component.html',
  styleUrls: [ './menu.component.scss' ]
})
export class MenuComponent implements OnInit {

  @Input() item: any;

  data: any;
  mock: any;

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.menuLateral().subscribe((res: any) => {
      this.data = res.data;
    });
  }

}


import { Component, Inject, OnChanges, SimpleChanges, Input, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/@core/services/user.service";

@Component({
    selector: 'admin-select-user',
    templateUrl: './select-user.component.html',
    styleUrls: ['./select-user.component.scss'],
    providers: [UserService]
})
export class SelectUserComponent implements OnChanges {

    @Input() userList:any;
    @Input() asapClient = false;
    @Input() selection:any = [];
    @Input() component = false;
    @Input() inline = false;

    @Output() selectUser = new EventEmitter<any>();


    public filter = "";
    public users:any;

    public multiselect = false;

    constructor(
        public userService: UserService,
    ) {
        if (!this.users) {
            this.users = [];
        }
    }

    public searchUser() {
        this.users = null;
        setTimeout(() => {
            if (this.asapClient) {
                this.userService.getAllUserData(this.filter).subscribe((response) => {
                    this.users = response.data;
                    if (this.selection) {
                        if (Array.isArray(this.selection)) {
                            this.selection.forEach((id) => {
                                let u = this.users.find((user:any) => id == user.user_info.user_id);
                                if (u) {
                                    u.selected = true;
                                }
                            });
                        }
                        else {
                            this.selection = [this.selection];
                            this.selection.forEach((id:any) => {
                                let u = this.users.find((user:any) => id == user.user_info.user_id);
                                if (u) {
                                    u.selected = true;
                                }
                            });
                        }
                    }
                });
            }
            else {
                this.userService.getUserSearch(`search=${this.filter}`).subscribe((response: any) => {
                    this.users = response.data;
                });
            }
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.userList) {
            this.users = this.userList;
            this.component = true;
        }
    }

    public doFilter(event: any) {
        this.searchUser();
        event.preventDefault();
    }

    public onNoClick(): void {
        this.users = [];
    }

    public select(user: any = null) {
        user.selected = true;
        this.selection.push((user.user_info) ? user.user_info.user_id : user.user_id);
        this.selectUser.emit(user);
    }

}

@Component({
    selector: 'select-user-modal',
    templateUrl: './select-user.component.html',
    styleUrls: ['./select-user.component.scss'],
    providers: [UserService]
})
export class SelectUserComponentModal extends SelectUserComponent {

    constructor(
        public userService: UserService,
        public dialogRef: MatDialogRef<SelectUserComponentModal>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(userService);
        if (data) {
            this.component = false;
            if (data.asapClient === false) {
                this.asapClient = false;
            }
            if (data.searchList) {
                this.users = data.searchList;
            }
            if (data.selection) {
                if (Array.isArray(this.selection)) {
                    this.selection = data.selection;
                }
                else {
                    this.selection = [data.selection];
                }
            }
            this.multiselect = data.multiselect;
        }

        if (!this.users) {
            this.users = [];
        }
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public select(user:any = null) {
        if (user == null) {
            this.userService.getAllUserData({ id: this.selection }).subscribe((response) => {
                this.dialogRef.close(response.data);
            });
            // let selected = this.users.filter(u => u.selected);
            // this.dialogRef.close(this.users);
        }
        else if (this.multiselect) {
            let id = (user.user_info) ? user.user_info.user_id : user.user_id;
            let idx = this.selection.indexOf(id);
            if (idx >= 0) {
                this.selection.splice(idx, 1);
            }
            else {
                this.selection.push(id)
            }
        }
        else {
            this.dialogRef.close(user);
        }
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-journey-config',
  templateUrl: 'journey-config.component.html',
  styleUrls: ['../journey-list.component.scss']
})
export class JourneyConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnInit(): void {
    if (this.item && !this.item.button) {
      this.item.button = {
        background: "#22222",
        color: "#fffff",
        text: "Começar"
      }
    }

    console.log("item", this.item);
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  uploadSubmited($event: any): void {
    this.item.url = ($event.data) ? $event.data.url : $event.url;
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getPhotoCard($event: any): void {
    this.item.cardImage = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  setItemAlign(element: string, align: string) {
    this.item[element] = align;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventsService } from 'src/app/@core/services/events.service';

import { FeatureService } from '../../shared/featured/feature.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-editor-custom-component-onboard-item',
  templateUrl: 'onboard-item.component.html',
  styleUrls: ['../onboard.component.scss']
})
export class OnboardItemComponent implements OnInit {

  @Input() item: any;

  data$: any;
  isLinear: any;

  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 500,
    dots: true,
    items: 1,
    nav: false
  };

  constructor(
    public featureService: FeatureService,
    public eventsService: EventsService
  ) {
  }

  ngOnInit() {
    this.setData();
    this.eventsService.on('featured-changed', () => this.setData());
  }

  setData() {
    this.data$ = of(this.item.onboard);
  }

  public processEvent(event) {
    if (['featured-config-event-change','featured-config-course-change','onboard-item-remove'].includes(event.event)) {
      this.data$ = this.featureService.getData(this.item.onboard);
    }
  }

}

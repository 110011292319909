import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Global } from 'asap-crud';

@Component({
  selector: 'app-editor-custom-component-navbar-mobile',
  templateUrl: 'navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent implements OnChanges {
  @Input() item: any;
  @Output() public sendEvent = new EventEmitter<any>();
  
  public layout: any;
  public pageListBuffer: any;
  public pageList: any;
  public dragZoom: number;

  constructor(public global: Global) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.changes) {
      console.log("[changes]", changes.changes);
    }
  }

  emitSendEvent(event) {
    this.sendEvent.emit(event);
  }

  public drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.item.navbarMenuMobile, event.previousIndex, event.currentIndex);
    this.emitSendEvent({action: 'save'});
  }

  menuDrag() {
    this.dragZoom = +(localStorage.getItem('stage_zoom') || sessionStorage.getItem('stage_zoom'));
    if (this.dragZoom != 1) {
      this.global.toastService.info({
        message: 'Para ajustar os itens do Menu mude o zoom para 100%',
        position: 'topCenter'
      });
    }
  }
}

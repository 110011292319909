import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-influenciadores',
  templateUrl: 'influenciadores.component.html'
})
export class InfluenciadoresComponent implements OnInit {
  @Input() setting: any;
  @Input() data: any;

  dataByFolder: any;

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnInit() {
    this.getCustom();
  }

  getCustom() {
    this.dataService.custom('trail', 2)
      .subscribe((res: any) => {
        this.dataByFolder = res.data.itens;
        console.log('this.dataByFolder', this.dataByFolder);
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-main-tecnico',
  templateUrl: 'main-tecnico.component.html',
  providers: [DataService]
})
export class MainTecnicoComponent implements OnInit {
    @Input() setting: any;
    @Input() full: boolean;
    @Input() screen: string;

    data: any;
    mock: any;

    constructor(public dataService: DataService) {
      this.mock = {
        image: '/assets/img/no-image.jpg',
        area: 'Documento'
      };
    }

    ngOnInit() {
      this.getData();
    }

    getData() {
      this.dataService.acervo().subscribe((res: any) => {
        console.log('MainTecnicoComponent: getData: res', res);
        this.data = res.data;
      });
    }
}

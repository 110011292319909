import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-colaboradores',
  templateUrl: 'colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss']
})
export class ColaboradoresComponent {
    @Input() item: any;
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatTabsModule, MatTooltipModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SelectUserModule } from "../select-user/select-user.module";
import { AdminPermissionComponent } from "./admin-permission.component";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTabsModule,
        MatSelectModule,
        MatTooltipModule,
        MatSlideToggleModule,
        TranslateModule,
        ImageCropperModule,
        NgxSkeletonLoaderModule,
        SelectUserModule,

    ],
    declarations: [
        AdminPermissionComponent,

    ],
    exports: [
        AdminPermissionComponent,
    ],
    bootstrap: [
        AdminPermissionComponent,

    ]
}) 

export class AdminPermissionModule{}
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { MentoriaSectionDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-mentoria-section',
  templateUrl: 'mentoria-section.component.html'
})
export class MentoriaSectionComponent implements OnInit {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  filtered: any;
  dataByFolder: any;
  dataByFolderBuffer: any;
  mock: any;
  sessions: any;

  data: any;

  owlOptions = { items: 1, loop: true, dots: false, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnInit() {
    if (!this.setting.session_id) {
      this.openDialog();
    } else {
      this.getCustom();
      this.getData();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MentoriaSectionDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('openDialog: result', result);
      if (result) {
        this.item.title.text = result.name;
        this.item.session_id = result.id;

        this.getData();
        this.getCustom();
      } else {
        this.removeItemEvent.emit();
        console.log('openDialog: remove curso');
      }
    });
  }

  getData() {
    console.log('getData');

    const setting = this.setting;
    setting.itens = 6;
    setting.folder = false;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        console.log('/api/v2/client/geral/mobile/web', res);
        this.data = res.main[0].itens;
        this.filtered = this.data;
      });
  }

  getCustom() {
    console.log('getCustom');

    const setting = this.setting;
    setting.itens = 6;
    setting.folder = true;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        console.log('getCustom', res);
        this.dataByFolder = res.main[0].itens;
      });
  }
}


import { Component, Input } from '@angular/core';
import { SharedMediaItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-media',
  templateUrl: 'media.component.html'
})

export class EditorSharedMediaComponent {
  @Input() media: SharedMediaItemComponent;
}

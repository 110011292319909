import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-jobs-config',
  templateUrl: 'jobs-config.component.html'
})
export class JobsConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  uploadSubmited($event: any): void {
    this.item.url = ($event.data) ? $event.data.url : $event.url;
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  setAlignElement(element: any, align: string) {
    element = align;
  }

  setItemAlign(element: string, align: string) {
    this.item[element] = align;
  }

}

import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-banner-config',
  templateUrl: 'banner-config.component.html',
  providers: [DataService]
})
export class BannerConfigComponent implements OnInit {
  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadBannerEvent = new EventEmitter<any>();

  trailData: any;
  eventData: any;
  videoData: any;

  featuredItem: any;
  featuredItemSearch: any;

  buffer: any;

  courseSessionData: any;
  eventSessionData: any;
  eventSessions: any;

  constructor(
    private elRef: ElementRef,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

  setTitleAlign(align: string): void {
    this.item.titleAlign = align;
  }

  setSubtitleAlign(align: string): void {
    this.item.subtitleAlign = align;
  }

  setLeadAlign(align: string): void {
    this.item.leadAlign = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadBanner() {
    this.buffer = this.item.featureType + '-' + this.item.featureId;
    this.reloadBannerEvent.emit(this.item);
  }

  eventFeaturedChanged() {
    this.dataService.featured('evento', this.item.featureId).subscribe(res => {
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getImage($event: any): void {
    this.item.image = $event.path;
  }

  getEmblemPhoto($event: any): void {
    this.item.emblem = $event.path;
  }

  getIconPhoto($event: any): void {
    this.item.icon = $event.path;
  }

  getVideo($event: any): void {
    this.item.video = $event.path;

    const player = this.elRef.nativeElement.querySelector('video');
    player.load();
  }

  getData() {
    const lu = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.dataService.web().subscribe(res => {
      this.trailData = [];
      this.trailData.push(...res.data.trilhas);
      this.trailData.push(...res.data.influenciadores);
      this.trailData.push(...res.data.desenvolvimento);

      this.videoData = res.data.videos;
    });

    this.dataService.event(user.id).subscribe(res => {
      this.eventData = res;
    });

    this.dataService.cursosSessao().subscribe(res => {
      this.courseSessionData = res;
    });

    this.dataService.eventoSessao()
      .subscribe(res => {
        this.eventSessions = res;
      });

    this.dataService.custom('event-sessao', 8).subscribe((res: any) => {
      this.eventSessionData = res.data['evento-sessao'];
    });
  }

  courseBySession(session: any) {
    if (!this.courseSessionData) {
      return;
    }
    const find = this.courseSessionData.find((item: any) => +item.session_id === +session);
    if (find) {
      return find.trail;
    }
  }

  eventBySession(session: any) {
    if (!this.eventSessionData) {
      return;
    }
    const filter = this.eventSessionData.filter((item: any) => +item.session_id === +session);
    if (filter.length > 0) {
      return filter;
    }
  }

}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-showcase-section-config',
    templateUrl: 'showcase-section-config.component.html'
})
export class ShowcaseSectionConfigComponent implements OnInit {
    @Input() item: any = {};
    @Input() customData: any;
    @Input() full: boolean;
    @Output() public sendEvent = new EventEmitter<any>();
    @Output() public sessionChangedEvent = new EventEmitter<any>();

    public sessions: any;

    public companies: any;

    constructor(
        public dataService: DataService
    ) {

    }

    ngOnInit(): void {
        this.getSessions();
        if (this.isMulti()) {
            this.getCompanies();
        }
        else {
            if (this.item.companies) {
                delete(this.item.companies);
            }
        }
    }

    getSessions() {
        this.dataService.cursosSessao()
            .subscribe((response: any) => {
                this.sessions = response;
            });
    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

    getPhoto($event: any): void {
        this.item.banner = $event.path;
    }

    setAlign(element: any, align: string): void {
        element.align = align;
    }

    setAlignElement(element: any, align: string) {
        element = align;
    }

    setItemAlign(element: string, align: string) {
        this.item[element] = align;
    }

    sessionChanged() {
        this.sessionChangedEvent.emit();
    }

    isMulti() {
        let user: any = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
        if (user) {
            user = JSON.parse(user).data;
            let permission = user.resource_sub.find(s => s.tag == 'multi-account' && s.parent == 'editor-ead');
            if (permission) {
                return permission.enabled;
            }
        }

        return false;
    }

    getCompanies() {
        this.dataService.companies().subscribe((response: any) => {
            this.companies = response.data;
        });
    }

    selectCompany(company) {
        if (!this.item.companies) {
            this.item.companies = [];
            this.item.companies.push(company.id);
        }
        else if (!this.item.companies.includes(company.id)) {
            this.item.companies.push(company.id);
        }
        else {
            let idx = this.item.companies.indexOf(company.id);
            this.item.companies.splice(idx,1);
        }
    }

}

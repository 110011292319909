import { Component, Input } from '@angular/core';
import { SharedSubTitleItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-subtitle',
  templateUrl: 'subtitle.component.html',
})
export class EditorSharedSubTitleComponent {
  @Input() subtitle: SharedSubTitleItemComponent;
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AsapService } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-shortcuts',
  templateUrl: 'shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent implements OnInit, OnChanges {

  @Input() item: any;

  data: any;
  mock: any;

  menu = [
    { icon: 'bi-house-door', title: 'Home' },
    { icon: 'bi-mortarboard', title: 'Universidade' },
    { icon: 'bi-award', title: 'Academy' },
    { icon: 'bi-tv', title: 'Canais' },
    { icon: 'bi-balloon', title: 'Benefícios e parcerias' },
    { icon: 'bi-bar-chart', title: 'Gestão PFA' }
  ];

  constructor(
    public dataService: DataService,
    public service: AsapService,  
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item) {
      if (!this.item.shortcuts) {
        this.item.shortcuts = this.menu;
      }
    }
  }

  getData() {
    // this.dataService.menuLateral().subscribe((res: any) => {
    //   this.data = res.data;
    // });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.item.shortcuts, event.previousIndex, event.currentIndex);

    let formData = new FormData();
    this.item.shortcuts.map((item: any, index: number) => {
      formData.append(`menu[${index}]`, item.name)
      formData.append(`position[${index}]`, index.toString())
    });

    this.dataService.menuList(formData).subscribe({
      next: (response: any) => {
        console.log("[drop] {next} response: ", response);
      }
    });
  }
}




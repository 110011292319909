import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-management-panel',
  templateUrl: 'management-panel.component.html',
  styleUrls: [ './management-panel.component.scss' ]
})
export class ManagementPanelComponent {

  @Input() item: any;
  
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-showcase-section-dialog',
  templateUrl: 'dialog.component.html',
})
export class ShowcaseSectionDialogComponent implements OnInit {

  loading: boolean;
  sections: string;
  section: string;

  constructor(
    public dialogRef: MatDialogRef<ShowcaseSectionDialogComponent>,
    public dataService: DataService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getSection();
  }

  getSection() {
    this.dataService.getShowcaseSections()
      .subscribe((response: any) => {
        this.loading = false;
        this.sections = response;
      });
  }

  selectSection(section: any) {
    this.dialogRef.close(section);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

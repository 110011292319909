import { Component, Input } from '@angular/core';
import { SharedMediaItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-media-config',
  templateUrl: 'media-config.component.html'
})

export class EditorSharedMediaConfigComponent {
  @Input() media: SharedMediaItemComponent;

  getMediaPhoto($event: { path: string }) {
    this.media.url = $event.path;
  }

}

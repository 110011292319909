import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Global } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-forum-theme',
  templateUrl: 'forum-theme.component.html',
  styleUrls: ['./forum-theme.component.scss']
})
export class ForumThemeComponent implements OnInit {

  @Input() item: any = {};
  @Input() full: boolean;

  sessions: any;

  data: any;

  constructor(
    public global: Global,
    public dataService: DataService,
  ) {

  }

  ngOnInit() {
    if (!this.item.session_id) {
      this.dataService.forumsSection().subscribe((response: any) => {
        this.sessions = response.data;
        setTimeout(() => {
          this.openDialog();
        }, 500);
      });
    } else {
      this.getData();
    }
  }

  openDialog(): void {
    this.global.searchData({
      title: 'Selecione a Seção',
      searchList: this.sessions,
      descriptionField: 'name',
    }).subscribe((result: any) => {
      if (result) {
        this.item.title.text = result.name;
        this.item.session_id = result.id;
        this.getData();
      }
    })
  }

  getData() {
    if (this.item.session_id) {
      this.dataService.forums(this.item.session_id).subscribe((response: any) => {
        this.data = response.data;
      });
    }
  }

}


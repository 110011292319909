import { Component, Input, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material';
import { CursoSessaoDialogComponent } from '../curso-sessao/dialog/dialog.component';

@Component({
    selector: 'app-editor-custom-component-expositores',
    templateUrl: 'expositores.component.html'
})
export class ExpositoresComponent {

    @Input() setting: any;
    @Input() layout: number;
    @Input() full: boolean;
    @Input() screen: string;

    public expositors: any;

    owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

    constructor(
        public dataService: DataService,
        public dialog: MatDialog
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnInit() {
        this.getExpositor();
    }

    public getExpositor() {
        this.dataService.expositor().subscribe((res: any) => {
            this.expositors = res.data;
        });
    }


}


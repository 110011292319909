import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Global } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-forum-theme-sidebar',
    templateUrl: 'forum-theme-sidebar.component.html',
    styleUrls: [ './forum-theme-sidebar.component.scss' ]
})
export class ForumThemeSidebarComponent implements OnInit {

    @Input() item: any = {};
    @Input() full: boolean;

    sessions: any;

    data: any;

    constructor(
        public global: Global,
        public dataService: DataService,
    ) {

    }

    ngOnInit() {
        if (!this.item.session_id) {
            this.dataService.forumsSection().subscribe((response:any) => {
                this.sessions = response.data;
                this.openDialog();
            });
        } else {
            this.getData();
            this.getCustom()
        }
    }

    openDialog(): void {
        this.global.searchData({
            title: 'Selecione a Seção',
            searchList: this.sessions,
            descriptionField: 'name',
        }).subscribe((result:any) => {
            console.log('openDialog: result', result);
            if (result) {
                this.item.design.title.text = result.name;
                this.item.session_id = result.id;
                this.getData();
                this.getCustom();
            } 
        })
    }

    getData() {
        if (this.item.session_id) {
            this.dataService.forums(this.item.session_id).subscribe((response:any) => {
                this.data = response.data;
            });
        }
    }

    getCustom() {

    }
}


import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-editor-custom-component-featured',
  templateUrl: 'featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit, OnDestroy {
  
  @Input() item: any;

  timer;
  loading = false;
  data: any;

  featured = {
    featuredType: null,
    featuredBy: null,
    featuredFolder: null
  };

  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 500,
    dots: false,
    items: 1,
    nav: true,
    navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
  };

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnInit() {
    this.getData();
    this.timer = setInterval(() => {
      if (!this.loading && this.item) {
        const changed = (this.featured.featuredType    != this.item.featureType)
                     || (this.featured.featuredBy      != this.item.featuredBy)
                     || (this.featured.featuredFolder  != this.item.featuredFolder);

        if (changed) {
          this.getData();
        }
      }
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  getData() {
    const lu = sessionStorage.getItem('loggedUser') || (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.loading = true;

    const folder = this.item.featuredFolder;
    const type = this.item.featureType;
    const by = this.item.featuredBy;

    this.featured.featuredFolder = this.item.featuredFolder;
    this.featured.featuredType = this.item.featureType;
    this.featured.featuredBy = this.item.featuredBy;

    const observables = {
      'course-session': {
        folder: this.dataService.cursosSessao(),
      },
      'event-session': {
        folder: this.dataService.custom('event-sessao', 8),
      },
      event: {
        folder: this.dataService.eventsInFolder(folder),
        items: this.dataService.event(user.id)
      },
      video: {
        folder: this.dataService.videoInFolder(folder),
        items: this.dataService.web()
      },
      trail: {
        folder: this.dataService.trailInFolder(folder),
        items: this.dataService.web()
      },
      store: {
        featured: this.dataService.storeByFeatured()
      },
      product: {
        featured: this.dataService.productByFeatured()
      }
    };

    if (!observables[type] || !observables[type][by]) {
      this.loading = false;
      return;
    }

    const observable = observables[type][by];

    console.log('observable', observable);

    observable.subscribe(
      (res: any) => {
        const hydrates = {
          event: {
            folder: this.eventByFolderHydrate,
            items: this.eventByItemsHydrate
          },
          video: {
            folder: this.videoByFolderHydrate,
            items: this.videoByItemsHydrate
          },
          trail: {
            folder: this.trailByFolderHydrate,
            items: this.trailByItemsHydrate
          }
        };

        if (type === 'event') {
          if (by === 'folder') {
            this.data = this.eventByFolderHydrate(res);
          }
          if (by === 'items') {
            this.data = this.eventByItemsHydrate(res);
          }
        }

        if (type === 'event-session') {
          if (by === 'folder') {
            this.data = this.eventBySessionHydrate(res);
          }
        }

        if (type === 'course-session') {
          if (by === 'folder') {
            this.data = this.courseBySessionHydrate(res);
          }
        }

        if (type === 'video') {
          if (by === 'folder') {
            this.data = this.videoByFolderHydrate(res);
          }
          if (by === 'items') {
            this.data = this.videoByItemsHydrate(res);
          }
        }

        if (type === 'trail') {
          if (by === 'folder') {
            this.data = this.trailByFolderHydrate(res);
          }
          if (by === 'items') {
            this.data = this.trailByItemsHydrate(res);
          }
        }

        if (type === 'store') {
          if (by === 'featured') {
            this.data = this.storeByFeaturedHydrate(res);
          }
        }

        if (type === 'product') {
          if (by === 'featured') {
            this.data = this.productsByFeaturedHydrate(res);
          }
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );

  }

  eventByFolderHydrate(data: any) {
    data = data.data;
    const buffer = [];
    data.map((item: any) => {
      buffer.push({
        banner: item.banner,
        title: item.name
      });
    });
    return buffer;
  }

  eventBySessionHydrate(data: any) {
    data = data.data['evento-sessao'];
    const buffer = [];

    const founded = data.find((item: any) => +item.id === +this.item.featuredFolder);

    if (!founded) {
      return [];
    }

    founded.events.map((item: any) => {
      buffer.push({
        banner: item.banner,
        title: item.name
      });
    });

    return buffer;
  }

  courseBySessionHydrate(data: any) {
    const buffer = [];

    const founded = data.find((item: any) => +item.id === +this.item.featuredFolder);

    if (!founded) {
      return [];
    }

    founded.trail.map((item: any) => {
      buffer.push({
        banner: item.arquivo,
        title: item.titulo
      });
    });

    return buffer;
  }

  eventByItemsHydrate(data: any) {
    const buffer = [];
    data.map((item: any) => {
      if (this.item.featureId.includes(item.id)) {
        buffer.push({
          id: item.id,
          banner: item.banner,
          title: item.name
        });
      }
    });
    return buffer;
  }

  videoByFolderHydrate(data: any) {
    data = data.data;
    const buffer = [];
    data.map((item: any) => {
      buffer.push({
        id: item.id,
        banner: item.image,
        title: item.title
      });
    });
    return buffer;
  }

  videoByItemsHydrate(data: any) {
    data = data.data.videos;

    console.log(data);

    const buffer = [];
    data.map((item: any) => {
      if (this.item.featureId.includes(item.id)) {
        buffer.push({
          id: item.id,
          banner: item.image,
          title: item.title
        });
      }
    });
    return buffer;
  }

  trailByFolderHydrate(data: any) {
    data = data.data;
    const buffer = [];
    data.map((item: any) => {
      buffer.push({
        id: item.id,
        banner: item.arquivo,
        title: item.titulo
      });
    });
    return buffer;
  }

  trailByItemsHydrate(data: any) {
    const trailTypes = [
      'trilhas',
      'influenciadores',
      'desenvolvimento',
      'artigos',
      'livres'
    ];

    const buffer = [];

    for (const tp of trailTypes) {
      const itemArray = data.data[tp];

      itemArray.map((item: any) => {
        if (this.item.featureId.includes(item.id)) {
          buffer.push({
            id: item.id,
            banner: item.arquivo,
            title: item.titulo
          });
        }
      });
    }

    return buffer;
  }

  storeByFeaturedHydrate(data: any) {
    const buffer = [];
    data.map((item: any) => {
      buffer.push({
        id: item.id,
        banner: item.banner,
        title: item.name
      });
    });
    return buffer;
  }

  productsByFeaturedHydrate(data: any) {
    data = data.data;
    const buffer = [];
    data.map((item: any) => {
      buffer.push({
        id: item.id,
        banner: item.fotos[0].img || false,
        title: item.produto
      });
    });
    return buffer;
  }


}


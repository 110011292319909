import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-gallery',
  templateUrl: 'gallery.component.html'
})
export class GalleryComponent implements OnInit {
  @Input() setting: any;
  @Input() full: boolean;
  @Input() screen: string;

  mock: any;
  data: any;

  dataByFolder: any;

  constructor(
    public dataService: DataService
  ) {
    this.mock = {
      event_date: '2020-12-12',
      event_time: '12:00:00',
      banner: '/assets/img/no-image.jpg',
      name: 'Lorem ipsum dolor sit amet. Consectetur adipiscing elit. Ut nec erat magna',
      description: 'Consectetur adipiscing elit. Ut nec erat magna. Vestibulum porta ante lobortis nulla ultrices'
    };
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.lives().subscribe((res: any) => {
      this.data = res.data;
      console.log('GalleryComponent: getData: res', res);
    });
  }

}

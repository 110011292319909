import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-pontos',
  templateUrl: 'pontos.component.html',
  styleUrls: ['./pontos.component.scss']
})
export class PontosComponent {
    @Input() item: any;

    constructor( 
      //public dataService: DataService,
    ) {
      console.log("pontos->",this.item);
      }
  
}

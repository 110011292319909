import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-talks',
  templateUrl: 'talks.component.html'
})
export class TalksComponent {

  @Input() setting: any;
  @Input() layout: number;
  @Input() full: boolean;

}


import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-jobs-list',
  templateUrl: 'list.component.html'
})
export class JobsListComponent {

  @Input() item: any;
  @Input() setting: any;
  @Input() full: boolean;

}

//import { ClientService } from 'src/app/@core/services/client.service';
import { DataService } from 'src/app/@core/services/data.service';
import { Component, Input, OnInit } from '@angular/core';


@Component({
	selector: 'app-editor-custom-component-credits',
	templateUrl: 'credits.component.html',
	styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {
	@Input() item: any;

	data: any;
	userID: number;

	constructor( 
		public dataService: DataService,
		//private clientService: ClientService
	) {
		this.userID = JSON.parse( localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser') ).data.id;		
    }

    ngOnInit() {
      this.getData();
    }

    getData() {
      this.dataService.credits(this.userID).subscribe((res: any) => {
        this.data = res.data;
      });
	}
	
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-events-card',
  templateUrl: 'events-card.component.html'
})
export class EventosCardComponent {
  @Input() setting: any;
  @Input() item: any;

  constructor() {
  }

}

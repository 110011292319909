import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-header-featured-event',
  templateUrl: 'event.component.html'
})
export class HeaderFeaturedEventComponent {
  @Input() item: any;
  @Input() setting: any;

  ngOnInit(): void {
    console.log('[HeaderFeaturedEventComponent] -> ngOnInit', this.item, this.setting);
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-management-panel-config',
  templateUrl: 'management-panel-config.component.html'
})
export class ManagementPanelConfigComponent {

  @Input()  item: any;
  @Input()  customData: any;
  @Output() sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

}

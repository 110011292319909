import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-editor-custom-component-html',
  templateUrl: 'html.component.html'
})
export class HtmlComponent implements OnInit, OnChanges {
  @Input() setting: any;
  @Input() item: any;
  @Input() data: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  public filtered: any;
  public dataByFolder: any;
  public dataByFolderItem: any;
  public dataByFolderBuffer: any;
  public dataByFolderBufferItem: any;
  public mock: any;
  public sessions: any;

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };
  loading: boolean;

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
  }

  ngOnInit() {
    this.getCustom();
    if (!this.setting.session_id) {
    }

    setInterval(() => {
      if (this.dataByFolderBufferItem) {
        this.dataByFolderItem = this.dataByFolderBufferItem.filter((item: any) => +item.session_id === +this.setting.session_id);
      }
      if (this.dataByFolderBuffer) {
        this.dataByFolder = this.dataByFolderBuffer.filter((item: any) => +item.session_id === +this.setting.session_id);
      }
    }, 500);
  }

  public getCustom() {
    this.dataService.custom('permissoes', 0)
      .subscribe((res: any) => {
        this.dataByFolderBuffer = [];
        this.dataByFolderBuffer = res.data['permissoes'];
        this.dataByFolderBufferItem = [];
      });
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-library',
  templateUrl: 'library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent {
  @Input() item: any;

  data: any;

  constructor() {
    this.data = [
      {

      }
    ]
  }

}


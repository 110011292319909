import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-info-talks',
  templateUrl: 'info-talks.component.html'
})
export class InfoTalksComponent {

  @Input() item: any;
  @Input() layout: number;
  @Input() full: boolean;

}


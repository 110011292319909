import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { PollsSectionDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-polls-section',
  templateUrl: 'polls-section.component.html'
})
export class PollsSectionComponent implements OnInit, OnChanges {
  @Input() setting: any;
  @Input() item: any;
  @Input() data: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  filtered: any;
  dataByFolder: any;
  dataByFolderItem: any;
  dataByFolderBuffer: any;
  dataByFolderBufferItem: any;
  mock: any;
  sessions: any;

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      title: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
  }

  ngOnInit() {
    this.getCustom();

    if (!this.setting.session_id) {
      this.openDialog();
    }

    setInterval(() => {
      if (this.dataByFolderBufferItem) {
        this.dataByFolderItem = this.dataByFolderBufferItem.filter((item: any) => +item.session_id === +this.setting.session_id);
      }
      if (this.dataByFolderBuffer) {
        this.dataByFolder = this.dataByFolderBuffer.filter((item: any) => +item.session_id === +this.setting.session_id);
      }
    }, 500);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PollsSectionDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('openDialog: this.setting', this.setting);
      if (result) {
        this.setting.title.text = result.name;
        this.setting.session_id = result.id;
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

  getCustom() {
    this.dataService.custom('enquete-sessao', 0)
      .subscribe((res: any) => {
        this.dataByFolderBuffer = [];
        this.dataByFolderBuffer = res.data['enquete-sessao'];
        this.dataByFolderBufferItem = [];
        this.dataByFolderBufferItem = res.data['enquete-item'];
      });
  }
}
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-mentoria-section-dialog',
  templateUrl: 'dialog.component.html',
})
export class MentoriaSectionDialogComponent implements OnInit {

  loading: boolean;
  sections: string;

  constructor(
    public dialogRef: MatDialogRef<MentoriaSectionDialogComponent>,
    public dataService: DataService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getSections();
  }

  getSections() {
    this.dataService.mentoriaSection()
      .subscribe((response: any) => {
        this.loading = false;
        this.sections = response.data;
        console.log('this.sections', this.sections);
      });
  }

  selectSection(section: any) {
    this.dialogRef.close(section);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type Channel = {
  id: string;
  banner: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class DataService {

  public selectedPage: any;

  constructor(public http: HttpClient) {
  }

  get client(): string {
    const storage = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));

    if (!storage) {
      return '';
    }

    const loggedUser = JSON.parse(storage);

    return loggedUser.data.client_id;
  }

  get userId(): string {
    const storage = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));

    if (!storage) {
      return '';
    }

    const loggedUser = JSON.parse(storage);

    return loggedUser.data.id;
  }

  get header(): { headers: HttpHeaders } {
    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return { headers: new HttpHeaders(headerOptions) };
  }

  companies() {
    const client = (localStorage.getItem('client') || sessionStorage.getItem('client'));
    const url = environment.baseUrl + '/api/v2/admin/asap-client/vinculo/' + client;
    return this.http.get(url, this.header);
  }

  geralWeb(body: any) {
    const url = environment.baseUrl + '/api/v2/client/geral/mobile/web';
    return this.http.post(url, body, this.header);
  }

  newsSection() {
    const url = environment.baseUrl + '/api/v2/client/news-section';
    return this.http.get(url, this.header);
  }

  news(section: number) {
    const url = environment.baseUrl + '/api/v2/client/news-section/' + section + '/news';
    return this.http.get(url, this.header);
  }

  featured(type: string, id: string): Observable<any> {
    if (type === 'course-session') {
      type = 'curso';
    }
    if (type === 'enquete-sessao') {
      type = 'enquete';
    }
    if (type === 'event-session') {
      type = 'evento';
    }
    if (type === 'video-session') {
      type = 'video';
    }

    const url = environment.baseUrl + '/api/v2/client/itens/custom?type=' + type + '&id=' + id + '&client_id=' + this.client;
    return this.http.get(url, this.header);
  }

  event(userId: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/client/event/unit/' + userId + '?type=presential' + '&client_id=' + this.client;
    return this.http.get(url, this.header);
  }

  web(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/geral/web?client_id=' + this.client, this.header);
  }

  progress(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha', this.header);
  }

  lives(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/event/live/gallery?client_id=' + this.client, this.header);
  }

  custom(type: string, role: number) {
    const url = environment.baseUrl + '/api/v2/client/trilha/geral/custom?type=' + type + '&role=' + role + '&client_id=' + this.client;
    return this.http.get(url, this.header);
  }

  acervo() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/acervo/pasta/abre/permission', this.header);
  }

  color(id: number) {
    return this.http.get(environment.baseUrl + '/api/v2/admin/color/' + id, this.header);
  }

  notices() {
    return this.http.get(environment.baseUrl + '/api/v2/client/edital/influenciadores', this.header);
  }

  menuList(formData) {
    return this.http.post(environment.baseUrl + '/api/v2/client/type/layout/menu', formData, this.header);
  }

  journey(session_id=null) {
    let params: any = {
      query: ''
    };
    if (session_id) {
      params.query = "?session_id=" + session_id;
    }
    return this.http.get(environment.baseUrl + '/api/v21/admin/jornada' + params.query, this.header);
  }

  journeyItens(id: number) {
    return this.http.get(environment.baseUrl + '/api/v21/admin/jornada/item/client/' + id, this.header);
  }

  trailSessao(id): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v21/admin/trail/session?type=' + id, this.header);
  }

  jornadasSessao() {
    return this.http.get(environment.baseUrl + '/api/v2/client/session-journey?client_id=' + this.client, this.header);
  }

  cursosSessao() {
    return this.http.get(environment.baseUrl + '/api/v2/client/session/geral/web?client_id=' + this.client, this.header);
  }
 
  videoSessao() {
    return this.http.get(environment.baseUrl + '/api/v21/admin/videos/session?client_id=' + this.client, this.header);
  }

  pollSection() {
    return this.http.get(environment.baseUrl + '/api/v2/client/polls?client_id=' + this.client, this.header);
  }

  htmlSection() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/folder/permission' + this.client, this.header);
  }
  getHtml() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/group/cad', this.header);
  }
  getVouch() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/folder/permission?context=voucher&', this.header);
  }

  eventoSessao() {
    return this.http.get(environment.baseUrl + '/api/v21/admin/eventos/session?client_id=' + this.client, this.header);
  }

  benefits() {
    return this.http.get(environment.baseUrl + '/api/v2/client/beneficio/geral/web', this.header);
  }

  credits(clientID) {
    return this.http.get(environment.baseUrl + '/api/v2/client/creditos/' + clientID, this.header);
  }

  studio() {
    return this.http.get(environment.baseUrl + '/api/v2/client/course?client_id=' + this.client, this.header);
  }

  menu() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/permission/' + this.userId, this.header);
  }

  menuLateral(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/menu/view', this.header);
  }

  expositor() {
    return this.http.get(environment.baseUrl + '/api/v2/client/expositor?client_id=' + this.client, this.header);
  }

  jobs() {
    return this.http.get(environment.baseUrl + '/api/v2/client/opportunity/user?client_id=' + this.client, this.header);
  }

  data(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/home/info', this.header);
  }

  mentoriaSection() {
    return this.http.get(`${environment.baseUrl}/api/v2/client/mentoria-section`, this.header);
  }

  users() {
    return this.http.get(`${environment.baseUrl}/api/v2/admin/user/search`, this.header);
  }

  forumsSection() {
    return this.http.get(`${environment.baseUrl}/api/v2/client/forum-session?all`, this.header);
  }

  forums(section) {
    let query = 'all&search=' + btoa(`(theme_id IN (SELECT id FROM forum_theme WHERE (session_id=${section})))`);
    return this.http.get(`${environment.baseUrl}/api/v2/client/forum-chat?${query}`, this.header);
  }

  filters() {
    return this.http.get(`${environment.baseUrl}/api/v2/client/filter-global/filters`, this.header);
  }

  channels(): Observable<Channel[]> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/channel?type=recommended&client_id=' + this.client, this.header)
      .pipe(
        map((response: {data: Channel[]}) => response.data)
      );
  }

  channelItems(id,type): Observable<Channel[]> {
    let queryString = '';
    if (type == 'image') {
      type = 'file';
      queryString = '&type=image';
    }
    return this.http.get(environment.baseUrl + `/api/v2/admin/channel/${type}/${id}?client_id=${this.client}${queryString}`, this.header)
      .pipe(
        map((response: {data: Channel[]}) => response.data)
      );
  }

  getShowcaseSections(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/v2/client/showcases/sections`, this.header);
  }

  getShowcaseItems(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/v2/client/showcases`, this.header);
  }

  channel(id: string): Observable<Channel> {
    const url = `${environment.baseUrl}/api/v2/admin/channel/${id}?client_id=${this.client}`;
    return this.http.get(url, this.header)
      .pipe(
        map((response: {data: Channel}) => response.data)
      );
  }

  layout() {
    return this.http.get(environment.baseUrl + '/api/v2/client/type/layout', this.header);
  }

  videoFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/video/pasta', this.header);
  }

  pollFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/client/polls/folder', this.header);
  }
  htmlFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/client/folder/permission', this.header);
  }
  eventFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/client/event-folder', this.header);
  }

  folders(type: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder?role=' + type, this.header);
  }

  videoInFolder(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/video/' + folder, this.header);
  }

  pollInFolder(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/polls/' + folder, this.header);
  }

  eventsInFolder(folder: number): Observable<any> {
    const query = '?page=1&type=presential&client_id=' + this.client + '&folder=' + folder;
    return this.http.get(environment.baseUrl + '/api/v2/client/event' + query, this.header);
  }

  trailInFolder(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder/' + folder + '/items?page=0', this.header);
  }

  productByFeatured() {
    const uri = environment.baseUrl + '/api/v2/client/produto/mais/vendidos/' + this.client + '?client_id=' + this.client;
    return this.http.get(uri, this.header);
  }

  storeByFeatured() {
    const uri = environment.baseUrl + '/api/v2/client/loja/abre/' + this.client + '/destaque?client_id=' + this.client;
    return this.http.get(uri, this.header);
  }

  dataByItemPolls(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/polls/item' + folder, this.header);
  }

}

import { Component, Input } from '@angular/core';
import { SharedButtonItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-button',
  templateUrl: 'button.component.html'
})
export class EditorSharedButtonComponent {


  @Input() button: SharedButtonItemComponent = {
    background: '#22222',
    text: 'Próximo',
    color: '#fffff'
  };
  
  constructor() {
    console.log("ITEM ->", this.button);
  }
}

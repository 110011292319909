import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-news-card',
  templateUrl: 'news-card.component.html'
})
export class NewsCardComponent {
  @Input() setting: any;
  @Input() item: any;

  constructor() {
  }

}

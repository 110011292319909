import { Component, Input } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-editor-custom-component-edital-influenciadores',
  templateUrl: 'edital-influenciadores.component.html',
  styleUrls: ['./edital-influenciadores.component.scss']
})
export class EditalInfluenciadoresComponent {
  @Input() item: any;
  noticeArray: any;

  owlOptions = { 
    items: 3, 
    margin: 15, 
    loop: false, 
    dots: false, 
    navigation: false, 
    autoplay: false, 
    autoplayTimeout: 5000, 
    autoplayHoverPause: true 
  };

  owlOptionsSingle = { 
    items: 1, 
    margin: 0, 
    loop: false, 
    dots: false, 
    navigation: false, 
    autoplay: false, 
    autoplayTimeout: 5000, 
    autoplayHoverPause: true 
  };


  constructor(
    private dataService: DataService
  ) {
    this.getNotices();
  }

  getNotices() {
    this.dataService.notices().subscribe((res: any) => {
      console.log(res);
      this.noticeArray = res.data.pasta;
    });
  }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AsapService } from 'asap-crud';

@Component({
  selector: 'app-editor-custom-component-banner-link-config',
  templateUrl: 'banner-link-config.component.html',
  providers: []
})
export class BannerLinkConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  public banners;

  constructor() { }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.image = $event.path;
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppComponent } from './app.component';
import { StructureModule } from './structure/structure.module';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { HomeComponent } from './home/home.component';
import { HomePlatformChangeComponent } from './home/home-platform-change/home-platform-change.component';
import { AsapCrudModule } from 'asap-crud';
import { SharedModule } from './shared/shared.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { SortablejsModule } from 'ngx-sortablejs';
import { ComponentListComponent } from './home/component-list/component-list.component';
import { FormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatMenuModule } from '@angular/material';
import { LayoutType } from './home/layout-type/layout-type.component';
import { environment } from 'src/environments/environment';
import { AdminPermissionModule } from './components/permission/admin-permission.module';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    HttpClientModule,
    StructureModule,
    ComponentsModule,
    BrowserAnimationsModule,
    CarouselModule,
    SharedModule,
    AdminPermissionModule,
    SortablejsModule,
    MatCheckboxModule,
    RouterModule.forRoot([
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: '',
        component: HomeComponent
      }
    ]),
    SimpleNotificationsModule.forRoot({
        timeOut: 3000,
        showProgressBar: false,
        // theClass:'mt-10',
        pauseOnHover: true,
        clickToClose: true,
        position: ['top', 'right']
    }),
    Ng2IziToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCnQ7hg9qn8mrS3zSLX-xeXX3wKbuC2GXA',
      authDomain: 'admin-17e4f.firebaseapp.com',
      databaseURL: 'https://admin-17e4f.firebaseio.com',
      projectId: 'admin-17e4f',
      storageBucket: 'admin-17e4f.appspot.com',
      messagingSenderId: '572223664609'
    }),
    AngularFireStorageModule,
    AsapCrudModule,
    MatIconModule,
    MatButtonModule
  ],
  entryComponents: [],

  declarations: [
    AppComponent,
    RedirectComponent,
    HomeComponent,
    HomePlatformChangeComponent,
    ComponentListComponent,
    LayoutType,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR',  }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
    console.log('[AppModule]', new Date());
    sessionStorage.setItem('apiURL', environment.apiUrl);
    localStorage.setItem('apiURL', environment.apiUrl);
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Global } from 'asap-crud';
import { emit } from 'process';
import { ClientService } from 'src/app/@core/services/client.service';

@Component({
  selector: 'app-editor-custom-component-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent implements OnInit, OnChanges {
  
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  pageListBuffer: any;
  pageList: any;

  showNavbar: boolean;

  text = {
    eventos: 'Eventos',
    portal: 'Portal',
    elearning: 'Escola',
    ecommerce: 'Loja'
  };

  icon = {
    eventos: 'date_range',
    portal: 'school',
    elearning: 'school',
    ecommerce: 'local_grocery_store'
  };

  sectionLabel = {
    news: 'Notícias',
    trail: 'Cursos'
  };

  navbarTitles = [];

  dragZoom: number;

  hideMenu = false;

  constructor(
    public global: Global,
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.getPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      if (this.item) {
        this.navbarTitles = this.item.navbarMenuItems.map(m => m.label);
      }
    }
  }

  emitSendEvent(event) {
    this.sendEvent.emit(event);
  }

  getPhoto($event: any): void {
    this.item.logoEAD = $event.path;
  }

  getPages() {
    this.clientService.allPages().subscribe(res => {
      this.pageListBuffer = res.data;
      setInterval(() => {
        this.pageList = [];

        this.pageListBuffer.map((section: any) => {
          section.pagesActive = section.pages.filter((page: any) => {
            this.item.navbarPagesItems = this.item.navbarPagesItems.filter(item => item != null);
            for (const item of this.item.navbarPagesItems) {
              if (+page.id === +item) {
                return true;
              }
            }
            return false;
          });
          this.pageList.push(section);
        });

      }, 500);

      this.showNavbar = true;
    });
  }

  getTitle(menuItem: any) {
    if (menuItem.type === 'external_link' || menuItem.type === 'internal_link') {
      return menuItem.label;
    }

    if (menuItem.type === 'section') {
      const sections = {
        news: 'Notícias',
        trail: 'Cursos'
      };
      return sections[menuItem.section];
    }

    if (menuItem.type === 'page') {
      const find = this.pageList.find((item: any) => +item.id === +menuItem.page);
      if (find) {
        return find.name;
      }
    }

  }

  getPage(page: number) {
    if (this.pageList) {
      const find = this.pageList.find((item: any) => +item.id === +page);
      return find;
    }
    return false;
  }

  menuDrag() {
    this.dragZoom = +(localStorage.getItem('stage_zoom') || sessionStorage.getItem('stage_zoom'));
    if (this.dragZoom != 1) {
      this.global.toastService.info({
        message: 'Para ajustar os itens do Menu mude o zoom para 100%',
        position: 'topCenter'
      });
    }
    // this.emitSendEvent({action:'zoom', value: 1})
  }

  menuDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.item.navbarMenuItems , event.previousIndex, event.currentIndex);
    // this.emitSendEvent({action:'zoom', value: this.dragZoom});
    this.emitSendEvent({action: 'save'});
  }

  
}

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminService } from './admin.service';

@Injectable()
export class UserService extends AdminService {

    public groupId = null;
    getHeader: any;

    public getAllUserData(filter: any = null, regional = false): Observable<any> {
        let query = "?all";
        if (filter) {
            if (typeof filter == "string") {
                if (filter != "") {
                    query += '&search=' + filter;
                }
            }
            else {
                if (filter.id) {
                    query += '&id=' + filter.id;
                }
            }
        }

        if (regional) {
            query += "&regional=true";
        }

        if (this.groupId) query = "&group=" + this.groupId;
        return this.http.get(this.baseUrl + '/api/v2/admin/asap-user' + query, this.options());
    }

    public getUserSearch(term: string): Observable<any> {
        return this.http.get(this.baseUrl + '/api/v2/admin/asap-user/search?' + term, this.options());
    }

    public remove(id: string): Observable<any> {
        const url = this.baseUrl + '/api/v2/admin/user/delete/' + id;
        return this.http.delete(url, { headers: this.getHeader() });
    }

    public saveData(data: any, idCliente = null, sendwelcomeEmail = false): Observable<any> {
        const headerOptions = {
            'Authorization': sessionStorage.getItem('token'),
            'Accept': 'application/json'
        };
        if (idCliente === null) {
            return this.http.post(this.baseUrl + '/api/v2/admin/asap-user/store?send_welcome_email=' + sendwelcomeEmail,
                data,
                { headers: new HttpHeaders() });
        }
        return this.http.put(this.baseUrl + '/api/v2/admin/asap-user/' + idCliente + '/update',
            data,
            { headers: new HttpHeaders() });
    }

    public putData(data: any, idCliente = null): Observable<any> {
        const headerOptions = {
            'Authorization': sessionStorage.getItem('token'),
            'Accept': 'application/json'
        };
        return this.http.put(this.baseUrl + '/api/v2/admin/permission/' + idCliente + '/update',
            data,
            { headers: new HttpHeaders() });
    }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-assessment',
  templateUrl: 'assessment.component.html'
})
export class AssessmentComponent implements OnInit {
  @Input() setting: any;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}

import { Component, Input, QueryList, ViewChildren } from "@angular/core";

@Component({
    selector: 'app-home-component-list',
    templateUrl: './component-list.component.html',
    styleUrls: ['../home.component.scss']
})

export class ComponentListComponent {
    @Input() owner;
    @Input() list;

    @ViewChildren('bannerComponent') bannerComponent: QueryList<any>;

    reloadBanner(event: any) {
        this.bannerComponent.map(banner => {
            banner.reload();
        })
    }

}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-slides-list',
  templateUrl: 'slides-list.component.html'
})
export class SlidesListComponent {

  @Input() item: any = {};
  @Input() setting: any;
  @Input() data: any;
  @Input() full: boolean;

  public price: any;

}

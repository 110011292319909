import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-slides-card-flix',
  templateUrl: 'slides-card-flix.component.html'
})
export class SlidesCardFlixComponent {
  @Input() setting: any;
  @Input() item: any;

  ngOnInit(): void {
    console.log('[SlidesCardFlixComponent] setting', this.setting);
    console.log('[SlidesCardFlixComponent] item', this.item);
  }
}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';


@Component({
    selector: 'app-col-left',
    templateUrl: './col-left.component.html'
})

export class ColLeftComponent implements OnInit {

    @Input() public layout: number;
    @Input() public section: string = 'conteudo';

    @Output() public insertItemEvent = new EventEmitter<any>();
    @Output() public sectionChanged = new EventEmitter<any>();
    @Output() public selectLayoutItemEvent = new EventEmitter<any>();

    @Input() public page;

    managers: any;

    layoutComponentSelected: any;

    topComponentArray = [
        {
            component: 'header',
            label: 'Cabeçalho',
            icon: 'bi-square-half rotate-90'
        },
        {
            component: 'search',
            label: 'Busca',
            icon: 'bi-search'
        }
    ];

    componentArray = [
    ];

    sidebarComponentArray = [
        {
            component: 'pontos',
            label: 'Pontos',
            icon: 'bi-123'
        },
        {
            component: 'shortcuts',
            label: 'Atalhos',
            icon: 'bi-list-ul'
        },
        {
            component: 'menu',
            label: 'Menu',
            icon: 'bi-list-ul'
        },
        {
            component: 'credits',
            label: 'Créditos',
            icon: 'bi-credit-card'
        },
        {
            component: 'conquistas',
            label: 'Conquistas',
            icon: 'bi-trophy'
        },
        {
            component: 'colaboradores',
            label: 'Colaboradores',
            icon: 'bi-people'
        },
        {
            component: 'aniversariantes',
            label: 'Aniversariantes',
            icon: 'bi-balloon'
        },
        {
            component: 'dates',
            label: 'Próximas datas',
            icon: 'bi-calendar'
        },
        {
            component: 'tecnico',
            label: 'Documentos',
            icon: 'bi-mortarboard'
        },
        {
            component: 'forum',
            label: 'Fórum',
            icon: 'bi-receipt'
        },
        {
            component: 'info-talks',
            label: 'Conversas',
            icon: 'bi-chat-left'
        },
        {
            component: 'benefits',
            label: 'Beneficios',
            icon: 'bi-gift'
        },
        {
            component: 'vacancies',
            label: 'Minhas Vagas',
            icon: 'bi-person-circle'
        },
        {
            component: 'banner-link',
            label: 'Banner com Link',
            icon: 'bi-layout-sidebar-inset rotate-90'
        },
        {
            component: 'usuarios-sidebar',
            label: 'Usuários',
            icon: 'bi-person-plus',
        },
        {
            component: 'forum-theme-sidebar',
            label: 'Fórum Temático',
            icon: 'bi-receipt',
        },
        {
            component: 'featured',
            label: 'Destaques',
            icon: 'bi-bookmark',
        },
        {
            component: 'library',
            label: 'Biblioteca',
            icon: 'bi-collection',
        },
        {
            component: 'ecommerce-featured',
            label: 'Destaques (e-commerce)',
            icon: 'bi-award',
        },

    ];

    fullComponentArray: [];

    constructor(
        public dataService: DataService
    ) {

    }

    ngOnInit() {
        this.getMenu();
    }

    getMenu() {
        this.dataService.menu().subscribe((res: any) => {
            const menuAll = res.data[0].resource_sub;
            console.log('ColLeftComponent: getMenu: menuAll', menuAll);
            if (menuAll) {
                this.managers = menuAll.filter((item: any) => (['editor-ead','gerenciadores'].includes(item.parent) && item.enabled === true));
            }
            console.log('ColLeftComponent: getMenu: this.managers', this.managers);

            this.setComponents();
        });
    }

    setComponents() {

        const components = [
            {
                component: 'banner',
                label: 'Banner',
                icon: 'bi-layout-sidebar-inset rotate-90',
            },
            {
                component: 'data',
                label: 'Meu dados',
                icon: 'bi-hdd'
            },
            {
                component: 'html',
                label: 'HTML',
                icon: 'bi-code'
            },
            {
              component: 'showcase-section',
              label: 'Vitrine',
              icon: 'bi-collection'
            },
          ];

        const dynamicComponents = {
            'feed': [
                {
                    component: 'feed',
                    label: 'Feed',
                    icon: 'bi-rss',
                },
            ],
            'cursos-sessao': [
                {
                    component: 'cursos-sessao',
                    label: 'Cursos por seção',
                    icon: 'bi-mortarboard',
                },
            ],
            'enquete-sessao': [
                {
                    component: 'enquete-sessao',
                    label: 'Enquetes por seção',
                    icon: 'bi bi-bar-chart-line-fill',
                },
            ],
            'mentoria': [
                {
                    component: 'mentoria-section',
                    label: 'Mentoria por seção',
                    icon: 'bi-briefcase',
                },
            ],
            documentos: [
                {
                    component: 'main-tecnico',
                    label: 'Documentos',
                    icon: 'bi-folder'
                },
            ],
            assessment: [
                {
                    component: 'assessment',
                    label: 'Assessment',
                    icon: 'bi-clipboard-check',
                },
            ],
            trilhas: [
                {
                    component: 'slides',
                    label: 'Slide',
                    icon: 'bi-stickies',
                },
                {
                    component: 'in_progress',
                    label: 'Cursos em andamento',
                    icon: 'bi-clock-history',
                },
            ],
            'trilhas-mini': [
                {
                    component: 'mini',
                    label: 'Reflexões e experiência',
                    icon: 'bi-chat',
                },
            ],
            'trilhas-develop': [
                {
                    component: 'influenciadores',
                    label: 'Influenciadores',
                    icon: 'bi-people',
                },
            ],
            'trilhas-artigos': [
                {
                    component: 'artigos',
                    label: 'Artigos',
                    icon: 'bi-journals',
                }
            ],
            'eventos-sessao': [
                {
                    component: 'evento-sessao',
                    label: 'Eventos por seção',
                    icon: 'bi-calendar2-event',
                }
            ],
            'aulas-rapidas-sessao': [
                {
                    component: 'aulas-rapidas-sessao',
                    label: 'Aulas rápidas por seção',
                    icon: 'bi-book',
                }
            ],
            'videos-sessao': [
                {
                    component: 'videos-sessao',
                    label: 'Vídeos por seção',
                    icon: 'bi-play-btn',
                }
            ],
            jornadas: [
                {
                    component: 'journey',
                    label: 'Jornada por sessão',
                    icon: 'bi-arrow-up-right',
                }
            ],
            'eventos-presenciais': [
                {
                    component: 'eventos',
                    label: 'Eventos',
                    icon: 'bi-calendar',
                },
            ],
            'trilhas-livres': [
                {
                    component: 'free',
                    label: 'Cursos livres',
                    icon: 'bi-laptop',
                },
            ],
            estudio: [
                {
                    component: 'studio',
                    label: 'Estúdio',
                    icon: 'bi-camera-video'
                }
            ],
            expositor: [
                {
                    component: 'expositores',
                    label: 'Expositores',
                    icon: 'bi-cast'
                },
            ],
            opportunity: [
                {
                    component: 'jobs',
                    label: 'Minhas vagas',
                    icon: 'bi-person-circle'
                },
            ],
            edital: [
                {
                    component: 'edital-influenciadores',
                    label: 'Formulários Editais',
                    icon: 'bi-clipboard-check',
                },
            ],
            blog: [
                {
                    component: 'news-section',
                    label: 'Notícias',
                    icon: 'bi-newspaper',
                },
            ],
            users: [
                {
                    component: 'users',
                    label: 'Usuários',
                    icon: 'bi-person-plus',
                },
            ],
            'multi-account': [
                {
                    component: 'multicontas',
                    label: 'Multicontas',
                    icon: 'bi-bookmarks',
                },
            ],
            'forum': [
                {
                    component: 'forum-theme',
                    label: 'Fórum Temático',
                    icon: 'bi-receipt',
                },
            ],
            'management-panel': [
                {
                    component: 'management-panel',
                    label: 'Painel de Gestão',
                    icon: 'bi-ui-checks',
                },
            ],
            'monitoring': [
                {
                    component: 'monitoring-comments',
                    label: 'Monitora - Comentários',
                    icon: 'bi-chat-square-quote'
                }
            ]
        };

        if (window.location.hostname == "localhost") {
            let tags = Object.keys(dynamicComponents);
            for (const tag of tags) {
                this.componentArray.push(...dynamicComponents[tag]);
            }

        }
        else {
            this.managers.forEach((item: any) => {
                if (dynamicComponents[item.tag]) {
                    this.componentArray.push(...dynamicComponents[item.tag]);
                } else {
                    console.log('ColLeftComponent:', item.tag);
                }
            });
        }

        this.componentArray.push(...components);

    }

    setSection(section: string) {
        this.sectionChanged.emit(section);
    }

    insertItem(type: string) {
        this.insertItemEvent.emit(type);
    }

    selectLayoutItem(item: string): void {
        this.layoutComponentSelected = item;
        this.selectLayoutItemEvent.emit(item);
    }

}

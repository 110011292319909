import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'layout-type',
  templateUrl: './layout-type.component.html',
  styleUrls: ['./layout-type.component.css'],

})
export class LayoutType implements OnInit {
  @Input()
  type!: string;

  @Input()
  activedColor?: string = "#fff";

  @Input()
  color?: string = "#2f378f";

  @Input()
  actived?: boolean = false;

  recs = [0.5, 21.5, 42.5];

  columns: number[] = [];
  constructor() {

  }
  ngOnInit(): void {
    this.columns = this.getColumnsByType(this.type);
    this.recs = this.recs.splice(0, this.columns.length);
  }


  getColumnsByType(type: string): number[] {
    var objColumns = {
      "full": [1],
      "center": [1, 1, 1],
      "left": [1, 1, 0],
      "right": [0, 1, 1],
    }
    return objColumns[type];
  }

}

import { NgModule } from '@angular/core';
import { ArrayGeneratePipe } from './array.pipe';
import { BgImagePipe } from './bgImage.pipe';
import { TextTruncatePipe } from './text-truncate.pipe';
import { AsapClientPipe } from './asap-client.pipe';
import { SafePipe } from './safe.pipe';

const pipes = [
  ArrayGeneratePipe,
  BgImagePipe,
  TextTruncatePipe,
  AsapClientPipe,
  SafePipe
];

@NgModule({
  declarations: [
    ... pipes
  ],
  exports: [
    ... pipes
  ]
})

export class PipeModule { }

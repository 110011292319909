import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-editor-custom-component-data',
  templateUrl: 'data.component.html'
})
export class DataComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() full: boolean;

  now = new Date();

  lastitemData: any;
  featuredData: any;

  showFeatured: boolean;
  featureLoading: boolean;

  data: any;

  constructor(
    private dataService: DataService,
    public sanitizer: DomSanitizer
  ) {
    this.featureLoading = true;
  }

  ngOnChanges(changes: any) {
    console.log('DataComponent', changes);
  }

  ngOnInit(): void {
    this.getData();
  }

  maskBackground(color: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`
      linear-gradient(180deg, transparent 60%, ${color}),
      linear-gradient(180deg, transparent 5%, transparent 20%),
      linear-gradient(270deg, transparent 30%, ${color} 80%)
    `);
  }

  getData() {
    this.dataService.data().subscribe(res => {
      this.data = res.data;
    });
  }

}

import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService, Channel } from 'src/app/@core/services/data.service';
import { EventsService } from 'src/app/@core/services/events.service';

@Component({
  selector: 'app-editor-components-shared-featured-config',
  templateUrl: 'featured-config.component.html',
  providers: [DataService]
})
export class SharedFeaturedConfigComponent implements OnInit {

  @Input() item: any;
  @Input() key: string;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadBannerEvent = new EventEmitter<any>();

  courseSectionData: any;
  eventSectionData: any;
  eventSections: any;

  channelList$: Observable<Channel[]>;
  channelItems$: Observable<Channel[]>;

  tabs = {
    web: true,
    celular: false
  };

  constructor(
    private dataService: DataService,
    private eventsService: EventsService
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  public selectTab(select, markOff) {
    this.tabs[select] = true;
    this.tabs[markOff] = false;
  }

  reloadBanner() {
    this.reloadBannerEvent.emit(this.item);
  }
  getImage($event: any, field: string): void {
    this.item[field] = $event.path;
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getEmblemPhoto($event: any): void {
    this.item.emblem = $event.path;
  }

  getData() {
    this.dataService.cursosSessao().subscribe(res => {
      this.courseSectionData = res;
    });

    this.dataService.eventoSessao()
      .subscribe(res => {
        this.eventSections = res;
      });

    this.dataService.custom('event-sessao', 8).subscribe((res: any) => {
      this.eventSectionData = res.data['evento-sessao'];
    });

    this.channelList$ = this.dataService.channels();
  }

  courseBySection(section: any) {
    if (!this.courseSectionData) {
      return;
    }
    const find = this.courseSectionData.find((item: any) => +item.session_id === +section);
    if (find) {
      return find.trail;
    }
  }

  eventBySection(section: any) {
    if (!this.eventSectionData) {
      return;
    }
    const filter = this.eventSectionData.filter((item: any) => +item.session_id === +section);
    if (filter.length > 0) {
      return filter;
    }
  }

  carouselItemChange(event,item) {
    delete(item.section);
    delete(item.id);
    delete(item.arquivo);
  }

  channelIDChange(value) {
    let type = this.item.type.split('-')[1];
    if (type) {
      this.channelItems$ = this.dataService.channelItems(value,type);
    }
    else {
      this.pubEventFeaturedChanged();
    }
  }

  channelItemIDChange(value,list) {
    let item = list.find(i => i.id == value);
    if (item) {
      this.item.item_id = item.id;
      this.item.altImage = true;
      this.item.banner = item.image;
    }

    this.pubEventFeaturedChanged();
  }

  pubEventFeaturedChanged() {
    this.eventsService.broadcast('featured-changed');
  }

}

import { Component, Input } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-usuarios-sidebar',
  templateUrl: 'usuarios-sidebar.component.html',
  styleUrls: ['./usuarios-sidebar.component.scss']
})
export class UsuariosSidebarComponent {
  @Input() item: any;
  data: any;

  constructor(
    public dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.users()
      .subscribe((res: any) => {
        this.data = res.data;
      });
  }

}

import { NgModule } from "@angular/core";
import { SelectUserSelectComponent } from "./select.component";
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material";

@NgModule({
    imports: [FormsModule, BrowserModule, MatInputModule, MatIconModule, TranslateModule, MatFormFieldModule],
    declarations: [SelectUserSelectComponent],
    exports: [SelectUserSelectComponent],
    bootstrap: [SelectUserSelectComponent]
})
export class SelectUserSelectModule { }
import { Component, OnInit, ViewChild, ViewChildren, QueryList, TemplateRef, Input } from '@angular/core';
import { ClientService } from '../@core/services/client.service';
import { LayoutService } from '../@core/services/layout.service';
import { DataService } from '../@core/services/data.service';
import { AsapClientPipe } from '../@core/pipes/asap-client.pipe';
import { NavbarMenuItem } from '../components/navbar/config/navbar-config.component';
import { Global } from 'asap-crud';
import slugify from 'slugify';
import { AdminService } from '../@core/services/admin.service';
import { PermissionModalComponent } from './permission-modal/permission-modal.component';
import { MatDialog } from '@angular/material';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [AdminService]
})

export class HomeComponent implements OnInit {

    @ViewChild('headerComponent', { static: false }) headerComponent: any;
    @ViewChildren('homeComponentsListEl') homeComponentsListEl: QueryList<any>;

    @ViewChild("pageSettings", { static: false }) pageSettings: TemplateRef<any>;

    @ViewChild("colLeft", { static: false }) colLeft;

    @ViewChild("navComponent", { static: false }) navComponent;
    @ViewChild("sidebarComponent", { static: false }) sidebarComponent;
    @ViewChild("footerComponent", { static: false }) footerComponent;
    @ViewChild("navMobileComponent", { static: false }) navMobileComponent;


    public homeSettings;
    public listSettings = [];
    public gridLayout = [0, 12, 0];
    public showIconBox;

    listIcons = [
        'bi-sticky',
        'bi-house',
        'bi-mortarboard',
        'bi-camera-video',
        'bi-bookmark',
        'bi-rss',
        'bi-folder',
        'bi-collection-play',
        'bi-bookmark-star',
        'bi-box-arrow-up-right',
        'bi-play-btn',
        'bi-book'
    ];

    id: number;
    item: any;
    trailStageSelected: any;
    settings: any;
    layout: number;
    styleZoom: number;
    page: any;

    @Input() customData: any;


    data: any;

    navbarSectionLinks = {
        news: {
            label: 'Notícias',
            link: '/all-news'
        },
        trail: {
            label: 'Cursos',
            link: '/training'
        }
    };

    public componentArray: Array<any>;
    public sidebarComponentArray: Array<any>;
    public sidebarLeftComponentArray: Array<any>;
    public fullComponentArray: Array<any>;
    public topComponentArray: Array<any>;

    public itemSelected: any;
    public status: string;
    componentLoading = true;

    section = 'conteudo';

    layoutComponentSelected: string;

    inProgressData: any;

    screen = 'desktop';


    selectedGrid: any = 'center';

    html = document.querySelector('html');


    public navbar = {
        type: 'navbar'
    }

    public footer = {
        type: 'footer'
    }

    public sidebar = {
        type: 'sidebar'
    }

    public navbarMobile = {
        type: 'navbarMobile'
    }

    public layoutFeedbackMessages = {
        "0,12,0": "Este layout usa 100% da tela",
        "3,9,0": "Este layout alinha o conteúdo a esquerda",
        "3,6,3": "Este layout centraliza o conteúdo",
        "0,9,3": "Este layout alinha o conteúdo a direita",
    }
    dynamicType: string;

    get selectedPage(): any {
        return this.dataService.selectedPage;
    }

    set selectedPage(value: any) {
        this.dataService.selectedPage = value;
    }

    constructor(
        private dataService: DataService,
        public clientService: ClientService,
        private layoutService: LayoutService,
        public service: AdminService,
        public dialog: MatDialog,
        public global: Global
    ) {
        if (localStorage.getItem('stage_zoom') || sessionStorage.getItem('stage_zoom')) {
            this.styleZoom = +(localStorage.getItem('stage_zoom') || sessionStorage.getItem('stage_zoom'));
        } else {
            this.styleZoom = 0.6;
        }
        this.html.style.setProperty('--editor-zoom', `${this.styleZoom}`);


        this.customData = [];
        this.layoutComponentSelected = 'info';
        this.getCustomization();
    }

    changeShowIconBox() {
        this.showIconBox = !this.showIconBox;
    }

    changePageIcon(iconName) {
        this.selectedPage.iconPage = iconName;
        this.settings.navbarMenuMobile = this.settings.navbarMenuMobile.map((item) => {
            if (item.id === this.selectedPage.id) {
                item.iconPage = iconName;
            }
            return item;
        });
    }

    getData() {
        this.dataService.web().subscribe(res => {
            let data = res.data;
            this.data = data;
            this.getInProgressData();
        });
    }

    getInProgressData() {
        this.dataService.progress().subscribe((res: any) => {
            this.inProgressData = res.data;
            this.componentLoading = false;
        });
    }


    ngOnInit() {

        this.status = '';
        this.getColor();

        $(document).ready(function () {
            $('.abas-container').on('mousewheel DOMMouseScroll', function (e) {
                e.preventDefault();
                var delta = e.originalEvent.wheelDelta || -e.originalEvent.detail;
                this.scrollLeft += (delta < 0 ? 1 : -1) * 30;
            });

            $('.scroll-left').click(function () {
                $('.abas-container').animate({
                    scrollLeft: '-=300' // You can adjust the scroll distance
                }, 'slow');
            });

            $('.scroll-right').click(function () {
                $('.abas-container').animate({
                    scrollLeft: '+=300' // You can adjust the scroll distance
                }, 'slow');
            });
        });

    }

    getCustomization() {
        this.clientService.getClientData().subscribe((response: any) => {
            this.clientService.getColor()
                .subscribe((res: any) => {
                    if (!res[0].config) {
                        this.settings = {};
                    } else if (res[0].config.url) {
                        this.settings = res[0].config;

                        this.customData = this.settings.myColors;
                    } else {
                        this.settings = res[0].config;
                    }

                    this.layout = response.data.layout;
                    if ([1, 4, 5, 6].indexOf(this.layout) >= 0) {
                        if ((!this.settings.firebase) || (Array.isArray(this.settings.firebase))) {
                            this.settings.firebase = {};
                        }
                    }

                    if (!this.settings.navbarMenuItems) {
                        this.settings.navbarMenuItems = [];
                    }

                    AsapClientPipe.setDictionary(this.settings);

                    this.getCustomData();
                    console.log("navbarMenuMobile: ", this.settings.navbarMenuMobile);
                });
        });
    }

    public openModal(page) {
        const dialogRef = this.global.dialog.open(PermissionModalComponent, {
            data: { ...page, type: 'dynamic' }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }


    getCustomData() {
        this.layoutService.getResources()
            .subscribe((res: any) => {
                this.homeSettings = {
                    main: [],
                    sidebarLeft: [],
                    sidebar: [],
                    top: [],
                    pages: []
                };
                this.componentArray = this.homeSettings.main;
                this.sidebarLeftComponentArray = this.homeSettings.sidebarLeft;
                this.sidebarComponentArray = this.homeSettings.sidebar;
                this.topComponentArray = this.homeSettings.top;

                if (res.data && res.data.value.main && res.data.value.sidebar) {
                    const value = res.data.value;
                    this.homeSettings = value;

                    if (!this.homeSettings.pages) {
                        this.homeSettings.pages = [];
                    }

                    this.componentArray = (value.main) ? value.main : [];
                    this.sidebarLeftComponentArray = (value.sidebarLeft) ? value.sidebarLeft : value.sidebarLeft = [];
                    this.sidebarComponentArray = (value.sidebar) ? value.sidebar : [];
                    this.topComponentArray = (value.top) ? value.top : [];
                }
                this.selectFirstItem();
                this.getData();
            });
    }

    saveCustomization() {
        console.log("navbarMenuMobile: ", this.settings.navbarMenuMobile);
        this.clientService.putColor(this.settings).subscribe((res: any) => {
            console.log('[saveCustomization]', res);
        });
    }

    save(type = null) {
        console.log("[type] navBarMobile", type);
        if (['navbar', 'footer', 'sidebar', 'navbarMobile'].includes(type)) {
            this.saveCustomization();
            if (type == 'navbar') {
                this.global.toast('Configuração de Menu salva com sucesso!');
            }
            else if (type == 'sidebar') {
                this.global.toast('Configuração de Menu Lateral salva com sucesso!');
            }
            else if (type == 'footer') {
                this.global.toast('Configuração de Rodapé salva com sucesso!');
            }
            else if (type == 'navbarMobile') {
                this.global.toast('Configuração de Menu inferior salva com sucesso!');
            }
        }
        else {
            this.homeSettings.pages = this.homeSettings.pages.filter(p => !p.deleted).map(p => {
                delete (p.deleted);
                return p;
            })

            this.global.toast('Ajustes salvos com sucesso!');
            this.layoutService.createResource({ value: this.homeSettings }).subscribe((res: any) => {
                console.log('saved: data', res);
            });
        }
    }

    selectFirstItem() {
        console.log("[selectFirstItem]", this.topComponentArray, this.componentArray);
        if (this.topComponentArray.length > 0) {
            this.selectItem(this.topComponentArray[0]);
        }
        else {
            if (this.componentArray.length > 0) {
                this.selectItem(this.componentArray[0]);
            }
            else {
                this.selectItem(null);
            }
        }
    }

    insertItem(event: string) {
        if (event == 'navbar') {
            this.selectItem(this.navbar);
            this.navComponent.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
        else if (event == 'sidebar') {
            this.selectItem(this.sidebar);
            this.sidebarComponent.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
        else if (event == 'footer') {
            this.selectItem(this.footer);
            this.footerComponent.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
        else if (event == 'navbarMobile') {
            this.selectItem(this.navbarMobile);
            this.navMobileComponent.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
        else {
            let inserted = true;

            console.log('[insertItem] type', event);
            let componentArray = this.componentArray;

            if (this.selectedPage != null) {
                if (this.selectedGrid == 'left') {
                    componentArray = this.sidebarLeftComponentArray;
                }
                if (this.selectedGrid == 'right') {
                    componentArray = this.sidebarComponentArray;
                }
            }

            const findComponent = componentArray.find(i => i.type === event);
            let duplicable = [
                'banner',
                'banner-link',
                'cursos-sessao',
                'enquete-sessao',
                'videos-sessao',
                'evento-sessao',
                'aulas-rapidas-sessao',
                'news-section',
                'html',
                'header',
                'menu',
                'forum-theme',
                'forum-theme-sidebar',
                'mentoria-section',
                'multicontas',
                'journey',
                'showcase-section'
            ];

            if (findComponent) {
                if (duplicable.indexOf(event) < 0) {
                    this.selectItem(findComponent);
                    return;
                }
            }

            const findSidebarComponent = this.sidebarComponentArray.find(i => i.type === event);
            if (findSidebarComponent) {
                if (duplicable.indexOf(event) < 0) {
                    this.selectItem(findSidebarComponent);
                    return;
                }
            }

            const findTopComponent = this.topComponentArray.find(i => i.type === event);
            if (findTopComponent) {
                if (!findTopComponent.type.includes(duplicable)) {
                    this.selectItem(findTopComponent);
                    return;
                }

            }

            if (event === 'studio') {
                componentArray.push({
                    type: 'studio',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'ESTÚDIO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        style: 'cards',
                        background: '',
                        loading: this.settings.primaryColor,
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONTINUAR',
                            textInitial: 'INICIAR',
                            textDisabled: 'INDISPONÍVEL'
                        }
                    },
                    coverStyle: 'title'
                });
            }

            if (event === 'slides') {
                componentArray.push({
                    type: 'slides',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'TÍTULO AQUI',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'SUB-TÍTULO AQUI',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        style: 'carousel',
                        background: '',
                        loading: this.settings.primaryColor,
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONTINUAR',
                            textInitial: 'INICIAR',
                            textDisabled: 'INDISPONÍVEL'
                        }
                    }
                });
            }

            if (event === 'shortcuts') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente MENU simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'shortcuts',

                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Atalhos',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'menu') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente MENU simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'menu',
                    title: {
                        text: 'Menu',
                        background: '#ddd',
                        color: '#333'
                    },
                    design: {
                        accordion: 'static',
                        template: 'template-1',
                        background: '',
                        color: '#333',
                        style: 'no-border',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingX: 0
                    }
                });
            }

            if (event === 'users') {
                componentArray.push({
                    type: 'users',
                    title: {
                        text: 'USUÁRIOS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'SUAS CONEXÕES',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    subtitle1: {
                        show: true
                    },
                    subtitle2: {
                        show: true
                    },
                    coverStyle: 'image',
                    design: {
                        style: 'cards',
                        loadingDisplay: false,
                        background: '',
                        loading: '#BBBBBB',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONECTAR',
                            textDisconnect: 'DESCONECTAR'
                        }
                    }
                });
            }

            if (event === 'multicontas') {
                componentArray.push({
                    type: 'multicontas',
                    title: {
                        text: 'MULTICONTAS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'SUAS PLATAFORMAS',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    subtitle1: {
                        show: true
                    },
                    subtitle2: {
                        show: true
                    },
                    coverStyle: 'image',
                    design: {
                        style: 'cards',
                        loadingDisplay: false,
                        background: '',
                        loading: '#BBBBBB',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'ACESSAR',
                        }
                    }
                });
            }

            if (event === 'mini') {
                componentArray.push({
                    type: 'mini',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'REFLEXÕES E EXPERIÊNCIAS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'APRENDA COM A PRÁTICA',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONTINUAR'
                        }
                    }
                });
            }

            if (event === 'influenciadores') {
                componentArray.push({
                    type: 'influenciadores',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'INFLUENCIADORES',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'PARTICIPE DA REDE DE CONHECIMENTO COLABORATIVO',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '#FFFFFF',
                        progress: this.settings.primaryColor,
                        style: 'carousel',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONTINUAR'
                        }
                    }
                });
            }

            if (event === 'in_progress') {
                componentArray.push({
                    type: 'in_progress',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'Meus cursos em andamento',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '#FFFFFF',
                        progress: this.settings.primaryColor,
                        style: 'carousel',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'CONTINUAR'
                        }
                    }
                });
            }

            if (event === 'edital-influenciadores') {
                componentArray.push({
                    type: 'edital-influenciadores',
                    title: {
                        text: 'FORMULÁRIOS EDITAIS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '#FFFFFF',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'PARTICIPAR'
                        }
                    }
                });
            }

            if (event === 'artigos') {
                componentArray.push({
                    type: 'artigos',
                    title: {
                        text: 'LEITURA E CONHECIMENTO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'APRENDA COM A CIÊNCIA',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3',
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF'
                        }
                    }
                });
            }

            if (event === 'videos-sessao') {
                componentArray.push({
                    type: 'videos-sessao',
                    title: {
                        text: 'VÍDEOS POR SESSÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        style: 'cards',
                        items: '3'
                    },
                    layout: {
                        coverStyle: 'cover',
                        background_color: '',
                        color: '',
                    },
                    paddingTop: 20,
                    paddingBottom: 20
                });
            }

            if (event === 'showcase-section') {
                componentArray.push({
                    type: 'showcase-section',
                    cardBackground: "#000000",
                    cardButtonBackground: this.settings.primaryColor,
                    cardButtonColor: "#ffffff",
                    cardForegroundBottom: "#000000",
                    cardForegroundTop: this.settings.primaryColor,
                    contentResumeColor: "#ffffff",
                    contentSubtitleColor: "#ffffff",
                    contentTitleColor: "#ffffff",
                    design: {
                        background: '#ffffff',
                        items: '3'
                    },
                    layout: "carousel",
                    marginBottom: 0,
                    marginTop: 0,
                    paddingBottom: 30,
                    paddingTop: 30,
                    section_id: null,
                    style: "carousel",
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    title: {
                        text: 'VÍTRINE',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    titlePaddingBottom: 30,
                });
            }

            if (event === 'mentoria-section') {
                componentArray.push({
                    type: event,
                    title: {
                        text: 'MENTORIA POR SEÇÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    },
                    layout: {
                        coverStyle: 'cover',
                        background_color: '',
                        color: '',
                    },
                    paddingTop: 20,
                    paddingBottom: 20
                });
            }

            if (event === 'evento-sessao') {
                componentArray.push({
                    type: 'evento-sessao',
                    layout: 'cards',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'EVENTOS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    }
                });
            }

            if (event === 'news-section') {
                componentArray.push({
                    type: 'news-section',
                    layout: 'cards',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'NOTÍCIAS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    }
                });
            }

            if (event === 'videos') {
                componentArray.push({
                    type: 'videos',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'VÍDEOS INSPIRADORES',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    },
                    layout: {
                        coverStyle: 'cover',
                        background_color: '',
                        color: '',
                    }
                });
            }

            if (event === 'gallery') {
                componentArray.push({
                    type: 'gallery',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'GALERIA',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3',
                        style: 'cards_2'
                    },
                    layout: {
                        coverStyle: 'cover',
                        background_color: '',
                        color: '',
                    },
                    coverStyle: 'imageFull'
                });
            }

            if (event === 'html') {
                componentArray.push({
                    type: 'html',
                    visibility: 'guest',
                    html: '',
                    css: ''
                });
            }

            if (event === 'journey') {
                componentArray.push({
                    type: 'journey',
                    isNew: true,
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'JORNADAS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    style: 'default',
                    design: {
                        background: '',
                        items: '3'
                    }
                });
            }

            if (event === 'eventos') {
                componentArray.push({
                    type: 'eventos',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'EVENTOS',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    }
                });
            }

            if (event === 'banner') {
                componentArray.push({
                    type: 'banner',
                    titleText: 'Título aqui',
                    titleColor: '#222222',
                    titleAlign: 'center',
                    titleSize: 32,
                    subtitleText: '',
                    subtitleColor: '#BBBBBB',
                    subtitleAlign: 'center',
                    subtitleSize: 18,
                    marginTop: 30,
                    marginBottom: 30,
                    paddingY: 30,
                    background: ''
                });
            }

            if (event === 'assessment') {
                componentArray.push({
                    type: 'assessment',
                    marginTop: 30,
                    marginBottom: 30,
                    title: {
                        text: '',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                });
            }

            if (event === 'free') {
                componentArray.push({
                    type: 'free',
                    title: {
                        text: 'CURSOS LIVRES',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3'
                    }
                });
            }

            if (event === 'feed') {
                componentArray.push({
                    type: 'feed',
                    template: 'template-1',
                    marginTop: 20,
                    marginBottom: 20
                });
            }

            if (event === 'onboard') {
                componentArray.push({
                    type: 'onboard',
                    background: '#f30',
                });
            }

            if (event === 'cursos-sessao') {
                componentArray.push({
                    type: 'cursos-sessao',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'NOVA SESSÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    coverStyle: 'image',
                    design: {
                        style: 'cards',
                        loadingDisplay: false,
                        background: '',
                        loading: '#BBBBBB',
                        button: {
                            background: '',
                            color: '#FFFFFF',
                            text: 'CONTINUAR',
                            textPlan: 'ADQUIRA UM PLANO!',
                            textBuy: 'COMPRAR',
                            textInitial: 'INICIAR',
                            textDisabled: 'INDISPONÍVEL'
                        }
                    }
                });
            }

            if (event === 'enquete-sessao') {
                componentArray.push({
                    type: 'enquete-sessao',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'NOVA SESSÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    coverStyle: 'image',
                    design: {
                        style: 'cards',
                        loadingDisplay: false,
                        background: '',
                        loading: '#BBBBBB',
                        button: {
                            background: '',
                            color: '#FFFFFF',
                            text: 'CONTINUAR',
                            textPlan: 'ADQUIRA UM PLANO!',
                            textBuy: 'COMPRAR',
                            textInitial: 'INICIAR',
                            textDisabled: 'INDISPONÍVEL'
                        }
                    }
                });
            }

            if (event === 'aulas-rapidas-sessao') {
                componentArray.push({
                    type: 'aulas-rapidas-sessao',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'NOVA SESSÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    coverStyle: 'image',
                    design: {
                        style: 'cards',
                        loadingDisplay: false,
                        background: '',
                        loading: '#BBBBBB',
                        button: {
                            background: '',
                            color: '#FFFFFF',
                            text: 'CONTINUAR',
                            textInitial: 'INICIAR',
                            textDisabled: 'INDISPONÍVEL'
                        }
                    }
                });
            }

            if (event === 'data') {
                componentArray.push({
                    type: 'data',
                    paddingTop: 20,
                    paddingBottom: 20,
                    title: {
                        text: 'NOVA SESSÃO',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    seeAllHidden: false
                });
            }

            if (event === 'pontos') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "PONTOS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'pontos',
                    design: {
                        template: 'template-1',
                        featured: {
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'credits') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "CRÉDITOS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'credits',
                    design: {
                        template: 'template-1',
                        featured: {
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            background: '#ddd',
                            color: '#333'
                        },
                        modal: {
                            title: 'Titulo da Modal',
                            description: 'Descrição da modal',
                            buttontext: 'Botão'
                        }
                    }
                });
            }

            if (event === 'conquistas') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "CONQUISTAS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'conquistas',
                    design: {
                        title: {
                            text: 'CONQUISTAS',
                            background: this.settings.primaryColor,
                            color: '#FFFFFF'
                        }
                    }
                });
            }

            if (event === 'colaboradores') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "COLABORADORES" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'colaboradores',
                    layout: 'parallelogram',
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'COLABORADORES',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'aniversariantes') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "ANIVERSARIANTES" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'aniversariantes',
                    layout: 'parallelogram',
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Aniversariantes do dia',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'dates') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "DATA" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'dates',
                    layout: 'parallelogram',
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Próximas datas',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'usuarios-sidebar') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "USUÁRIOS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'usuarios-sidebar',
                    layout: 'parallelogram',
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Usuários',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'forum') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente FÓRUM simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'forum',
                    layout: 'parallelogram',
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Forum',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'forum-theme') {
                componentArray.push({
                    type: 'forum-theme',
                    title: {
                        text: 'Nova seção',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Forums Temáticos',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        columns: 'col-sm-4',
                        background: '',
                        loading: '#BBBBBB',
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingX: 20,
                    }
                });
            }

            if (event === 'forum-theme-sidebar') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente FÓRUM TEMÁTICO simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'forum-theme-sidebar',
                    layout: 'parallelogram',
                    session_id: null,
                    design: {
                        template: 'template-1',
                        title: {
                            text: 'Fórum temático',
                            background: '#ddd',
                            color: '#333'
                        },
                        button: {
                            text: 'VER TODOS',
                            background: '#ddd',
                            color: '#333'
                        }
                    }
                });
            }

            if (event === 'featured') {
                componentArray.push({
                    template: 'template-1',
                    type: 'featured',
                    layout: 'parallelogram',
                    marginTop: 0,
                    marginBottom: 0,
                    titleText: 'Cursos',
                    titleBackground: '#ddd',
                    titleColor: '#333',
                    cardTitleColor: '#333'
                });
            }

            if (event === 'library') {
                componentArray.push({
                    type: 'library',
                    marginTop: 0,
                    marginBottom: 0,
                    coverStyle: 'imageFull',
                    titleBackground: '#ddd',
                    titleColor: '#333',
                    titleLibrary: 'Biblioteca',
                    titleChannel: 'Canais'
                });
            }

            if (event === 'ecommerce-featured') {
                componentArray.push({
                    type: 'ecommerce-featured',
                    marginTop: 0,
                    marginBottom: 0
                });
                this.lastSelect('right');
            }

            if (event === 'tecnico') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "DOCUMENTOS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'tecnico',
                });
            }

            if (event === 'benefits') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "BENEFÍCIOS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'benefits',
                    marginTop: 0,
                    marginBottom: 0,
                    coverStyle: 'imageFull',
                    titleBackground: '#ddd',
                    titleColor: '#333',
                    titleText: 'Benefícios',
                });
            }

            if (event === 'banner-link') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente BANNER COM LINK simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'banner-link',
                });
            }

            if (event === 'vacancies') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "MINHAS VAGAS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'vacancies',
                });
            }

            if (event === 'info-talks') {
                if (this.selectedPage == null) {
                    componentArray = this.sidebarComponentArray;
                    if (this.isCardFlix) {
                        this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente "CONVERSAS" simultaneamente ao card do tipo flix' });
                        componentArray = [];
                        inserted = false;
                    }
                }
                componentArray.push({
                    type: 'info-talks',
                });
            }

            if (event === 'header') {
                componentArray = this.topComponentArray;
                componentArray.push({
                    type: 'header',
                    layout: 'texto',
                    buttonText: 'VER MAIS',
                    buttonBackground: this.settings.primaryColor,
                    buttonColor: '#ffffff',
                    carousel: [],
                    carouselHeight: 40,
                });
            }

            if (event === 'search') {
                componentArray = this.topComponentArray;
                if (this.isHeaderFlix) {
                    this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente BUSCA simultaneamente ao card do tipo flix' });
                    componentArray = [];
                    inserted = false;
                }
                componentArray.push({
                    type: 'search',
                    layout: 'campo'
                });
            }

            if (event === 'slider') {
                componentArray = this.topComponentArray;
                if (this.isHeaderFlix) {
                    this.global.toastService.show({ class: 'toast-custom', position: 'topRight', message: 'Não é possível adicionar o componente SLIDES simultaneamente ao card do tipo flix' });
                    componentArray = [];
                    inserted = false;
                }
                componentArray.push({
                    type: 'slider',
                    layout: 'campo'
                });
            }

            if (event === 'main-tecnico') {
                componentArray.push({
                    type: 'main-tecnico',
                    title: {
                        text: 'Documentos',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Documentos e artigos',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        layout: 'resume'
                    }
                });
            }

            if (event === 'palestrantes') {
                componentArray.push({
                    type: 'palestrantes',
                    title: {
                        text: 'Palestrantes',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Subtítulo',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        layout: 'resume'
                    }
                });
            }

            if (event === 'talks') {
                componentArray.push({
                    type: 'talks',
                    title: {
                        text: 'Conversas',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Salas e Grupos',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        layout: 'resume'
                    }
                });
            }

            if (event === 'expositores') {
                componentArray.push({
                    type: 'expositores',
                    title: {
                        text: 'Expositores',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        layout: 'resume'
                    }
                });
            }

            if (event === 'management-panel') {
                componentArray.push({
                    type: 'management-panel',
                    seeAllHidden: true,
                    statusSuccess: "Concluído",
                    statusWarning: "Pendente",
                    statusFinished: "Prazo Finalizado",
                    statusEvaluated: "Avaliado",
                    columnPFA: "PFA",
                    columnTitle: "Título",
                    columnLimit: "Data Limite",
                    columnStatus: "Status",
                    columnStart: "Iniciar",
                    title: {
                        text: 'Painel de gestão',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Subtítulo do painel de gestão',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        layout: 'resume'
                    }
                });
            }

            if (event === 'monitoring-comments') {
                componentArray.push({
                    type: 'monitoring-comments',
                    title: {
                        text: 'Comentários',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: 'Avaliação',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: ''
                    }
                });
            }

            if (event === 'jobs') {
                componentArray.push({
                    type: 'jobs',
                    titlePaddingBottom: 20,
                    marginBottom: 20,
                    coverStyle: 'imageFull',
                    contentTitleColor: '#222222',
                    contentSubtitleColor: '#BBBBBB',
                    title: {
                        text: 'Minhas vagas',
                        color: this.settings.primaryColor,
                        align: 'center',
                        size: 26
                    },
                    subtitle: {
                        text: '',
                        color: '#BBBBBB',
                        align: 'center',
                        size: 16
                    },
                    design: {
                        background: '',
                        items: '3',
                        style: 'cards',
                        buttonDisplay: true,
                        button: {
                            background: this.settings.primaryColor,
                            color: '#FFFFFF',
                            text: 'VER VAGA'
                        }
                    }
                });
            }

            if (inserted) {
                this.selectItem(componentArray[componentArray.length - 1]);
            }
        }
    }

    lastSelect(type: string): void {
        if (type === 'main') {
            this.selectItem(this.componentArray[this.componentArray.length - 1]);
        }

        if (type === 'sidebar') {
            this.selectItem(this.sidebarComponentArray[this.sidebarComponentArray.length - 1]);
        }

        if (type === 'top') {
            this.selectItem(this.topComponentArray[this.topComponentArray.length - 1]);
        }
    }

    selectItem(item: any) {
        if (item) {
            this.itemSelected = item;
            this.componentArray.map(i => this.reset(i));
            this.sidebarLeftComponentArray.map(i => this.reset(i));
            this.sidebarComponentArray.map(i => this.reset(i));
            this.topComponentArray.map(i => this.reset(i));

            this.itemSelected.active = true;
        }
        else {
            this.itemSelected = item;
            this.componentArray.map(i => this.reset(i));
            this.sidebarLeftComponentArray.map(i => this.reset(i));
            this.sidebarComponentArray.map(i => this.reset(i));
            this.topComponentArray.map(i => this.reset(i));
        }
    }

    reset(i: any) {
        i.active = false;
    }

    delete(list, item: any) {
        const index = list.indexOf(item);
        list.splice(index, 1);
    }

    deleteSidebar(item: any) {
        const index = this.sidebarComponentArray.indexOf(item);
        this.sidebarComponentArray.splice(index, 1);

        this.selectItem({});
    }

    deleteTop(item: any) {
        const index = this.topComponentArray.indexOf(item);
        console.log(this.topComponentArray[index]);
        this.topComponentArray.splice(index, 1);

        this.selectItem({});
    }

    selectLayoutItem($event: any): void {
        this.layoutComponentSelected = $event;
    }

    move(list, actualIndex: number, newIndex: number) {

        const cList = {
            top: this.topComponentArray,
            main: list,
            sidebar: this.sidebarComponentArray
        };

        const cArray = list;

        while (actualIndex < 0) {
            actualIndex += cArray.length;
        }
        while (newIndex < 0) {
            newIndex += cArray.length;
        }
        if (newIndex >= cArray.length) {
            let k = newIndex - cArray.length;
            while ((k--) + 1) {
                cArray.push(undefined);
            }
        }
        cArray.splice(newIndex, 0, cArray.splice(actualIndex, 1)[0]);
    }

    setStyleZoom(zoom: number, update = true) {
        localStorage.setItem('stage_zoom', zoom.toString());
        this.styleZoom = zoom;
        if (update) {
            this.html.style.setProperty('--editor-zoom', `${this.styleZoom}`);
        }
    }

    getColor() {
        const lu = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
        const user = JSON.parse(lu).data;
        this.dataService.color(user.client_id).subscribe((response: any) => {
            const dictionary = response.data.config;
            if (dictionary) {
                AsapClientPipe.setDictionary(dictionary);
                this.setHtmlData();
            }
        });

    }

    setHtmlData() {
        const html = document.querySelector('html');

        html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);

        html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

        html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
        html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);

        html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
        html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

        html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
        html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

        html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
        html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);

        html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
        html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.mainColor);
        html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnViewer);
        html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.textBtnViewer);
        html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.textBtnViewerActive);

        html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

        html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
        html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);

        html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);
    }

    reloadHeader(event: any) {
        console.log(this.headerComponent);
        this.headerComponent.reload(event);
    }

    reloadBanner(event: any) {
        this.homeComponentsListEl.map(homeEl => {
            homeEl.reloadBanner(event);
        })
    }


    get isHeaderFlix(): boolean {
        return this.topComponentArray.some((item: any) => (item.type === 'header' && item.layout === 'flix'));
    }

    get isCardFlix(): boolean {
        return this.componentArray.some((item: any) => (item.type == 'cursos-sessao' && item.design.style === 'card-flix'));
    }

    sessionChanged() {

    }

    get isHeaderFlixStyle(): { 'top.px': number, 'margin-bottom.px': number } {
        if (!this.isHeaderFlix) {
            return { 'top.px': 0, 'margin-bottom.px': 0 };
        }
        const flix = this.topComponentArray.find((item: any) => (item.type === 'header' && item.layout === 'flix'));

        // Comentado dia 11/02/2022 por Sérgio Borges com aval de Eduardo Tavares
        // Motivo: Essas variávieis estavam impedindo o clique em mais de 50% do header
        // Caso dê aglum erro chamar os responsáveis

        // return {
        //     'top.px': flix.position,
        //     'margin-bottom.px': flix.position
        // }

        return { 'top.px': 0, 'margin-bottom.px': 0 };

    }

    changeGrid(side) {
        if (this.selectedGrid != side) {
            this.selectedGrid = side;
            let list = this.componentArray;
            if (side == 'left') list = this.sidebarLeftComponentArray;
            if (side == 'right') list = this.sidebarComponentArray;

            if (list.length == 0) {
                this.selectItem(null);
            }
            else {
                this.selectItem(list[0]);
            }
        }
        if (this.selectedGrid == 'center') {
            if (this.colLeft) {
                this.colLeft.section = 'conteudo';
            }
        }
        else {
            if (this.colLeft) {
                this.colLeft.section = 'informacao';
            }
        }
    }

    changePage(page) {

        console.log("[changePage]", page);
        if (page == null) {
            this.selectedPage = null;
            this.componentArray = (this.homeSettings.main) ? this.homeSettings.main : [];
            this.sidebarComponentArray = (this.homeSettings.sidebar) ? this.homeSettings.sidebar : [];
            this.topComponentArray = (this.homeSettings.top) ? this.homeSettings.top : [];
            this.selectFirstItem();
        }
        else {
            this.selectedPage = page;
            this.componentArray = (page.main) ? page.main : [];
            this.sidebarLeftComponentArray = (page.sidebarLeft) ? page.sidebarLeft : page.sidebarLeft = [];
            this.sidebarComponentArray = (page.sidebar) ? page.sidebar : [];
            this.topComponentArray = (page.top) ? page.top : [];

            if (!this.selectedPage.gridLayout) {
                this.selectedPage.gridLayout = [0, 12, 0];
            }

            if (!this.selectedPage.iconPage) {
                this.selectedPage.iconPage = this.listIcons[0];
            }

            this.selectFirstItem();
        }
    }

    pageOnMenu(id) {
        const pageIdx = this.settings.navbarMenuItems.findIndex(p => p.type == 'dynamic_page' && p.page == id);
        return (pageIdx >= 0);
    }

    togglePageOnMenu(page) {
        page.changed = true;
        if (this.pageOnMenu(page.id)) {
            this.removePageFromMenu(page.id);
        }
        else {
            this.addPageToMenu(page);
        }
    }

    addPageToMenu(page) {
        const menu = new NavbarMenuItem();
        menu.label = page.title;
        menu.type = 'dynamic_page';
        menu.page = page.id;
        this.settings.navbarMenuItems.push(menu);
    }

    removePageFromMenu(id) {
        let pageIdx = this.settings.navbarMenuItems.findIndex(p => p.type == 'dynamic_page' && p.page == id);
        if (pageIdx >= 0) {
            this.settings.navbarMenuItems.splice(pageIdx, 1);
        }
    }

    newPage() {
        let page = {
            title: "Nova Página",
            id: "pagina-" + (this.homeSettings.pages.length + 1),
            main: [],
            top: [],
            sidebar: []
        };

        this.homeSettings.pages.push(page);

        this.addPageToMenu(page);

        this.changePage(page);

        this.saveCustomization();
        this.save();
    }

    openPageSettings(page) {
        this.changePage(page);
        setTimeout(() => {
            if (this.selectedPage) {
                this.selectedPage.NewID = this.selectedPage.id;
                this.selectedPage.isMainPage = this.isMainPage(this.selectedPage.id);
            }

            this.global.dialogOpen(this.pageSettings, { width: '400px' }, 'pageSettings');

            this.global.dialogRef('pageSettings').afterClosed().subscribe((response) => {
                const id = this.selectedPage.NewID;
                const changed = this.selectedPage.changed;
                const isMainPage = this.selectedPage.isMainPage;

                delete (this.selectedPage.NewID);
                delete (this.selectedPage.changed);
                delete (this.selectedPage.isMainPage);

                this.save('navbarMobile');

                if (isMainPage) {
                    this.settings.mainPage = id;
                    this.saveCustomization();
                }
                else if (this.isMainPage(id)) {
                    delete (this.settings.mainPage);
                    this.saveCustomization();
                }

                if ((changed) || (id != this.selectedPage.id)) {
                    let menu = this.settings.navbarMenuItems.find(m => (m.type == 'dynamic_page') && (m.page == this.selectedPage.id));
                    this.selectedPage.id = id;
                    if (menu) {
                        menu.label = this.selectedPage.title;
                        menu.page = this.selectedPage.id;
                        this.saveCustomization();
                        this.save();
                    }
                }
            });
        }, 200);
    }

    changeLayout(page, layout) {
        page.gridLayout = layout;
    }

    layoutClasses(side) {
        let result = "col-sm-12";
        if (this.selectedPage == null) {
            if (side == 'left') {
                result = "hidden d-none"
            }
            else if (side == 'center') {
                if (this.sidebarComponentArray.length > 0) {
                    result = "col-sm-9"
                }
                else {
                    result = "col-sm-12";
                }
            }
            else if (side == 'right') {
                if (this.sidebarComponentArray.length > 0) {
                    result = "col-sm-3"
                }
                else {
                    result = "hidden d-none";
                }
            }
        }
        else {
            if (side == 'left') {
                if (this.selectedPage.gridLayout && this.selectedPage.gridLayout[0]) {
                    result = "col-sm-" + this.selectedPage.gridLayout[0];
                }
                else {
                    result = "hidden d-none";
                }
            }
            else if (side == 'center') {
                if (this.selectedPage.gridLayout && this.selectedPage.gridLayout[1]) {
                    result = "col-sm-" + this.selectedPage.gridLayout[1];
                }
                else {
                    result = "col-sm-12";
                }
            }
            else if (side == 'right') {
                if (this.selectedPage.gridLayout && this.selectedPage.gridLayout[2]) {
                    result = "col-sm-" + this.selectedPage.gridLayout[2];
                }
                else {
                    result = "hidden d-none";
                }
            }

            if ((this.selectedPage != null) && (this.selectedPage.gridLayout) && (this.selectedPage.gridLayout[1] != 12) && (this.selectedGrid != side)) {
                result = result + ' unselected';
            }

            if ((this.selectedGrid == side) && (this.selectedGrid == 'left') && ((!this.selectedPage.sidebarLeft) || (this.selectedPage.sidebarLeft.length == 0))) {
                result = result + ' boxit';
            }
            else if ((this.selectedGrid == side) && (this.selectedGrid == 'center') && ((!this.selectedPage.main) || (this.selectedPage.main.length == 0))) {
                result = result + ' boxit';
            }
            else if ((this.selectedGrid == side) && (this.selectedGrid == 'right') && ((!this.selectedPage.sidebar) || (this.selectedPage.sidebar.length == 0))) {
                result = result + ' boxit';
            }
        }

        return result;
    }

    deletePage(page) {
        this.global.swal({
            title: 'Remover?',
            text: 'Você tem certeza que deseja remover esta Página?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'

        }).then(
            confirm => {
                if (confirm.value) {
                    page.deleted = true;
                    this.global.dialogClose('pageSettings');
                    setTimeout(() => {
                        const menuIdx = this.settings.navbarMenuItems.findIndex(m => (m.type == 'dynamic_page') && (m.page == page.id));
                        if (menuIdx >= 0) {
                            this.settings.navbarMenuItems.splice(menuIdx, 1);
                            this.saveCustomization();
                        }
                        this.save();
                        this.changePage(null);
                    }, 500);

                }
            },
            cancel => { }
        );
    }

    navbarEvent(event) {
        console.log('[navbarEvent]', event);
        if (event) {
            if (event.action == 'save') {
                this.saveCustomization();
            }
            else if (event.action == 'zoom') {
                this.setStyleZoom(event.value, false);
            }
        }
    }

    isMainPage(pageID) {
        return this.settings.mainPage == pageID;
    }

    pageTitleChanged(page) {
        page.changed = true;
        page.NewID = slugify(page.title, {
            lower: true
        });

        this.settings.navbarMenuMobile = this.settings.navbarMenuMobile.map((item) => {
            if (item.id === page.id) {
                item.label = this.selectedPage.title;
            }
            return item;
        });
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsapBaseService } from 'asap-crud';

@Injectable({providedIn: 'root'})
export class AuthService extends AsapBaseService {

    constructor(public http: HttpClient) {
        super(http);
        this.basePath = 'api';
        this.entityName = 'v2';
    }

    public getUserLogged() {
        return this.getResources({router: 'client/user/authenticated'});
    }

    public verify() {
        return this.getResources({router: 'client/verifica'});
    }

  public setSessionToken(data: { token: string }): Promise<boolean> {
    console.log('setSessionToken', data);
    return new Promise<boolean>((resolve, reject) => {
      const token = 'Bearer' + ' ' + data.token;
      localStorage.setItem('token', token);
      setTimeout(() => {
        this.getUserLogged().subscribe((response: any) => {
          console.log('setSessionToken', response);

          localStorage.setItem('loggedUser', JSON.stringify(response));
          localStorage.setItem('client', response.data.client_id);

          resolve(true);

        }, err => reject(err));
      }, 100);
    });
  }

  public getData() {

  }

}

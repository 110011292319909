import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AsapService } from 'asap-crud';

@Component({
    selector: 'app-editor-custom-component-forum-theme-sidebar-config',
    templateUrl: 'forum-theme-sidebar-config.component.html',
    providers: [ AsapService ]
})
export class ForumThemeSidebarConfigComponent {

    @Input() item: any;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    public fields = [];

    constructor(
        public service: AsapService
    ) {

    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

}

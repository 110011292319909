import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-studio-list',
  templateUrl: 'list.component.html'
})
export class StudioListComponent {

  @Input() item: any = {};
  @Input() setting: any;
  @Input() data: any;
  @Input() full: boolean;

}

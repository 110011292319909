import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Global } from 'asap-crud';
import { AdminToolsService } from './admin-tools.service';


@Component({
  selector: 'admin-permission',
  templateUrl: './admin-permission.component.html',
  providers: [AdminToolsService]
})
export class AdminPermissionComponent implements OnInit {

  @Input() id!: number;
  @Input() dynamic: any;
  @Input() entity: any;
  @Input() type!: string;
  @Input() noCategory = false;
  @Input() noUsers = false;
  @Input() noGroups = false;
  @Input() useVoucher = false;
  @Input() context: any = 'voucher';

  public fields: any;
  public fieldsVoucher: any;
  public field: any;
  public image: any;
  public fieldVoucher: any;
  public values: any = [];
  public valuesVoucher: any;
  public value: any;
  public valueVoucher: any;
  public user: any;
  public items: any;
  public loggedUser: any;
  public url: string;
  public addingPermission = false;
  public permissionsLoad: any;
  public permissionsLoadVoucher: any;
  public userLoad: any;
  public userLoadVouchers: any;
  public groups: any;
  public memberOf: any = null;
  public memberOfVoucher: any = null;
  public getFieldsVoucher: any;


  public typeList = [
    "areas",
    "company",
    "fields",
    "folders",
    "forum",
    "rules",
    "users",
    "videos"
  ];

  constructor(
    public global: Global,
    public service: AdminToolsService,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    

    this.route.params.subscribe(params => {
      if (params.id && params.type) {
        this.id = params.id;
        this.type = params.type;
      }
    });
  }

  ngOnInit() {
    this.loadData();
    this.url = this.data.url;
  }

  public loadData() {
    this.getPermissionsExtra();
    this.getPermissionsForVoucher();
    this.getGroups();

    if (this.useVoucher) this.getGroupsByVoucher();

    this.service.loadFields().subscribe((response) => {
      this.fields = response;
      if (this.global.hasCompanies()) {
        this.fields.unshift({
          name: "multi-conta"
        })
      }
    });

    if (this.useVoucher) {
      this.service.loadFieldsVoucher().subscribe((response) => {
        this.fieldsVoucher = response;
        if (this.global.hasCompanies()) {
          this.fieldsVoucher.unshift({
            name: "multi-conta"
          })
        }
      });
    }

    this.service.loadGroups().subscribe((response) => {
      this.groups = response.data;
    });
  }

  public getValues(event: any) {
    this.values = null;
    this.value;
    setTimeout(() => {
      if (this.field == 'multi-conta') {
        this.values = this.global.companies;
      }
      else {
        this.service.loadValues(this.field).subscribe((response) => {
          this.values = response;
        });
      }
    }, 100);
  }

  public getValuesVoucher(event: any) {
    this.valueVoucher = null;
    setTimeout(() => {
      if (this.fieldVoucher == 'multi-conta') {
        this.valuesVoucher = this.global.companies;
      }
      else {
        this.service.loadValuesVoucher(this.fieldVoucher,false, this.global.clientID()).subscribe((response) => {
          this.valuesVoucher = response;
        });
      }
    }, 100);
  }

 
  public getGroups() {
    if (this.type == 'trail') {
      this.service.getFolderGroups(this.id).subscribe((response) => {
        this.memberOf = response;
      });
    }
    else if (this.type == 'documento') {
      this.service.getAreaGroups(this.id).subscribe((response) => {
        this.memberOf = response;
      });
    }
    else if (this.type == 'forum') {
      this.service.getForumGroups(this.id).subscribe((response) => {
        this.memberOf = response;
      });
    }
    else if (this.type == 'video') {
      this.service.getVideoGroups(this.id).subscribe((response) => {
        this.memberOf = response;
      });
    }
    else {
      this.service.getPermissionGroups(this.id, this.type).subscribe((response) => {
        this.memberOf = response;
      });
    }
  }

  public getGroupsByVoucher() {
    this.service.getPermissionGroups(this.id, this.type, this.context).subscribe((response) => {
      this.memberOfVoucher = response;
    });
  }

  public getPermissionsExtra() {
    this.service.getPermissionsExtra(this.id, this.type).subscribe(res => {
      let group_to_values = res.reduce((obj: any, item: any) => {
        obj[item.field] = obj[item.field] || [];
        obj[item.field].push({ name: item.value, user: item.name, id: item.id });
        return obj;
      }, {});

      let permission = Object.keys(group_to_values).map(function (key) {
        return { category: key, permission: group_to_values[key] };
      });

      this.permissionsLoad = permission.filter(e => e.category != "user_id").map((item: any) => {
        if (item.category == 'multi-conta') {
          item.permission = item.permission.map((p: any) => {
            if (this.global.hasCompanies()) {
              let c = this.global.companies.find((i: any) => i.id == p.name);
              if (c) {
                p.name = c.name;
              }
            }
            return p;
          });
        }
        return item;
      });

      this.userLoad = permission.filter(e => e.category == "user_id");
    })
  }

  public getPermissionsForVoucher() {
    const queryParams = (this.useVoucher) ? `&context=${this.context}` : '';
    this.service.getPermissionsExtra(this.id, this.type + queryParams).subscribe(res => {
      let group_to_values = res.reduce((obj: any, item: any) => {
        obj[item.field] = obj[item.field] || [];
        obj[item.field].push({ name: item.value, user: item.name, id: item.id });
        return obj;
      }, {});

      let permission = Object.keys(group_to_values).map(function (key) {
        return { category: key, permission: group_to_values[key] };
      });

      this.permissionsLoadVoucher = permission.filter(e => e.category != "user_id").map((item: any) => {
        if (item.category == 'multi-conta') {
          item.permission = item.permission.map((p: any) => {
            if (this.global.hasCompanies()) {
              let c = this.global.companies.find((i: any) => i.id == p.name);
              if (c) {
                p.name = c.name;
              }
            }
            return p;
          });
        }
        return item;
      });

      this.userLoadVouchers = permission.filter(e => e.category == "user_id");
    })
  }

  public addUsers(user: any) {
    this.field = 'user_id';
    this.value = user.user_id;
    this.values = [];
    this.addPermission((response: any) => {
      if (this.userLoad.length > 0) {
        this.userLoad[0].permission.push({
          name: user.user_id,
          user: user.name,
          id: (response) ? response.data.id : null
        });
      }
      else {
        this.userLoad = [
          {
            category: "user_id",
            permission: [{
              name: user.user_id,
              user: user.name,
              id: (response) ? response.data.id : null
            }]
          }
        ]
      }
      this.entity.userLoad = this.userLoad;
    });
  }

  public addPermission(afterAdd: any = null) {
    if (this.field && this.value) {
      let item = {
        field: this.field,
        target_type: this.type,
        url: this.url,
        value: this.value,
        dynamic: this.dynamic
      }
      this.addingPermission = true;
      this.value = null;
      setTimeout(() => {
        if (this.entity) {
          this.addingPermission = false;
          if (afterAdd) afterAdd(null);
          else if (this.field != 'user_id') {
            let permission = this.permissionsLoad.find((p: any) => p.category == item.field);
            if (permission) {
              if (this.field == 'multi-conta') {
                let c = this.global.companies.find((c: any) => c.id == item.value);
                if (c) {
                  item.value = c.name;
                }
                permission.permission.push({
                  name: item.value
                });
              }
              else {
                permission.permission.push({
                  name: item.value
                });
              }
            }
            else {
              this.permissionsLoad.push({
                category: item.field,
                permission: [
                  {
                    name: item.value,
                  }
                ]
              })
            }
            this.entity.permissionsLoad = this.permissionsLoad;
          }
        }
        else {
          this.service.createPermission(this.id, item).subscribe(res => {
            this.addingPermission = false;
            if (afterAdd) afterAdd(res);
            else if (this.field != 'user_id') {
              if (!this.permissionsLoad) this.permissionsLoad = [];
              let permission = this.permissionsLoad.find((p: any) => p.category == res.data.field);
              if (permission) {
                if (this.field == 'multi-conta') {
                  let c = this.global.companies.find((c: any) => c.id == res.data.value);
                  if (c) {
                    res.data.value = c.name;
                  }
                  permission.permission.push({
                    name: res.data.value,
                    id: res.data.id
                  });
                }
                else {
                  permission.permission.push({
                    name: res.data.value,
                    id: res.data.id
                  });
                }
              }
              else {
                this.permissionsLoad.push({
                  category: res.data.field,
                  permission: [
                    {
                      name: res.data.value,
                      id: res.data.id
                    }
                  ]
                })
              }
            }
          })
        }
      }, 100);
    }
  }

  public addPermissionVoucher(afterAdd: any = null) {
    const queryParams = (this.useVoucher) ? `?context=${this.context}` : '';
    if (this.fieldsVoucher && this.valuesVoucher) {
      let item = {
        field: this.fieldVoucher,
        target_type: this.type,
        url: this.url,
        value: this.valueVoucher
      }
      this.addingPermission = true;
      this.valueVoucher = null;
      setTimeout(() => {
        if (this.entity) {
          this.addingPermission = false;
          if (afterAdd) afterAdd(null);
          else if (this.fieldVoucher != 'user_id') {
            let permission = this.permissionsLoadVoucher.find((p: any) => p.category == item.field);
            if (permission) {
              if (this.fieldVoucher == 'multi-conta') {
                let c = this.global.companies.find((c: any) => c.id == item.value);
                if (c) {
                  item.value = c.name;
                }
                permission.permission.push({
                  name: item.value
                });
              }
              else {
                permission.permission.push({
                  name: item.value
                });
              }
            }
            else {
              this.permissionsLoadVoucher.push({
                category: item.field,
                permission: [
                  {
                    name: item.value,
                  }
                ]
              })
            }
            this.entity.permissionsLoadVoucher = this.permissionsLoadVoucher;
          }
        }
        else {
          this.service.createPermission(this.id + queryParams, item).subscribe(res => {
            this.addingPermission = false;
            if (afterAdd) afterAdd(res);
            else if (this.field != 'user_id') {
              if (!this.permissionsLoadVoucher) this.permissionsLoadVoucher = [];
              let permission = this.permissionsLoadVoucher.find((p: any) => p.category == res.data.field);
              if (permission) {
                if (this.field == 'multi-conta') {
                  let c = this.global.companies.find((c: any) => c.id == res.data.value);
                  if (c) {
                    res.data.value = c.name;
                  }
                  permission.permission.push({
                    name: res.data.value,
                    id: res.data.id
                  });
                }
                else {
                  permission.permission.push({
                    name: res.data.value,
                    id: res.data.id
                  });
                }
              }
              else {
                this.permissionsLoadVoucher.push({
                  category: res.data.field,
                  permission: [
                    {
                      name: res.data.value,
                      id: res.data.id
                    }
                  ]
                })
              }
            }
          })
        }
      }, 100);
    }
  }

  public removePermission(item: any, idx1: number, idx2: number, type: string) {
    this.global.swal({
      title: 'Você quer apagar este item?',
      message: 'Você não poderá reverter essa ação depois de confirmado',
      type: 'warning',
      showCancelButton: true,
      okButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(
      (result: any) => {
        if (result.value) {
          item.removing = true;
          if (this.entity) {
            if (type == 'user_id') {
              this.userLoad[idx1].permission.splice(idx2, 1)
            }
            else {
              this.permissionsLoad[idx1].permission.splice(idx2, 1);
            }
            this.entity.userLoad = this.userLoad;
            this.entity.permissionsLoad = this.permissionsLoad;
          }
          else {
            setTimeout(() => {
              this.service.deletePermission(item.id, this.userLoad).subscribe((response) => {
                if (type == 'user_id') {
                  this.userLoad[idx1].permission.splice(idx2, 1)
                }
                else {
                  this.permissionsLoad[idx1].permission.splice(idx2, 1);
                }

              });
            }, 100);
          }
        }
      }
    );
  }

  public removePermissionVoucher(item: any, idx1: number, idx2: number, type: string) {
    this.global.swal({
      title: 'Você quer apagar este item??',
      message: 'Você não poderá reverter essa ação depois de confirmado',
      type: 'warning',
      showCancelButton: true,
      okButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).subscribe(
      (result) => {
        if (result == 'ok') {
          item.removing = true;
          if (this.entity) {
            if (type == 'user_id') {
              this.userLoadVouchers[idx1].permission.splice(idx2, 1)
            }
            else {
              this.permissionsLoadVoucher[idx1].permission.splice(idx2, 1);
            }
            this.entity.userLoadVouchers = this.userLoadVouchers;
            this.entity.permissionsLoadVoucher = this.permissionsLoadVoucher;
          }
          else {
            setTimeout(() => {
              this.service.deletePermission(item.id, 'context=voucher').subscribe((response) => {
                if (type == 'user_id') {
                  this.userLoadVouchers[idx1].permission.splice(idx2, 1)
                }
                else {
                  this.permissionsLoadVoucher[idx1].permission.splice(idx2, 1);
                }

              });
            }, 100);
          }
        }
      }
    );
  }

  public addGroups(): void {
    if (this.memberOf) {
      let type = this.type + "s";
      if (this.type == "trail") {
        type = "folders";
      }
      else if (this.type == "documento") {
        type = "areas";
      }
      else if (this.type == "forum") {
        type = "forum";
      }
      else if (this.typeList.indexOf(type) < 0) {
        type = this.type;
      }
      this.service.addGroups(this.id, type, this.memberOf.join(",")).subscribe((response) => {
        this.memberOf = response.data;
      });
    }
  }

  public isMember(group: any) {
    if (!this.memberOf) {
      return false;
    }
    return (this.memberOf.indexOf(group.id) >= 0);
  }

  public member(group: any) {
    if (!this.isMember(group)) {
      this.memberOf.push(group.id);
    }
    else {
      let idx = this.memberOf.indexOf(group.id);
      this.memberOf.splice(idx, 1);
    }
    this.addGroups();
  }


}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-monitoring-comments',
  templateUrl: 'monitoring-comments.component.html',
  styleUrls: [ './monitoring-comments.component.scss' ]
})
export class MonitoringCommentsComponent {

  @Input() item: any;

  public mockData = [
    {
      reportType: 'Relatório A',
      reportName: 'Relatório A',
      comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      date: '2022-10-25'
    },
    {
      reportType: 'Relatório A',
      reportName: 'Relatório B',
      comment: 'Sed commodo pretium purus, et dapibus tellus dapibus a. In rutrum volutpat ante, at ullamcorper libero.',
      date: '2022-08-27'
    },
    {
      reportType: 'Relatório B',
      reportName: 'Relatório C',
      comment: 'Fusce ultricies eget mi a consequat. Etiam auctor neque ac lacus euismod accumsan.',
      date: '2022-03-07'
    }
  ]
  
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-gallery-card',
  templateUrl: 'gallery-card.component.html'
})
export class GalleryCardComponent {
  @Input() setting: any;
  @Input() item: any;
}

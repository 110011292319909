import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-assessment-config',
  templateUrl: 'assessment-config.component.html',
  providers: [DataService]
})
export class AssessmentConfigComponent implements OnInit {
  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadHeaderEvent = new EventEmitter<any>();

  trailData: any;
  eventData: any;
  videoData: any;

  featuredItem: any;
  featuredItemSearch: any;

  buffer: any;

  constructor(
    private elRef: ElementRef
  ) {
  }

  ngOnInit() {
    if (!this.item.title) {
      this.item.title = {
        text: 'ASSESSMENT',
        color: '',
        align: 'center',
        size: 26
      };

      this.item.subtitle = {
        text: '',
        color: '',
        align: 'center',
        size: 16
      };
    }
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

  setTitleAlign(align: string): void {
    this.item.titleAlign = align;
  }

  setSubtitleAlign(align: string): void {
    this.item.subtitleAlign = align;
  }

  setAlignElement(element: any, align: string): void {
    this.item[element].align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadHeader() {
    this.buffer = this.item.featureType + '-' + this.item.featureId + '-' + this.item.featureDeadline;
    this.reloadHeaderEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getEmblemPhoto($event: any): void {
    this.item.emblem = $event.path;
  }

  getIconPhoto($event: any): void {
    this.item.icon = $event.path;
  }

  getVideo($event: any): void {
    this.item.video = $event.path;

    const player = this.elRef.nativeElement.querySelector('video');
    player.load();
  }

}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TopMenuComponent } from './top-menu/top-menu.component';
import { ColLeftComponent } from './col-left/col-left.component';
import { ColRightComponent } from './col-right/col-right.component';
import { FooterComponent } from './footer/footer.component';
import { ComponentsModule } from '../components/components.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    MatTooltipModule
  ],
  declarations: [
    TopMenuComponent,
    ColLeftComponent,
    ColRightComponent,
    FooterComponent
  ],
  exports: [
    TopMenuComponent,
    ColLeftComponent,
    ColRightComponent,
    FooterComponent,
    MatTooltipModule
  ],
})
export class StructureModule { }

import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-search-config',
  templateUrl: 'search-config.component.html',
  providers: [DataService]
})
export class SearchConfigComponent {
  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  categoryArray: any;

  constructor(
    public dataService: DataService
  ) {
    this.getCategories();
  }

  getCategories() {
    this.dataService.filters().subscribe((res:any) => {
      let i = 0;
      this.categoryArray = [];
      res.data.map((item: any) => {
        i++;
        this.categoryArray.push({value: i, display: item.filter_value});
      });
    });
  }

  

  emitSendEvent() {
    this.sendEvent.emit();
  }

}

import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-data-config',
  templateUrl: 'data-config.component.html',
  providers: [DataService]
})
export class DataConfigComponent implements OnInit {
  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;

  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
    private dataService: DataService
  ) {
  }

  ngOnInit() {
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

  setTitleAlign(align: string): void {
    this.item.titleAlign = align;
  }

  setSubtitleAlign(align: string): void {
    this.item.subtitleAlign = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

}

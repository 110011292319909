import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AsapService } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-multicontas-config',
    templateUrl: 'multicontas-config.component.html',
    providers: [AsapService]
})
export class MulticontasConfigComponent implements OnChanges {

    @Input() item: any;
    @Input() root: any;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    public fields = [];
    public companies;

    constructor(
        public service: AsapService,
        public dataService: DataService
    ) {
        this.dataService.companies().subscribe((res: any) => {
            this.companies = res.data;
            this.loadImages();
        });

        this.service.basePath = "api/v2/admin";
        this.service.entityName = "folder/permission";
        this.service.getResources().subscribe((response) => {
            this.fields = response;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item && this.item) {
            if (!this.item.images) {
                this.item.images = {};
                this.loadImages();
            }
        }
    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

    loadImages() {
        console.log('[loadImages]', this.item, this.companies);
        if (this.item && this.companies) {
            for (const c of this.companies) {
                if (!this.item.images[c.id]) {
                    let image = this.getImage(c.id);
                    console.log('[loadImages] Image', c.id, image);
                    this.item.images[c.id] = image;
                }
            }
        }
    }

    getImage(id, component = this.root) {
        console.log('[loadImages]', id, component);
        let image;
        if (Array.isArray(component)) {
            for (const c of component) {
                image = this.getImage(id, c);
                if (image) {
                    return image;
                }
            }
        }
        else if (component) {
            if (component.main) {
                image = this.getImage(id, component.main);
                if (image) {
                    return image;
                }
            }
            if (component.pages) {
                image = this.getImage(id, component.pages);
                if (image) {
                    return image;
                }
            }
            if (component.sidebar) {
                image = this.getImage(id, component.sidebar);
                if (image) {
                    return image;
                }
            }
            if (component.sidebarLeft) {
                image = this.getImage(id, component.sidebarLeft);
                if (image) {
                    return image;
                }
            }
            if (component.top) {
                image = this.getImage(id, component.top);
                if (image) {
                    return image;
                }
            }
            if (component.type == 'multicontas') {
                if (component.images && component.images[id]) {
                    return component.images[id];
                }
            }
            return null;
        }
    }

    changeImage(id, value, component = this.root) {
        if (Array.isArray(component)) {
            for (const c of component) {
                this.changeImage(id, value, c);
            }
        }
        else if (component) {
            if (component.main) this.changeImage(id, value, component.main);
            if (component.pages) this.changeImage(id, value, component.pages);
            if (component.sidebar) this.changeImage(id, value, component.sidebar);
            if (component.sidebarLeft) this.changeImage(id, value, component.sidebarLeft);
            if (component.top) this.changeImage(id, value, component.top);
            if (component.type == 'multicontas') {
                if (!component.images) component.images = {};
                component.images[id] = value;
            }
        }
    }

}

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DataService } from "src/app/@core/services/data.service";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
@Component({
  selector: "app-editor-custom-component-header",
  templateUrl: "header.component.html",
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() item: any;
  @Input() settings: any;
  @Input() layout: number;

  now = new Date();
  public lastitemData: any;
  public featureID: any;
  public featuredData: any;
  public showFeatured: boolean;
  public featureLoading: boolean;
  public featureTimer;
  

  constructor(
    private dataService: DataService,
    public sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    clearInterval(this.featureTimer);
  }

  ngOnInit(): void {
    this.featureTimer = setInterval(() => {
      if (!this.featureLoading && this.item) {
        if (this.featureID != this.item.featureId) {
          this.getFeaturedData();
        }
      }
    }, 500);
  }

  getFeaturedData(feature = null) {
    if (feature) {
      this.checkShowFeatured(this.item.featureDeadline);
      this.featureLoading = true;
      this.featureID = feature.featureId;
      this.dataService
        .featured(feature.featureType, feature.featureId)
        .subscribe((res) => {
          this.lastitemData = res.data.item[0];
          this.featuredData = res.data.itemSeach[0] || {
            title: `[${feature.featureType}] não encontrado na Plataforma...`,
          };
          this.featureLoading = false;
        });
    } else {
      this.checkShowFeatured(this.item.featureDeadline);
      this.featureLoading = true;
      this.featureID = this.item.featureId;
      this.dataService
        .featured(this.item.featureType, this.item.featureId)
        .subscribe((res) => {
          this.lastitemData = res.data.item[0];
          this.featuredData = res.data.itemSeach[0] || {
            title: `[${this.item.featureType}] não encontrado na Plataforma...`,
          };
          this.featureLoading = false;
        });
    }

    return "Loading...";
  }

  maskBackground(color: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`
      linear-gradient(0deg, ${color} 15%, transparent 95%)
    `);
  }

  checkShowFeatured(deadline: any): void {
    const now = this.now.getTime();
    const dead = new Date(deadline).getTime();
    this.showFeatured = dead > now ? true : false;
  }

}

import { Component, Input, Output, EventEmitter, ElementRef, OnInit, OnChanges, SimpleChanges, Inject, Injector, ViewChild, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Global } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';
import { EventsService } from 'src/app/@core/services/events.service';

@Component({
  selector: 'app-editor-custom-component-onboard-config',
  templateUrl: 'onboard-config.component.html',
  styleUrls: ['../onboard.component.scss'],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: [] }
  ]
})
export class OnboardConfigComponent implements OnInit, OnChanges {

  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;
  @Input() settings: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadHeaderEvent = new EventEmitter<any>();

  public trailData: any;
  public eventData: any;
  public videoData: any;
  public courseSessionData: any;
  public eventSessionData: any;
  public eventSessions: any;
  public buffer: any;
  public page: any;
  public featuredItem: any;
  public featuredItemSearch: any;

  isCheckboxLinkChecked = false;
  
  tabs = {
    web: true,
    celular: false
  };


  constructor(
    private elRef: ElementRef,
    private dataService: DataService,
    private eventsService: EventsService,
    public global: Global,
  ) {
  }

  ngOnInit() {
    console.log("[item]", this.item);
    this.getData();
    if (this.item.featureType === 'evento') {
      this.eventFeaturedChanged();
    }
    if (this.item.featureType === 'curso') {
      this.trailFeaturedChanged();
    }
    if (this.item.featureType === 'video') {
      this.videoFeaturedChanged();
    }
    if (this.item.featureType === 'course-session') {
      this.trailFeaturedChanged();
    }
    this.buffer = this.item.featureType + '-' + this.item.featureId + '-' + this.item.featureDeadline;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings) {
    }
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

  setTitleAlign(align: string): void {
    this.item.titleAlign = align;
  }

  setSubtitleAlign(align: string): void {
    this.item.subtitleAlign = align;
  }

  setBtnAlign(align: string): void {
    this.item.btnAlign = align;
  }

  emitSendEvent() {
    this.sendEvent.emit({field:'banner'});
  }

  reloadHeader() {
    this.buffer = {
      featureType: this.item.featureType,
      featureId: this.item.featureId,
      featureDeadline: this.item.featureDeadline
    };
    this.reloadHeaderEvent.emit(this.buffer);
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getAlternativeImage(event: any): void {
    this.item.alternativeImage = event.path;
  }

  getImage($event: any, field: string): void {
    this.item[field] = $event.path;
  }

  getEmblemPhoto($event: any): void {
    this.item.emblem = $event.path;
  }

  getIconPhoto($event: any): void {
    this.item.icon = $event.path;
  }

  getVideo($event: any): void {
    this.item.video = $event.path;

    const player = this.elRef.nativeElement.querySelector('video');
    player.load();
  }

  getData() {
    const lu = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.dataService.web().subscribe(res => {
      this.trailData = [];
      this.trailData.push(...res.data.trilhas);
      this.trailData.push(...res.data.influenciadores);
      this.trailData.push(...res.data.desenvolvimento);

      this.videoData = res.data.videos;
    });

    this.dataService.event(user.id).subscribe(res => {
      this.eventData = res;
    });

    this.dataService.cursosSessao().subscribe(res => {
      this.courseSessionData = res;
    });

    this.dataService.eventoSessao()
      .subscribe(res => {
        this.eventSessions = res;
      });

    this.dataService.custom('event-sessao', 8).subscribe((res: any) => {
      this.eventSessionData = res.data['evento-sessao'];
    });
  }

  eventFeaturedChanged() {
    this.dataService.featured('evento', this.item.featureId).subscribe(res => {
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  trailFeaturedChanged() {
    this.dataService.featured('curso', this.item.featureId).subscribe(res => {
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  videoFeaturedChanged() {
    this.dataService.featured('video', this.item.featureId).subscribe(res => {
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  courseBySession(session: any) {
    if (!this.courseSessionData) {
      return;
    }
    const find = this.courseSessionData.find((item: any) => +item.session_id === +session);
    if (find) {
      return find.trail;
    }
  }

  eventBySession(session: any) {
    if (!this.eventSessionData) {
      return;
    }
    const filter = this.eventSessionData.filter((item: any) => +item.session_id === +session);
    if (filter.length > 0) {
      return filter;
    }
  }

  sendTheEvent(event: any) {
    this.sendEvent.emit(event);
  }

  onboardItemAdd() {
    if (!this.item.onboard) {
      this.item.onboard = [];
    }

    this.item.onboard.push({
      type: null,
      section: null,
      id: null,
    });

    this.item.onboardItemActive = this.item.onboard.length - 1;

  }

  getOnboard($event: any, index: number): void {
    this.item.onboard[index].path = $event.path;
  }

  onboardItemRemove(index: number): void {
    this.item.onboard.splice(index, 1);
    if (!this.item.onboard[this.item.onboardItemActive]) {
      this.item.onboardItemActive = this.item.onboard.length - 1;
    }

    this.sendTheEvent({ event: 'onboard-item-remove' })
    this.eventsService.broadcast('featured-changed')
  }

  selectLayout(layout) {
    if (layout == 'full') {
      if (this.item.onboardHeight == '200') {
        this.item.onboardHeight = '316';
      }
    }
    else if (layout == 'container') {
      if (this.item.onboardHeight == '552') {
        this.item.onboardHeight = '473';
      }
    }
  }

  layoutChange() {
    if (this.item.layout == 'flix') {
      this.item.background = '#000';
      this.item.color = '#FFF';
    }
    else {
      if (this.settings.themeStyle == 'light') {
        this.item.background = '#FFF';
        this.item.color = '#000';
      }
      if (this.settings.themeStyle == 'dark') {
        this.item.background = '#000';
        this.item.color = '#FFF';
      }
    }
  }
  public selectTab(select, markOff) {
    this.tabs[select] = true;
    this.tabs[markOff] = false;
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-journey-assessment',
  templateUrl: 'journey-assessment.component.html',
  styleUrls: ['../journey-list.component.scss']

})
export class JourneyAssessmentComponent {
  @Input() setting: any;
  @Input() item: any;
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-slides-card',
  templateUrl: 'slides-card.component.html'
})
export class SlidesCardComponent {
  @Input() setting: any;
  @Input() item: any;

  ngOnInit(): void {
    console.log('[SlidesCardComponent] setting', this.setting);
    console.log('[SlidesCardComponent] item', this.item);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-curso-sessao-dialog',
  templateUrl: 'dialog.component.html',
})
export class CursoSessaoDialogComponent implements OnInit {

  loading: boolean;
  sessions: string;
  session: string;

  constructor(
    public dialogRef: MatDialogRef<CursoSessaoDialogComponent>,
    public dataService: DataService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getSessions();
  }

  getSessions() {
    this.dataService.trailSessao('8,10')
      .subscribe((response: any) => {
        this.loading = false;
        this.sessions = response;
      });
  }

  selectSession(session: any) {
    this.dialogRef.close(session);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

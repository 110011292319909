import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-studio-card',
  templateUrl: 'card.component.html'
})
export class StudioCardComponent {
  @Input() setting: any;
  @Input() item: any;
}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-vacancies',
  templateUrl: 'vacancies.component.html'
})
export class VacanciesComponent implements OnInit {
    @Input() item: any;

    data: any;

    constructor(public dataService: DataService) {

    }

    ngOnInit() {
      // this.getData();
    }

    // getData() {
    //   this.dataService.benefits().subscribe((res: any) => {
    //     console.log('BeneficioComponent: getData: res', res);
    //     this.data = res.data;
    //   });
    // }
 
}

import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-dialog-html',
  templateUrl: './dialog-html.component.html',
  styleUrls: ['./dialog-html.component.scss']
})
export class DialogHtmlComponent implements OnInit {

  constructor(
    public service: DataService
  ) { }

  ngOnInit() {
  }

}

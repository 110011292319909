import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-editor-custom-component-mini',
  templateUrl: 'mini.component.html'
})

export class MiniComponent implements OnInit, OnChanges {
  @Input() setting: any;
  @Input() data: any;

  dataByFolder: any;

  customOptions: OwlOptions;

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
     console.log('Changes ->', changes);
    }
  }

  ngOnInit() {
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: this.setting.design.items
        },
        400: {
          items: this.setting.design.items
        },
        740: {
          items: this.setting.design.items
        },
        940: {
          items: this.setting.design.items
        }
      },
      nav: true
    };
    this.getCustom();
  }

  getCustom() {
      this.dataService.custom('trail', 3)
        .subscribe((res: any) => {
          this.dataByFolder = res.data.itens;
          console.log('this.dataByFolder', this.dataByFolder);
        });
    }
}

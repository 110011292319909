import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-mentoria-section-card',
  templateUrl: './card.component.html',
})
export class MentoriaSectionCardComponent {
  @Input() item: any;
  @Input() setting: any;
}

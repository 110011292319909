import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataService } from 'src/app/@core/services/data.service';
import { AdminToolsService } from '../../permission/admin-tools.service';
import { DialogHtmlComponent } from '../dialog-html/dialog-html.component';

@Component({
  selector: 'app-editor-custom-component-html-config',
  templateUrl: 'html-config.component.html',
  providers: [AdminToolsService]
})
export class HtmlConfigComponent implements OnInit {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() customData: any;
  @Input() full: boolean;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public sessionChangedEvent = new EventEmitter<any>();

  public sessions: any;
  public companies: any;
  public loading: boolean;
  public removeItemEvent: any;

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.getSessions();
    this.getHtml();
    this.getVouch();
    this.htmlSection();
    if (this.isMulti()) {
      this.getCompanies();
    }
    else {
      if (this.item.companies) {
        delete (this.item.companies);
      }
    }
  }

  public getVouch() {
    this.dataService.getVouch()
      .subscribe((response: any) => {
        this.loading = false;
        this.sessions = response;
        console.log('this.sessions', this.sessions);
      });
  }

  public htmlSection() {
    this.dataService.htmlSection()
      .subscribe((response: any) => {
        this.loading = false;
        this.sessions = response;
        console.log('this.sessions', this.sessions);
      });
  }

  public getHtml() {
    this.dataService.getHtml()
      .subscribe((response: any) => {
        this.loading = false;
        this.sessions = response;
        console.log('this.sessions', this.sessions);
      });
  }

  getSessions(): void {
    this.dataService.getHtml()
      .subscribe((response: any) => {
        this.sessions = response;
      });
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  setAlignElement(element: any, align: string) {
    element = align;
  }

  setItemAlign(element: string, align: string) {
    this.item[element] = align;
  }

  sessionChanged() {
    this.sessionChangedEvent.emit();
  }

  isMulti() {
    let user: any = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    if (user) {
      user = JSON.parse(user).data;
      let permission = user.resource_sub.find(s => s.tag == 'multi-account' && s.parent == 'editor-ead');
      if (permission) {
        return permission.enabled;
      }
    }

    return false;
  }

  openDialog(): void {
    // TODO
    // conferir quais valores passar dentro do data
    const dialogRef = this.dialog.open(DialogHtmlComponent, {
      width: '600px',
      data: {
        id: 'id',
        type: 'journey'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('openDialog: this.setting', this.setting);
      if (result) {
        this.setting.title.text = result.name;
        this.setting.session_id = result.id;
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

  getCompanies() {
    this.dataService.companies().subscribe((response: any) => {
      this.companies = response.data;
    });
  }

  selectCompany(company) {
    if (!this.item.companies) {
      this.item.companies = [];
      this.item.companies.push(company.id);
    }
    else if (!this.item.companies.includes(company.id)) {
      this.item.companies.push(company.id);
    }
    else {
      let idx = this.item.companies.indexOf(company.id);
      this.item.companies.splice(idx, 1);
    }
  }

}

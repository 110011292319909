import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AsapService } from 'asap-crud';

@Component({
    selector: 'app-editor-custom-component-users-config',
    templateUrl: 'users-config.component.html',
    providers: [ AsapService ]
})
export class UsersConfigComponent {

    @Input() item: any;
    @Input() setting: any;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    public fields = [];
    public companies;
    public type: boolean;

    constructor(
        public service: AsapService
    ) {
        this.service.basePath = "api/v2/admin";
        this.service.entityName = "folder/permission";
        this.service.getResources().subscribe((response) => {
            this.fields = response;
        });
    }

    emitSendEvent() {
        this.sendEvent.emit();
    }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-jobs-slide',
  templateUrl: 'slide.component.html'
})
export class JobsSlideComponent {
  @Input() item: any = {};
  @Input() setting: any;

  constructor(
  ) {
  }

}

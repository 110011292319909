import { Component, Input } from '@angular/core';



@Component({
  selector: 'app-editor-custom-component-palestrante',
  templateUrl: 'palestrante.component.html'
})
export class PalestranteComponent {

  @Input() item: any;
  @Input() layout: number;
  @Input() full: boolean;

  public speakers = [
    {
      avatar: 'https://firebasestorage.googleapis.com/v0/b/universidade-smart-fit.appspot.com/o/images%2Fa70a57c1-af08-7142-e54c-02e478c3e828.image%2Fjpeg?alt=media&token=b17f91bc-402e-4a8d-b024-d4cc83de8ea5',
      name: 'Cesar de oliveira'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/ef2a241ce318d860fe410ad3228b79cd/sm',
      name: 'Ana Paula Bispo'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/596a90fd6a5818170dc468d694644267/sm',
      name: 'Marcio Fernandes'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/643de52381fab539fe9a4234a2736553/sm',
      name: 'Ingrid Levy'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/ba066bd476e85f6857c8583ce007b300/sm',
      name: 'Amanda dos Santos'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/588866f945dc6a8651c750af8bcca5c2/sm',
      name: 'Petrick Henry'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/41144c94b73990b99e5c6d8a86d08343/sm',
      name: 'Heloisa Cristina'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/f445155a5b7b486677a79b2832c895b3/sm',
      name: 'Danilo Roger'
    },
    {
      avatar: 'https://firebasestorage.googleapis.com/v0/b/universidade-smart-fit.appspot.com/o/images%2F48761388-8242-941a-a698-0eff6a27c39c.image%2Fjpeg?alt=media&token=9d5188ee-4dcf-4bea-a2ab-5531ccd8c323',
      name: 'Bruno Correa'
    },
    {
      avatar: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/35b10917cb1f9dde189c52e6a815c5c3/sm',
      name: 'Monique Lisboa'
    },
  ];

  options = {
    center: false,
    loop: false,
    margin: 40,
    rewind: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    items: 6,
  };

}


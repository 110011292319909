import { Component, Input, Output, EventEmitter, ViewContainerRef, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-col-right',
  templateUrl: './col-right.component.html'
})

export class ColRightComponent implements OnChanges {
  @Input() root;
  @Input() item: any = {};
  @Input() section: any;
  @Input() layoutComponentSelected: any;
  @Input() settings: any;
  @Input() customData: any;
  @Input() layout: any;
  @Input() full: boolean;
  @Input() owner: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadHeaderEvent = new EventEmitter<any>();
  @Output() public reloadBannerEvent = new EventEmitter<any>();
  @Output() public saveConfigEvent = new EventEmitter<any>();
  @Output() public sessionChangedEvent = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  setElementAlign(element: any, align: string) {
    element = align;
  }

  emitSendEvent() {
    console.log("[settings collright]", this.settings.navbarMenuItems);
    this.sendEvent.emit(this.item.type);
  }

  reloadHeader(event: any) {
    this.reloadHeaderEvent.emit(event);
  }

  reloadBanner(event: any) {
    this.reloadBannerEvent.emit(event);
  }

  saveConfig() {
    this.saveConfigEvent.emit();
  }

  getVideoArchive($event: { path: string }) {
    this.item.source_path = $event.path;
  }

  getPhoto($event: { path: string }) {
    this.item.banner = $event.path;
  }

  sessionChanged() {
    this.sessionChangedEvent.emit();
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-journey-card',
  templateUrl: 'journey-card.component.html'
})
export class JourneyCardComponent {
    @Input() setting: any;
    @Input() item: any;
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-jobs-card-2',
  templateUrl: 'card-2.component.html'
})
export class JobsCard2Component implements OnInit {
  @Input() setting: any;
  @Input() item: any;

  ngOnInit(): void {
    console.log('[SlidesCard2Component] setting', this.setting);
    console.log('[SlidesCard2Component] item', this.item);
  }
}

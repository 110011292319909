import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-dates',
  templateUrl: 'dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent {
    @Input() item: any;
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: './permission-modal',
  templateUrl: './permission-modal.component.html',
})
export class PermissionModalComponent{

  
  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<PermissionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


  }



import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-header-countdown',
  templateUrl: 'header-countdown.component.html'
})
export class HeaderCountdownComponent implements OnInit {
  @Input() item: any;
  @Input() settings: any;

  public dias: any = 0;
  public horas: any = 0;
  public minutos: any = 0;
  public segundos: any = 0;
  public regressiva: any = 0;
  public interval;

  constructor(
  ) {
  }


  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    let target_date = new Date("november 30, 2023").getTime();

    this.interval = setInterval(() => {

      let current_date = new Date().getTime();
      let segundos_f: any = ((target_date - current_date) / 1000);
      let _dias = Math.floor(Number(segundos_f / 86400));
      let _horas = Math.floor(Number(segundos_f / 3600));
      this.dias = this.pad(Math.floor(Number(segundos_f / 86400)));
      segundos_f = segundos_f % 86400;

      this.horas = this.pad(Math.floor(Number(segundos_f / 3600)));
      segundos_f = segundos_f % 3600;

      this.minutos = this.pad(Math.floor(Number(segundos_f / 60)));
      this.segundos = this.pad(Math.floor(Number(segundos_f % 60)));

      if (_dias <= 0 && _horas <= 0) {
        this.reset();
      }


    }, 1000);
  }

  public pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  public reset() {
    this.dias = 0;
    this.horas = 0;
    this.minutos = 0;
    this.segundos = 0;
    clearInterval(this.interval);
  }

}

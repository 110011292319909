import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-users',
    templateUrl: 'users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    @Input() item: any;
    @Input() settings: any;
    @Input() full: any;

    @ViewChild('userCarousel',{static: false}) userCarousel;

    data: any;
    mock: any;

    filtered: any;

    get owlOptions() {
        let options =  { items: 4, loop: true, dots: false, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };
        if (this.userCarousel) {
            options.items = this.userCarousel.carouselWindowWidth/250;
        }
        return options;
    };

    constructor(
        public dataService: DataService
    ) {

    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.dataService.users().subscribe((res: any) => {
            this.data = res.data;
            this.filtered = this.data;
        });
    }

}


import { Component, Input, OnChanges, SimpleChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog, MatDialogActions } from '@angular/material/dialog';
import { AulasRapidasSessaoDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-aulas-rapidas-sessao',
  templateUrl: '../curso-sessao/curso-sessao.component.html'
})
export class AulasRapidasSessaoComponent implements OnInit, OnChanges {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  filtered: any;
  dataByFolder: any;
  dataByFolderBuffer: any;
  mock: any;
  sessions: any;

  data: any;

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
  }

  ngOnInit() {
    this.getCustom();

    if (!this.setting.session_id) {
      this.openDialog();
    } else {
      this.getData();
    }

    // setInterval(() => {
    //   if (this.dataByFolderBuffer) {
    //     this.dataByFolder = this.dataByFolderBuffer.filter((item: any) => +item.session_id === +this.setting.session_id);
    //   }
    // }, 500);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AulasRapidasSessaoDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.item.title.text = result.name;
        this.item.session_id = result.id;

        this.getData();
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

  getData() {
    const setting = this.setting;
    setting.itens = 6;
    setting.folder = false;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        this.data = res.main[0].itens;
        this.filtered = this.data;
      });
  }

  getCustom() {
    const setting = this.setting;
    setting.itens = 6;
    setting.folder = true;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        console.log('getCustom', res);
        this.dataByFolder = res.main[0].itens;
      });
  }

}


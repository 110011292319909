import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-showcase-section-item',
  templateUrl: 'showcase-item.component.html',
  styleUrls: ['showcase-item.component.scss']
})
export class ShowcaseSectionItemComponent implements OnInit {
  @Input() setting: any;
  @Input() item: any;

  constructor(
  ) {
  }

  ngOnInit() {

  }

}


import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-eventos',
  templateUrl: 'eventos.component.html'
})
export class EventosComponent implements OnInit {
  @Input() full: boolean;
  @Input() item: any;
  @Input() settings: any;
  @Input() layout: number;
  @Input() screen: string;

  data: any;
  dataGroupByDate: any;

  dataByFolder: any;

  mock = {
    banner: '',
    name: 'Título do evento',
    event_date: '2020-12-12',
    event_time: '12:00:00'
  };

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(public dataService: DataService) {
  }

  ngOnInit() {
    this.getData();
    this.getCustom();
  }

  getData() {
    const lu = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.dataService.event(user.id).subscribe(res => {
      this.data = res;

      const eventsBuffer = [];
      this.data.map((event: any) => {
          const find = eventsBuffer.find(i => i.date === event.event_date);
          if (find) {
              find.list.push(event);
          } else {
              eventsBuffer.push({
                  date: event.event_date,
                  list: [event]
              });
          }
      });
      this.dataGroupByDate = eventsBuffer.sort((a, b) => {
          return a.date.localeCompare(b.date);
      });

    });
  }

  getCustom() {
    this.dataService.custom('event', 0)
      .subscribe((res: any) => {
        this.dataByFolder = res.data.events;
      });
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-journey-list',
  templateUrl: 'journey-list.component.html',
  styleUrls: ['../journey-list.component.scss']
})
export class JourneyListComponent {
    @Input() setting: any;
    @Input() data: any;
}

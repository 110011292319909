import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-video-card',
  templateUrl: 'video-card.component.html'
})
export class VideoCardComponent {

  @Input() setting: any;
  @Input() item: any;

}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Global } from 'asap-crud';
import { DataService } from 'src/app/@core/services/data.service';
import { JourneySessionDialogComponent } from './dialog/dialog.component';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-editor-custom-component-journey',
  templateUrl: 'journey.component.html',
  styleUrls: ['./journey-list.component.scss']
})
export class JourneyComponent implements OnChanges {
    @Input() setting: any;
    @Input() full: boolean;
    @Input() screen: string;

    public dataArray: any;

    public mock: any;

    assessmentOptions: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      margin: 0,
      stagePadding: 0,
      center: true,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 4
        },
        940: {
          items: 4
        }
      },
      nav: false
    }

    constructor(
      public dataService: DataService,
      public global: Global
    ) {

      this.mock = {
        image: '/assets/img/no-image.jpg',
        name: 'Lorem ipsum dolor sit amet'
      };
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.setting) {
        if (this.setting.isNew && !this.setting.session_id) {
          delete(this.setting.isNew)
          this.openDialog();
        }
        else {
          this.getJourney();
        }
        console.log('settings', this.setting);
      }
    }

    getJourney() {
      console.log("[getJourney]",this.setting);
      this.dataService.journey(this.setting.session_id).subscribe((res: any) => {
        this.dataArray = res.data;
        console.log('dataArray', this.dataArray);
      });
    }

    openDialog(): void {
      const dialogRef = this.global.dialog.open(JourneySessionDialogComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('openDialog: result', result);
        if (result) {
          this.setting.title.text = result.name;
          this.setting.session_id = result.id;

          this.getJourney();
        } else {
          console.log('openDialog: remove curso');
        }
      });
    }
}

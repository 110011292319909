import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-evento-sessao-config',
    templateUrl: 'evento-sessao-config.component.html'
})
export class EventoSessaoConfigComponent implements OnInit {
  @Input() item: any = {};
  @Input() customData: any;
  @Input() full: boolean;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public sessionChangedEvent = new EventEmitter<any>();

  public sessions: any;

  constructor(
      public dataService: DataService
  ) {

  }

  ngOnInit(): void {
    this.getSessions();
  }

  getSessions() {
    this.dataService.cursosSessao()
      .subscribe((response: any) => {
        this.sessions = response;
      });
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  setAlignElement(element: any, align: string) {
    element = align;
  }

  setItemAlign(element: string, align: string) {
    this.item[element] = align;
  }

  sessionChanged() {
    this.sessionChangedEvent.emit();
  }

}

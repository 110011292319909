import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-events-banner',
  templateUrl: 'events-banner.component.html'
})
export class EventosBannerComponent {
  @Input() setting: any;
  @Input() item: any;
  @Input() screen: string;

  constructor() {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-main-tecnico-card',
  templateUrl: 'main-tecnico-card.component.html',
  providers: [DataService]
})
export class MainTecnicoCardComponent {
  @Input() setting: any;
  @Input() item: any;
}

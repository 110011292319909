import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-banner-link',
  templateUrl: 'banner-link.component.html'
})
export class BannerLinkComponent implements OnInit {
    @Input() item: any;

    data: any;

    constructor(public dataService: DataService) {

    }

    ngOnInit() {
      this.getData();
    }

    getData() {
      this.dataService.benefits().subscribe((res: any) => {
        this.data = res.data;
      });
    }
 
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material';
import { EventoSessaoDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-evento-sessao',
  templateUrl: 'evento-sessao.component.html'
})
export class EventoSessaoComponent implements OnInit {
  @Input() full: boolean;
  @Input() item: any;
  @Input() settings: any;
  @Input() layout: number;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  data: any;
  dataGroupByDate: any;
  filtered: any;
  dataByFolder: any;
  dataByFolderBuffer: any;

  mock = {
    banner: '',
    name: 'Título do evento',
    event_date: '2020-12-12',
    event_time: '12:00:00'
  };

  owlOptions = { items: 1, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    if (!this.item.session_id) {
      this.openDialog();
    } else {
      this.getData();
    }
  }

  filterBySession() {
    this.filtered = this.data.filter((item: any) => (+item.session_id === +this.item.session_id));
    this.generateEventsByDate();

    this.dataByFolder = this.dataByFolderBuffer.filter((item: any) => +item.session_id === +this.item.session_id);
  }

  getData() {
    const lu = (localStorage.getItem('loggedUser') || sessionStorage.getItem('loggedUser'));
    const user = JSON.parse(lu).data;

    this.dataService.event(user.id).subscribe(res => {
      this.data = res;
      this.getCustom();
    });
  }

  generateEventsByDate() {
    const eventsBuffer = [];
    this.filtered.map((event: any) => {
      const find = eventsBuffer.find(i => i.date === event.event_date);
      if (find) {
        find.list.push(event);
      } else {
        eventsBuffer.push({
          date: event.event_date,
          list: [event]
        });
      }
    });
    this.dataGroupByDate = eventsBuffer.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
  }

  getCustom() {
    this.dataService.custom('event-sessao', 8)
      .subscribe((res: any) => {
        this.dataByFolderBuffer = res.data['evento-sessao'];
        console.log('this.dataByFolder', this.dataByFolderBuffer);
        this.filterBySession();
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EventoSessaoDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.item.title.text = result.name;
        this.item.session_id = result.id;

        this.getData();
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

}

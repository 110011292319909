import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NouisliderModule } from 'ng2-nouislider';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';

import { SlidesComponent } from './slides/slides.component';
import { SlidesConfigComponent } from './slides/config/slides-config.component';

import { MiniComponent } from './mini/mini.component';
import { MiniConfigComponent } from './mini/config/mini-config.component';

import { InfluenciadoresComponent } from './influenciadores/influenciadores.component';
import { InfluenciadoresConfigComponent } from './influenciadores/config/influenciadores-config.component';

import { PontosComponent } from './pontos/pontos.component';
import { PontosConfigComponent } from './pontos/config/pontos-config.component';

import { CreditsComponent } from './credits/credits.component';
import { CreditsConfigComponent } from './credits/config/credits-config.component';

import { ConquistasComponent } from './conquistas/conquistas.component';
import { ConquistasConfigComponent } from './conquistas/config/conquistas-config.component';

import { ArtigosComponent } from './artigos/artigos.component';
import { ArtigosConfigComponent } from './artigos/config/artigos-config.component';

import { EventosComponent } from './eventos/eventos.component';
import { EventosConfigComponent } from './eventos/config/eventos-config.component';

import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ColaboradoresConfigComponent } from './colaboradores/config/colaboradores-config.component';

import { AniversariantesComponent } from './aniversariantes/aniversariantes.component';
import { AniversariantesConfigComponent } from './aniversariantes/config/aniversariantes-config.component';

import { DatesComponent } from './dates/dates.component';
import { DatesConfigComponent } from './dates/config/dates-config.component';

import { ForumComponent } from './forum/forum.component';
import { ForumConfigComponent } from './forum/config/forum-config.component';

import { TecnicoComponent } from './tecnico/tecnico.component';
import { TecnicoConfigComponent } from './tecnico/config/tecnico-config.component';

import { InfoComponent } from './info/info.component';
import { InfoConfigComponent } from './info/config/info-config.component';

import { ViewerComponent } from './viewer/viewer.component';
import { ViewerConfigComponent } from './viewer/config/viewer-config.component';

import { FreeComponent } from './free/free.component';
import { FreeConfigComponent } from './free/config/free-config.component';

import { MainTecnicoComponent } from './main-tecnico/main-tecnico.component';
import { MainTecnicoConfigComponent } from './main-tecnico/config/main-tecnico-config.component';

import { JourneyComponent } from './journey/journey.component';
import { JourneyConfigComponent } from './journey/config/journey-config.component';
import { JourneyCardComponent } from './journey/card/journey-card.component';
import { JourneyCardFlixComponent } from './journey/flix/journey-card-flix.component';
import { JourneyListComponent } from './journey/list/journey-list.component';
import { JourneyAssessmentComponent } from './journey/assessment/journey-assessment.component';

import { EditalInfluenciadoresComponent } from './edital-influenciadores/edital-influenciadores.component';
import { EditalInfluenciadoresConfigComponent } from './edital-influenciadores/config/edital-influenciadores-config.component';

import { BenefitsComponent } from './benefits/benefits.component';
import { BenefitsConfigComponent } from './benefits/config/benefits-config.component';

import { MaterialDesignModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { AsapLimitToModule } from 'asap-limit-to';
import { AsapSkeletonModule } from 'asap-skeleton';

import { PipeModule } from '../@core/pipes/pipe.module';

import { CustomHeaderComponent } from './custom-header/custom-header.component';

import { HeaderComponent } from './header/header.component';
import { HeaderConfigComponent } from './header/config/header-config.component';

import { SearchComponent } from './search/search.component';
import { SearchConfigComponent } from './search/config/search-config.component';

import { VacanciesConfigComponent } from './vacancies/config/vacancies-config.component';
import { VacanciesComponent } from './vacancies/vacancies.component';

import { PalestranteConfigComponent } from './palestrante/config/palestrante-config.component';
import { PalestranteComponent } from './palestrante/palestrante.component';

import { TalksConfigComponent } from './talks/config/talks-config.component';
import { TalksComponent } from './talks/talks.component';

import { InfoTalksConfigComponent } from './info-talks/config/info-talks-config.component';
import { InfoTalksComponent } from './info-talks/info-talks.component';

import { SlidesCardComponent } from './slides/card/slides-card.component';
import { SlidesListComponent } from './slides/list/slides-list.component';
import { SlidesSlideComponent } from './slides/slide/slide.component';

import { GalleryComponent } from './gallery/gallery.component';
import { GalleryCardComponent } from './gallery/card/gallery-card.component';
import { GalleryConfigComponent } from './gallery/config/gallery-config.component';

import { ComponentTitleComponent } from './component-title/component-title.component';
import { MainTecnicoCardComponent } from './main-tecnico/main-tecnico-card/main-tecnico-card.component';

import { SectionTitleComponent } from './section-title/section-title.component';
import { HeaderFeaturedEventComponent } from './header/featured/event/event.component';
import { HeaderFeaturedTrailComponent } from './header/featured/trail/trail.component';
import { HeaderFeaturedDefaultComponent } from './header/featured/default/default.component';
import { HeaderFeaturedVideoComponent } from './header/featured/video/video.component';
import { CursoSessaoComponent } from './curso-sessao/curso-sessao.component';
import { CursoSessaoConfigComponent } from './curso-sessao/config/curso-sessao-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { BannerComponent } from './banner/banner.component';
import { BannerConfigComponent } from './banner/config/banner-config.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentConfigComponent } from './assessment/config/assessment-config.component';
import { EventosCardComponent } from './eventos/card/events-card.component';

import { ExpositoresComponent } from './expositores/expositores.component';
import { ExpositoresConfigComponent } from './expositores/config/expositores-config.component';

import { StudioComponent } from './studio/studio.component';
import { StudioConfigComponent } from './studio/config/studio-config.component';
import { StudioListComponent } from './studio/layout/list/list.component';
import { StudioSlideComponent } from './studio/layout/slide/slide.component';
import { StudioCardComponent } from './studio/layout/card/card.component';
import { CursoSessaoDialogComponent } from './curso-sessao/dialog/dialog.component';
import { VideoSessaoComponent } from './video-sessao/video-sessao.component';
import { VideoSessaoConfigComponent } from './video-sessao/config/video-sessao-config.component';
import { VideoSessaoDialogComponent } from './video-sessao/dialog/dialog.component';
import { SlidesCard2Component } from './slides/card-2/slides-card-2.component';
import { EventosBannerComponent } from './eventos/banner/events-banner.component';
import { EventoSessaoComponent } from './evento-sessao/evento-sessao.component';
import { EventoSessaoConfigComponent } from './evento-sessao/config/evento-sessao-config.component';
import { EventoSessaoDialogComponent } from './evento-sessao/dialog/dialog.component';

import { JobsComponent } from './jobs/jobs.component';
import { JobsSlideComponent } from './jobs/slide/slide.component';
import { JobsListComponent } from './jobs/list/list.component';
import { JobsListTwoComponent } from './jobs/list-2/list-2.component';
import { JobsConfigComponent } from './jobs/config/jobs-config.component';
import { JobsCard2Component } from './jobs/card-2/card-2.component';
import { JobsCardComponent } from './jobs/card/card.component';

import { AulasRapidasSessaoComponent } from './aulas-rapidas-sessao/aulas-rapidas-sessao.component';
import { AulasRapidasSessaoDialogComponent } from './aulas-rapidas-sessao/dialog/dialog.component';
import { DataComponent } from './data/data.component';
import { DataConfigComponent } from './data/config/data-config.component';
import { NewsSectionComponent } from './news-section/news-section.component';
import { NewsSectionDialogComponent } from './news-section/dialog/dialog.component';
import { NewsSectionConfigComponent } from './news-section/config/news-section-config.component';
import { NewsCardComponent } from './news-section/card/news-card.component';
import { BannerLinkComponent } from './banner-link/banner-link.component';
import { BannerLinkConfigComponent } from './banner-link/config/banner-link-config.component';
import { SlidesSalesCardComponent } from './slides/sales-card/sales-card.component';
import { HtmlComponent } from './html/html.component';
import { HtmlConfigComponent } from './html/config/html-config.component';
import { HeaderCountdownComponent } from './header/countdown/header-countdown.component';
import { MentoriaSectionComponent } from './mentoria-section/mentoria-section.component';
import { MentoriaSectionConfigComponent } from './mentoria-section/config/mentoria-section-config.component';
import { MentoriaSectionDialogComponent } from './mentoria-section/dialog/dialog.component';
import { MentoriaSectionCardComponent } from './mentoria-section/card/card.component';
import { AsapCrudModule } from 'asap-crud';
import { EditorSharedButtonComponent } from './shared/button/button.component';
import { EditorSharedButtonConfigComponent } from './shared/button/config/button-config.component';
import { EditorSharedTitleComponent } from './shared/title/title.component';
import { EditorSharedTitleConfigComponent } from './shared/title/config/title-config.component';
import { EditorSharedAnimationComponent } from './shared/animation-select/animation-select.component';
import { EditorSharedSubTitleComponent } from './shared/subtitle/subtitle.component';
import { EditorSharedSubTitleConfigComponent } from './shared/subtitle/config/subtitle-config.component';
import { UsersConfigComponent } from './users/config/users-config.component';
import { UsersComponent } from './users/users.component';
import { MulticontasComponent } from './multicontas/multicontas.component';
import { MulticontasConfigComponent } from './multicontas/config/multicontas-config.component';
import { ForumThemeComponent } from './forum-theme/forum-theme.component';
import { ForumThemeConfigComponent } from './forum-theme/config/forum-theme-config.component';
import { UsuariosSidebarComponent } from './usuarios-sidebar/usuarios-sidebar.component';
import { UsuariosSidebarConfigComponent } from './usuarios-sidebar/config/usuarios-sidebar-config.component';
import { ForumThemeSidebarComponent } from './forum-theme-sidebar/forum-theme-sidebar.component';
import { ForumThemeSidebarConfigComponent } from './forum-theme-sidebar/config/forum-theme-sidebar-config.component';
import { JourneySessionDialogComponent } from './journey/dialog/dialog.component';
import { SharedFeaturedConfigComponent } from './shared/featured/config/featured-config.component';
import { HeaderCarouselComponent } from './header/carousel/carousel.component';
import { ShowcaseSectionComponent } from './showcase-section/showcase-section.component';
import { ShowcaseSectionConfigComponent } from './showcase-section/config/showcase-section-config.component';
import { ShowcaseSectionDialogComponent } from './showcase-section/dialog/dialog.component';
import { ShowcaseSectionItemComponent } from './showcase-section/item/showcase-item.component';
import { SlidesCardFlixComponent } from './slides/card-flix/slides-card-flix.component';
import { NavbarConfigComponent } from './navbar/config/navbar-config.component';
import { FooterConfigComponent } from './footer/config/footer-config.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarConfigComponent } from './sidebar/config/sidebar-config.component';
import { MatButtonModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { FeaturedComponent } from './featured/featured.component';
import { FeaturedConfigComponent } from './featured/config/featured-config.component';
import { LibraryComponent } from './library/library.component';
import { LibraryConfigComponent } from './library/config/library-config.component';
import { EcommerceFeaturedComponent } from './ecommerce-featured/ecommerce-featured.component';
import { EcommerceFeaturedConfigComponent } from './ecommerce-featured/config/ecommerce-featured-config.component';
import { FeedComponent } from './feed/feed.component';
import { FeedConfigComponent } from './feed/config/feed-config.component';
import { EditorSharedMediaComponent } from './shared/media/media.component';
import { EditorSharedMediaConfigComponent } from './shared/media/config/media-config.component';
import { ManagementPanelComponent } from './management-panel/management-panel.component';
import { ManagementPanelConfigComponent } from './management-panel/config/management-panel-config.component';
import { VideoCardComponent } from './video-sessao/card/video-card.component';
import { VideoCard2Component } from './video-sessao/card-2/video-card-2.component';
import { VideoCardFlixComponent } from './video-sessao/card-flix/video-card-flix.component';
import { MonitoringCommentsComponent } from './monitoring-comments/monitoring-comments.component';
import { MonitoringCommentsConfigComponent } from './monitoring-comments/config/monitoring-comments-config.component';

import { PollsSectionComponent } from './polls-section/polls-section.component';
import { PollsSectionConfigComponent } from './polls-section/config/polls-section-config.component';
import { PollsSectionDialogComponent } from './polls-section/dialog/dialog.component';
import { PollsCardComponent } from './polls-section/card/polls-card.component';
import { DialogHtmlComponent } from './html/dialog-html/dialog-html.component';
import { AdminPermissionModule } from './permission/admin-permission.module';
import { HeaderSliderComponent } from './header/slider/slider.component';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { ShortcutsConfigComponent } from './shortcuts/config/shortcuts-config.component';
import { MenuComponent } from './menu/menu.component';
import { MenuConfigComponent } from './menu/config/menu-config.component';
import { PermissionModalComponent } from '../home/permission-modal/permission-modal.component';
import { OnboardComponent } from './onboard/onboard.component';
import { OnboardConfigComponent } from './onboard/config/onboard-config.component';
import { OnboardItemComponent } from './onboard/item/onboard-item.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { NavbarMobileConfigComponent } from './navbar-mobile/config/navbar-mobile-config.component';


const components = [
  SlidesComponent,
  SlidesConfigComponent,
  MiniComponent,
  MiniConfigComponent,
  InfluenciadoresComponent,
  InfluenciadoresConfigComponent,
  PontosComponent,
  PontosConfigComponent,
  CreditsComponent,
  CreditsConfigComponent,
  ConquistasComponent,
  ConquistasConfigComponent,
  ArtigosComponent,
  ArtigosConfigComponent,
  EventosComponent,
  EventosConfigComponent,
  ColaboradoresComponent,
  ColaboradoresConfigComponent,
  AniversariantesComponent,
  AniversariantesConfigComponent,
  DatesComponent,
  DatesConfigComponent,
  ForumComponent,
  ForumConfigComponent,
  TecnicoComponent,
  TecnicoConfigComponent,
  InfoComponent,
  InfoConfigComponent,
  ViewerComponent,
  ViewerConfigComponent,
  FreeComponent,
  FreeConfigComponent,
  MainTecnicoComponent,
  MainTecnicoConfigComponent,
  JourneyComponent,
  JourneyConfigComponent,
  JourneyCardComponent,
  JourneyCardFlixComponent,
  JourneyListComponent,
  JourneyAssessmentComponent,
  JourneySessionDialogComponent,
  EditalInfluenciadoresComponent,
  EditalInfluenciadoresConfigComponent,
  HeaderComponent,
  HeaderConfigComponent,
  SearchComponent,
  SearchConfigComponent,
  CustomHeaderComponent,
  PalestranteComponent,
  PalestranteConfigComponent,
  SlidesCardComponent,
  VideoCardComponent,
  VideoCard2Component,
  VideoCardFlixComponent,
  GalleryComponent,
  GalleryCardComponent,
  GalleryConfigComponent,
  ComponentTitleComponent,
  SlidesSlideComponent,
  MainTecnicoCardComponent,
  SectionTitleComponent,
  HeaderFeaturedEventComponent,
  HeaderFeaturedTrailComponent,
  HeaderFeaturedDefaultComponent,
  HeaderFeaturedVideoComponent,
  TalksComponent,
  TalksConfigComponent,
  InfoTalksConfigComponent,
  InfoTalksComponent,
  BannerComponent,
  BannerConfigComponent,
  AssessmentComponent,
  AssessmentConfigComponent,
  BenefitsComponent,
  BenefitsConfigComponent,
  VacanciesComponent,
  VacanciesConfigComponent,
  EventosCardComponent,
  ExpositoresComponent,
  ExpositoresConfigComponent,
  StudioComponent,
  StudioConfigComponent,
  StudioListComponent,
  StudioCardComponent,
  StudioSlideComponent,
  CursoSessaoComponent,
  CursoSessaoConfigComponent,
  SlidesListComponent,
  CursoSessaoDialogComponent,
  VideoSessaoComponent,
  VideoSessaoConfigComponent,
  VideoSessaoDialogComponent,
  SlidesCard2Component,
  EventosBannerComponent,
  EventoSessaoComponent,
  EventoSessaoConfigComponent,
  EventoSessaoDialogComponent,
  JobsComponent,
  JobsSlideComponent,
  JobsListComponent,
  JobsListTwoComponent,
  JobsConfigComponent,
  JobsCard2Component,
  JobsCardComponent,
  AulasRapidasSessaoComponent,
  AulasRapidasSessaoDialogComponent,
  DataComponent,
  DataConfigComponent,
  NewsSectionComponent,
  NewsSectionDialogComponent,
  NewsSectionConfigComponent,
  NewsCardComponent,
  BannerLinkComponent,
  BannerLinkConfigComponent,
  SlidesSalesCardComponent,
  HtmlComponent,
  HtmlConfigComponent,
  HeaderCountdownComponent,
  MentoriaSectionComponent,
  MentoriaSectionConfigComponent,
  MentoriaSectionDialogComponent,
  MentoriaSectionCardComponent,
  EditorSharedButtonComponent,
  EditorSharedButtonConfigComponent,
  EditorSharedTitleComponent,
  EditorSharedTitleConfigComponent,
  EditorSharedAnimationComponent,
  EditorSharedSubTitleComponent,
  EditorSharedSubTitleConfigComponent,
  ShortcutsComponent,
  ShortcutsConfigComponent,
  MenuComponent,
  MenuConfigComponent,
  UsersComponent,
  UsersConfigComponent,
  MulticontasComponent,
  MulticontasConfigComponent,
  ForumThemeComponent,
  ForumThemeConfigComponent,
  UsuariosSidebarComponent,
  UsuariosSidebarConfigComponent,
  ForumThemeSidebarComponent,
  ForumThemeSidebarConfigComponent,
  SharedFeaturedConfigComponent,
  HeaderCarouselComponent,
  HeaderSliderComponent,
  ShowcaseSectionComponent,
  ShowcaseSectionConfigComponent,
  ShowcaseSectionDialogComponent,
  ShowcaseSectionItemComponent,
  SlidesCardFlixComponent,
  NavbarComponent,
  NavbarConfigComponent,
  NavbarMobileComponent,
  NavbarMobileConfigComponent,
  SidebarComponent,
  SidebarConfigComponent,
  FooterComponent,
  FooterConfigComponent,
  FeaturedComponent,
  FeaturedConfigComponent,
  LibraryComponent,
  LibraryConfigComponent,
  EcommerceFeaturedComponent,
  EcommerceFeaturedConfigComponent,
  FeedComponent,
  FeedConfigComponent,
  EditorSharedMediaComponent,
  EditorSharedMediaConfigComponent,
  ManagementPanelComponent,
  ManagementPanelConfigComponent,
  MonitoringCommentsComponent,
  MonitoringCommentsConfigComponent,
  PollsSectionComponent,
  PollsSectionConfigComponent,
  PollsSectionDialogComponent,
  PollsCardComponent,
  DialogHtmlComponent,
  OnboardComponent,
  OnboardItemComponent,
  OnboardConfigComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NouisliderModule,
    ReactiveFormsModule,
    TagInputModule,
    ColorPickerModule,
    CarouselModule,
    MaterialDesignModule,
    SharedModule,
    PipeModule,
    AsapLimitToModule,
    AsapSkeletonModule,
    AsapCrudModule,
    DragDropModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    AdminPermissionModule,
  ],
  declarations: [
    ...components,
    DialogHtmlComponent,
    PermissionModalComponent,

  ],
  exports: [
    ...components,
    PermissionModalComponent,
  ],

  entryComponents: [
    CursoSessaoDialogComponent,
    PollsSectionDialogComponent,
    DialogHtmlComponent,
    VideoSessaoDialogComponent,
    EventoSessaoDialogComponent,
    AulasRapidasSessaoDialogComponent,
    NewsSectionDialogComponent,
    MentoriaSectionDialogComponent,
    JourneySessionDialogComponent,
    ShowcaseSectionDialogComponent,
    PermissionModalComponent,
  ],
})

export class ComponentsModule { }

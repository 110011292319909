import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-banner',
  templateUrl: 'banner.component.html'
})
export class BannerComponent implements OnInit {
  @Input() setting: any;

  featuredData: any;
  featureLoading: boolean;

  constructor(
    private dataService: DataService
  ) {
    this.featureLoading = true;
  }

  ngOnInit(): void {
    this.getFeaturedData();
  }

  reload(feature = null) {
    this.featureLoading = true;
    this.getFeaturedData(feature);
  }

  getFeaturedData(feature = null) {
    if (feature) {
      this.dataService.featured(feature.featureType, feature.featureId)
        .subscribe((res: any) => {
          console.log('[BannerComponent]: getFeaturedData: res', res);
          if (res.data.itemSeach && res.data.itemSeach.length > 0) {
            this.featuredData = res.data.itemSeach[0];
            this.featureLoading = false;
          }
        });
    } else {
      this.dataService.featured(this.setting.featureType, this.setting.featureId)
        .subscribe((res: any) => {
          if (res.data.itemSeach && res.data.itemSeach.length > 0) {
            this.featuredData = res.data.itemSeach[0];
            this.featureLoading = false;
          }
        });
    }
  }

}

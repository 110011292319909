import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowcaseSectionDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-showcase-section',
  templateUrl: 'showcase-section.component.html'
})
export class ShowcaseSectionComponent implements OnInit {
  @Input() setting: any;
  @Input() data: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  filtered: any;
  dataByFolder: any;
  dataByFolderBuffer: any;
  mock: any;
  sessions: any;

  owlOptions = { items: 3, margin: 30, loop: true, dots: true, navigation: true, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnInit() {
    if (!this.setting.section_id) {
      this.openDialog();
    }

    console.log('ShowcaseSectionComponent: this.data', this.data)

    setInterval(() => {
      if (this.data) {
        this.filtered = this.data.filter((item: any) => (+item.section_id === +this.setting.section_id));
      }
    }, 500);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ShowcaseSectionDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result) {
        this.setting.title.text = result.title;
        this.setting.subtitle.text = result.description;
        this.setting.section_id = result.id;

        this.filtered = this.data.filter((item: any) => (+item.section_id === +this.setting.section_id));
      } else {
        this.removeItemEvent.emit();
      }
    });
  }

}


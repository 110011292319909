import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-video-card-flix',
  templateUrl: 'video-card-flix.component.html'
})
export class VideoCardFlixComponent {

  @Input() setting: any;
  @Input() item: any;

}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Global } from "asap-crud";
import { UserService } from "src/app/@core/services/user.service";
import { SelectUserComponentModal } from "../select-user.component";

@Component({
    selector: 'asap-select-user',
    templateUrl: './select.component.html',
    styleUrls: [ '../select-user.component.scss' ],
    providers: [ UserService ]
})
export class SelectUserSelectComponent implements OnChanges {

    @Input() value:any;
    @Output() valueChange = new EventEmitter();

    @Input() public multiselect = false;
    @Input() public placeholder:any;

    public display = "";
    public users:any;

    constructor(
        public global: Global,
        public userService: UserService
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            console.log("value A",this.value);
            if (this.value) {
                console.log("value Entrou no IF",this.value);
                let same = (this.value == changes.value.previousValue);
                if (!same) {
                    console.log("[arrayEquals]", this.value, changes.value.previousValue)
                    same = this.global.arrayEquals(this.value, changes.value.previousValue);
                }
                if (!same) {
                    console.log("value", same, this.value);

                    let ids = "";

                    if (Array.isArray(this.value)) {
                        ids = this.value.join(",");
                    }
                    else {
                        ids = this.value;
                    }

                    if (ids != "") {
                        this.userService.getAllUserData({ id: ids }).subscribe((response) => {
                            this.users = response.data.map((user:any) => {
                                user.selected = true;
                                return user;
                            });
                            if (Array.isArray(response.data)) {
                                let display = "";
                                response.data.forEach((user:any) => {
                                    if (display != "") display += ", ";
                                    display += user.name;
                                });
                                this.display = display;
                            }
                            else {
                                this.display = response.data.name;
                            }
                        });
                    }
                    else {
                        this.display = "";
                    }
                }
            }
            else {
                console.log("value Display Vazio");
                this.display = "";
            }
        }
    }

    public selectUser() {
        this.global.dialogOpen(SelectUserComponentModal, {
            width: '550px',
            data: {
                selection: this.value,
                searchList: this.users,
                asapClient: false,
                multiselect: this.multiselect
            }
        }).afterClosed().subscribe((response) => {
            if (response) {
                if (Array.isArray(response)) {
                    let value:any = [];
                    let display = "";
                    console.log('Rresponse ->', response);
                    this.users = response;
                    response.forEach(user => {
                        let id:any;

                        if (user.user_info) {
                            id = user.user_info.user_id;
                        }
                        else {
                            id = user.user_id
                        }

                        value.push(id);

                        if (display != "") display += ", ";
                        display += user.name;

                        let idx = this.users.findIndex((u:any) => ((u.user_info) ? u.user_info.user_id : user.user_id) == id);
                        if (idx < 0) {
                            this.users.push(user);
                        }
                    });
                    this.value = value;
                    this.valueChange.emit(value);
                    this.display = display;
                }
                else {
                    if (response.user_info) {
                        this.value = response.user_info.user_id;
                        this.valueChange.emit(response.user_info.user_id);
                        this.display = response.name;
                    }
                    else {
                        this.value = response.user_id;
                        this.valueChange.emit(response.user_id);
                        this.display = response.name;
                    }
                }
            }
        });
    }

    public selectUserIfNull() {
        if (!this.value) {
            this.selectUser();
        }
    }


}
